import {
  Analysis,
  AnalysisResult,
  CreateAnalysisRequest,
  Distribution,
  FoodsResponse,
  FoodsResult,
  KitDistribution,
} from "../store/analyses/types";
import { httpGet, httpPost } from "./base.service";
import { endpoints } from "./endpoints";

export const analysesService = {
  createAnalysis,
  addDistribution,
  getDistributions,
  addKitDistribution,
  getKitDistribution,
  getAnalysesResults,
  getAnalysesFoods,
  getAnalysesResultsById,
};

async function createAnalysis(
  createAnalysisRequest: CreateAnalysisRequest
): Promise<Analysis | undefined> {
  //console.log("createAnalysisRequest")
  //console.log(JSON.stringify(createAnalysisRequest));
  return await httpPost<Analysis>(
    `${endpoints["analyses"]}`,
    createAnalysisRequest
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function addDistribution(
  newAddress: Distribution
): Promise<Distribution | undefined> {
  return await httpPost<Distribution>(
    `${endpoints.analysesAddDistribution}`,
    newAddress
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function addKitDistribution(
  kitDistribution: KitDistribution
): Promise<KitDistribution | undefined> {
  return await httpPost<KitDistribution>(
    `${endpoints.analysesAddKitDistribution}`,
    kitDistribution
  )
    .then((response) => {
      console.log(response);
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function getDistributions(): Promise<Distribution[] | undefined> {
  return await httpGet<Distribution[]>(`${endpoints.analysesGetDistribution}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      console.log("error", error);
      throw new Error(error);
    });
}

async function getAnalysesResults(): Promise<AnalysisResult[] | undefined> {
  return await httpGet<{ nutrients: AnalysisResult[] }>(
    `${endpoints.analysesGetResults}`
  )
    .then((response) => {
      return response.parsedBody.nutrients;
    })
    .catch((error) => {
      console.log("error", error);
      throw new Error(error);
    });
}

async function getAnalysesResultsById(
  id: string
  ): Promise<AnalysisResult[] | undefined> {
  return await httpGet<{ nutrients: AnalysisResult[] }>(
    `${endpoints.analysesGetResultsById}/${id}`
  )
    .then((response) => {
      return response.parsedBody.nutrients;
    })
    .catch((error) => {
      console.log("error", error);
      throw new Error(error);
    });
}

async function getAnalysesFoods(): Promise<FoodsResponse | undefined> {
  return await httpGet<{ foods: FoodsResult[] }>(
    `${endpoints.analysesGetFoods}`
  )
    .then((response) => {
      return assignIdToCategories(response.parsedBody.foods);
    })
    .catch((error) => {
      console.log("error", error);
      throw new Error(error);
    });
}

function assignIdToCategories(foods: FoodsResult[]) {
  const idDict = {};
  let index = 0;
  return {
    foods: foods.map((food) => {
      if (idDict[food.category] !== undefined) {
        food.categoryId = idDict[food.category];
      } else {
        idDict[food.category] = index;
        food.categoryId = index;
        index += 1;
      }
      return food;
    }),
    categories: Object.keys(idDict).reduce((accum, key) => {
      return { ...accum, [idDict[key]]: key };
    }, {}),
  };
}

async function getKitDistribution(): Promise<Analysis | undefined> {
  return await httpGet<Analysis>(`${endpoints.analysesGet}`)
    .then((response) => {
      console.log("parsedbody", response.parsedBody);
      const distributions = response.parsedBody.distributions;
      let pickupDistribution: Distribution;
      let returnDistribution: Distribution;
      let pickupKitDistribution;
      let returnKitDistribution;
      if (distributions.length == 2) {
        const are2Pickups = distributions
          .map((x) => !x.kitDistributions.length)
          .reduce((prev, accum) => prev && accum, true);
        const onePickupOneDelivery =
          distributions.filter((x) => x.type == 0).length == 1 &&
          distributions.filter((x) => x.type == 1).length == 1;

        if (are2Pickups) {
          pickupDistribution = distributions[0];
          returnDistribution = distributions[1];
        } else if (onePickupOneDelivery) {
          const distWithKit = distributions.find(
            (x) => x.kitDistributions.length > 0
          );

          if (distWithKit.kitDistributions[0].type == 1) {
            pickupDistribution = distWithKit;
            pickupKitDistribution = distWithKit.kitDistributions[0];
            returnDistribution = distributions.find(
              (d) => d.kitDistributions.length == 0
            );
          } else {
            returnDistribution = distWithKit;
            returnKitDistribution = distWithKit.kitDistributions[0];
            pickupDistribution = distributions.find(
              (d) => d.kitDistributions.length == 0
            );
          }
        } else {
          pickupDistribution = distributions.find(
            (x) => x.kitDistributions[0]?.type == 1
          );
          pickupKitDistribution = pickupDistribution.kitDistributions[0];
          returnDistribution = distributions.find(
            (x) => x.kitDistributions[0]?.type == 2
          )
            ? distributions.find((x) => x.kitDistributions[0]?.type == 2)
            : distributions.find((x) => x.kitDistributions.length == 0);
          returnKitDistribution = returnDistribution?.kitDistributions[0];
        }
      } else if (distributions.length == 1) {
        const dist = distributions[0];
        if (dist.kitDistributions.length == 1) {
          pickupDistribution = dist;
          pickupKitDistribution = pickupDistribution.kitDistributions[0];
        } else if (dist.kitDistributions.length == 2) {
          pickupDistribution = dist;
          returnDistribution = dist;
          pickupKitDistribution = pickupDistribution.kitDistributions.find(
            (x) => x.type == 1
          );
          returnKitDistribution = pickupDistribution.kitDistributions.find(
            (x) => x.type == 2
          );
        } else {
          pickupDistribution = dist;
        }
      }

      return {
        ...response.parsedBody,
        distributions,
        pickupDistribution,
        pickupKitDistribution,
        returnDistribution,
        returnKitDistribution,
      };
    })
    .catch((error) => {
      console.log("error", error);
      throw new Error(error);
    });
}
