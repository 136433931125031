import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAnalysisResults } from "../../store/analyses/actionCreators";
import { RootState } from "../../store/config/types";
import { LoadingAnimation } from "../animations/LoadingAnimation";
import { FullWidthSnackbar } from "../common/FullWidthSnackbar";

type DiagnosticsProps = {
  patientId: string;
  isNutritionist?: boolean;
};

/* let traits = ["Niveles séricos de colesterol LDL", "Requerimiento de grasas Omega 3", "Sensibilidad a la glucosa",
  "Sensibilidad a la histamina", "Requerimiento de calcio", "Requerimiento de vitamina D", "Requerimiento de vitamina A",
  "Requerimiento de vitamina B6", "Requerimiento de vitamina B12", "Requerimiento de magnesio", "Respuesta al alcohol",
  "Sensibilidad a la sal", "Actividad antioxidante", "Niveles séricos de homocisteina", "Equilibrio depuración hepática",
  "Sensibilidad a la cafeína", "Tolerancia a la lactosa", "Sensación de saciedad", "Celiaquía", "Composición muscular",
  "Capacidad aeróbica o atlética", "Cafeína en deporte","Grasas saturadas y peso corporal"
] 

{
    "nutrient": "Sensibilidad a la histamina",
    "score": 2,
    "snps": [
        {
            "snp_ref": "rs2052129",
            "gene": "AOC1",
            "result": "GG"
        },
        {
            "snp_ref": "rs2071514",
            "gene": "AOC1",
            "result": "GG"
        },
        {
            "snp_ref": "rs1049793",
            "gene": "AOC1",
            "result": "CC"
        },
        {
            "snp_ref": "rs1050891",
            "gene": "HNMT",
            "result": "AA"
        },
        {
            "snp_ref": "rs11558538",
            "gene": "HNMT",
            "result": "CC"
        }
    ]
}

*/



const KeyToLabelDict = {
  "Grasas saturadas y peso corporal": {
    label: "Grasas saturadas y peso corporal",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite saber si estás dentro del grupo de riesgo,  adecuar el consumo de alimentos y mejorar tus hábitos alimentarios para prevenir el desarrollo de obesidad y las enfermedades asociadas a ella.

      Tus genes indican que tu predisposición al aumento de peso corporal, teniendo un consumo elevado de grasas saturadas se encuentra <b>bajo o habitual<b/> , pero tus genes no te determinan, tus hábitos si.`,
      1: "",
      2: `Conocer lo que dicen tus genes te permite saber si estás dentro del grupo de riesgo,  adecuar el consumo de alimentos y mejorar tus hábitos alimentarios para prevenir el desarrollo de obesidad y las enfermedades asociadas a ella.

      Tus genes indican que tu predisposición al aumento de peso corporal, teniendo un consumo elevado de grasas saturadas se encuentra <b>Aumentado</b>, pero tus genes no te determinan, tus hábitos si.`,
    },
    generalInfo: `Los lípidos o grasas son un componente importante de una dieta completa, nos aportan energía, forman parte de las membranas celulares y favorecen la absorción de ciertos nutrientes.
    Como sucede en otras áreas de la salud, a medida que se realizan nuevos estudios  conocemos más del impacto que tienen los alimentos en nuestro organismo. 
    A partir de la década de los años 50, las grasas se demonizaron, se proponía reducir el consumo total de grasas, entre ellas por ejemplo las  saturadas para evitar enfermedades cardiovasculares ya que se planteaba la hipótesis que al aumentar las partículas de colesterol LDL (“malo”) favorecen a enfermedades cardiovasculares. 
    Sin embargo, nuevos estudios hoy nos  revelan que, si bien producen un aumento de LDL sería de partículas pequeñas y densas, no de partículas grandes que si se asocian a esta enfermedad.
    Respecto al peso corporal sucede algo similar, no debemos erradicar las grasas, sino que para obtener sus beneficios  hay que seleccionarlas en cantidad y calidad .
    Hoy el  énfasis es la evaluación 360° de las personas, poniendo foco en cambiar nuestro estilo de vida además de controlar las grasas, incrementando el ejercicio físico, evitando el tabaco y el estrés.
    `,
    results: `Las personas con dos copias de la variante rs5082 presentan un índice de masa corporal (IMC) más alto en comparación con personas con una o ninguna copia, al realizar una dieta alta en grasas saturadas. Estos individuos también presentan mayor ingesta total de alimentos. Esta variante se encuentra cerca de un gen llamado APOA2, que codifica para la proteína  apolipoproteína A-II (apo A-II), que se encuentra en las partículas de colesterol HDL. Las personas con dos copias de la variante producen menos proteína apo A-II que las personas con ninguna o una variante.
    Tener dos copias del alelo G se asocia con un IMC más alto en el contexto de una dieta alta en grasas saturadas.
    `,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `<li>Tus genes no te predisponen a presentar un mayor aumento del peso corporal con el consumo elevado de grasas saturadas, pero esto no significa que no puedas desarrollar alto peso u obesidad si tu alimentación no es en base a alimentos reales y equilibrada.</li>
      <li>Esto es una predisposición que te acompañará siempre, sin embargo el peso corporal se modifica fundamentalmente por el ambiente y los hábitos.</li> 
      <li>A pesar de esto, realizar actividad física periódica te traerá múltiples beneficios (al menos 30 minutos diarios).</li>
      <li>Los valores de colesterol LDL se determinan mediante técnicas bioquímicas (laboratorio) que reflejen tu metabolismo al momento de la consulta.</li>
      <li>Se sugiere limitar la ingesta de grasas saturadas por debajo de 15-20 gr diarios ( un 10% de las grasas totales). </li>
      <li>Naturalmente los alimentos con altos niveles de grasas saturadas son las carnes sin procesar, lácteos  enteros, chocolate  y la mayoría de los alimentos procesados.</li>
      <li>La carne y los lácteos tienen otros  nutrientes necesarios para tu salud, no los elimines, selecciona versiones reducidas en grasa como lácteos descremados y cortes de carne magros: Vaca (peceto, lomo, nalga), pollo sin piel (pechuga y muslos), cerdo (lomo, paleta, solomillo) </li>
      <li>Consumí con mayor frecuencia carnes blancas como pollo y pescado (merluza, brótola, lenguado).</li>
      <li>El consumo de fiambres, achuras, mantecas/margarinas y embutidos que presentan altos niveles de grasas saturadas  reservalo solo para ocasiones especiales . </li>
      <li>En productos industriales este tipo de grasa está en tapas de masa, panes, galletitas y golosinas. </li>
      <li>Beneficiate del efecto antiinflamatorio de las grasas poliinsaturadas omega-3 preferentemente de aceite de oliva, aceitunas, semillas de chía y lino, nueces pecanas, aceite de lino, y nuez. </li>
      <li>Realizá al menos 4 comidas diarias. Evitá “picotear” programando 1 o 2 colaciones. Comé hasta sentirte levemente satisfecho, incorporá frutas y verduras variadas a todas tus comidas.</li>
      <li>Preferí el aceite de oliva extra virgen y palta  en crudo (una cucharada sopera por comida como condimento) y no en fritura. Para cocinar aceite de coco o ghee. Evitar aceites en spray</li>
      `,
      1: ``,
      2: `
      <li>Tus genes indican que estás predispuesto a presentar un mayor aumento del peso corporal con el consumo elevado de grasas saturadas, pero esto no significa que vayas a desarrollar alto peso u obesidad. Esto es una predisposición que te acompañará siempre, sin embargo el peso corporal se modifica fundamentalmente por el ambiente y los hábitos.</li>
      <li>A pesar de esto, realizar actividad física periódica te traerá múltiples beneficios (al menos 30 minutos diarios).</li>
      <li>Los valores de colesterol LDL se determinan mediante técnicas bioquímicas (laboratorio) que reflejen tu metabolismo al momento de la consulta.</li>
      <li>Limita  la ingesta de grasas saturadas por debajo de 15 gr diarios (7% de las grasas totales). Naturalmente los alimentos con altos niveles de grasas saturadas son las carnes sin procesar, lácteos  enteros, chocolate  y la mayoría de los alimentos procesados.</li>
      <li>La carne y los lácteos tienen otros  nutrientes necesarios para tu salud, no los elimines, selecciona versiones reducidas en grasa como lácteos descremados y cortes de carne magros: Vaca (peceto, lomo, nalga), pollo sin piel (pechuga y muslos), cerdo (lomo, paleta, solomillo) </li>
      <li>Consumí con mayor frecuencia carnes blancas como pollo y pescado (merluza, brótola, lenguado).</li>
      <li>El consumo de fiambres, achuras, mantecas/margarinas y embutidos que presentan altos niveles de grasas saturadas reservalo solo para ocasiones especiales . Por ejemplo un rulo de manteca contiene 2,5 grs de grasa saturada, un rollito de jamón y queso 4 grs de grasa saturada.</li>
      <li>Beneficiate del efecto antiinflamatorio de las grasas poliinsaturadas omega-3 preferentemente de aceite de oliva, aceitunas, semillas de chía y lino, nueces pecanas, aceite de lino, y nuez. </li>
      <li>Realizá al menos 4 comidas diarias. Evitá “picotear” programando 1 o 2 colaciones. Comé hasta sentirte levemente satisfecho, incorporá frutas y verduras variadas a todas tus comidas.</li>
      <li>Para mantener un peso saludable es importante incorporar cantidad y calidad suficiente de proteínas en tus comidas y fibra a fin de lograr saciedad.</li>
      <li>Preferí el aceite de oliva extra virgen y palta en crudo (una cucharada sopera por comida como condimento) y no en fritura. Para cocinar aceite de coco o ghee. Evitar aceites en spray</li>
      `,
    },
    bibliography: `Dolores Corella , Gina Peloso, Donna K Arnett, Serkalem Demissie, L Adrienne Cupples, Katherine Tucker, Chao-Qiang Lai, Laurence D Parnell, Oscar Coltell, Yu-Chi Lee, Jose M Ordovas.APOA2, dietary fat, and body mass index: replication of a gene-diet interaction in 3 independent populations
    `
  },
  "Cafeína en deporte": {
    label: "Cafeína en deporte",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite adecuar el consumo de cafeína acorde a tu metabolismo para maximizar tu rendimiento deportivo.
          Metabolizador rápido de cafeína.
      `,
      1: "",
      2: `Conocer lo que dicen tus genes te permite adecuar el consumo de cafeína acorde a tu metabolismo para maximizar tu rendimiento deportivo.
      Metabolizador lento de cafeína.
  `,
    },
    generalInfo: `La cafeína es una sustancia estimulante, y por este efecto es uno de los suplementos usados por deportistas. Casi todos los estudios concluyen que la cafeína mejora el rendimiento en un 10% y permite que el esfuerzo parezca más fácil.
      <li>Disminuye la percepción de fatiga en deportes de resistencia, mejorando el rendimiento y permitiendo mantener la actividad a una intensidad superior durante un periodo más largo.</li>    
      <li>Al ser un estimulante del sistema nervioso, aumenta la capacidad de alerta, concentración, tiempo de reacción y memoria.</li>    
      <li>Aumenta la movilización y utilización de grasas durante el ejercicio, retrasando el vaciamiento de las reservas de glucógeno.</li>    
      Las fuentes principales de cafeína que podemos encontrar son el café, el té, las bebidas cola, el guaraná, el mate o el chocolate. Además, existen en el mercado otros productos específicos  que contienen cafeína como bebidas energéticas, gelatinas o cápsulas.
      En los últimos años, se han identificado variantes genéticas que pueden modificar la respuesta a esta sustancia y permiten adaptar el tiempo y la dosis a las características de cada deportista para obtener una respuesta óptima.
      `,
    results: `El citocromo P450 1A2 (CYP1A2) es responsable aproximadamente del 95% del metabolismo de la cafeína y presenta una amplia variabilidad entre individuos. Variantes en el gen CYP1A2 que codifica para ese citocromo afectan su actividad, esto modifica la velocidad con la que se metaboliza la cafeína en el organismo, y por lo tanto sus acciones a nivel muscular`,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
        <li>Tus genes  indican que tu metabolismo de la cafeína es más rápido que lo habitual, por lo que es probable que las recomendaciones clásicas de dosis y tiempo de administración de suplementos con cafeína sean útiles para vos.</li>
        <li>Si utilizas la cafeína como suplemento deportivo, la pauta que indica 3 a 9 mg/kg aproximadamente 60 minutos antes del ejercicio probablemente es útil para que puedas obtener un efecto óptimo.</li>
        <li>Si haces deporte de alto rendimiento, consultá con un especialista.</li>
        <li>Aunque es poco probable que presentes síntomas, la cantidad de cafeína recomendada por día es de hasta 400 mg (4 tazas diarias).</li>
        <li>En caso de presentar síntomas, evitá las bebidas cafeinadas luego del mediodía, y reducí el consumo total diario de cafeína a menos de 200 mg/día (2 tazas). Si continuas con estos síntomas luego de reducir la dosis, considerá eliminarla.</li>
        <li>Si tenés factores de riesgo cardiovascular o hipertensión arterial, es posible que no sea el suplemento más indicado para vos.</li>
        <li>Para más información consultá a tu médico o deportólogo.</li>
      `,
      1: ``,
      2: `<li>Tus genes indican que tu metabolismo de la cafeína es más lento que lo habitual, por lo que es probable que las recomendaciones clásicas de dosis y tiempo de administración de suplementos con cafeína necesiten ser modificadas para resultar útiles para vos.</li>
      <li>Si utilizas la cafeína como suplemento deportivo, es posible que necesites aumentar la dosis de cafeína para alcanzar valores en sangre óptimos, o consumirla más de una hora antes de hacer ejercicio.</li>
      <li>Si haces deporte de alto rendimiento, consultá con un especialista. Cuando consumimos más de 500-600 mg/día, puede presentarse intranquilidad,aumento de la actividad, congestión facial, molestias gastrointestinales, arritmias. En metabolizadores lentos se relaciona el consumo de cafeína con aumento del riesgo de infarto agudo de miocardio (IAM).</li>
      <li>Es posible que el consumo de altas dosis de cafeína en vos se asocie a mayor riesgo de efectos adversos. En caso de presentar síntomas, evitá las bebidas cafeinadas luego del mediodía, y reducí el consumo total diario de cafeína a menos de 200 mg/día (2 tazas).</li>
      <li>Si tenés factores de riesgo cardiovascular o hipertensión arterial, es posible que no sea el suplemento más indicado para vos.</li>
      <li>Para más información consultá a tu médico o deportólogo.</li>`,
    },
    bibliography: `Craig Pickering , John Kiely Are the Current Guidelines on Caffeine Use in Sport Optimal for Everyone? Inter-individual Variation in Caffeine Ergogenicity, and a Move Towards Personalised Sports Nutrition
    `
  },
  "Capacidad aeróbica o atlética": {
    label: "Capacidad aeróbica",
    zone: "diagnosticResults",
    desc: {
      0: "Conocer lo que dicen tus genes acerca de tu capacidad aeróbica te permite adecuar tu entrenamiento para alcanzar tus objetivos deportivos.",
      1: "",
      2: "Conocer lo que dicen tus genes acerca de tu capacidad aeróbica te permite adecuar tu entrenamiento para alcanzar tus objetivos deportivos.",
    },
    generalInfo: `Podemos definir al rendimiento deportivo como la relación entre las marcas obtenidas y los recursos utilizados para lograrlas. 
    Por lo tanto, para maximizar tu rendimiento deberás usar todos tus recursos disponibles.
    Está influenciado por distintos factores como alimentación, habilidad técnica, personalidad, factores psicológicos, circunstanciales, apoyo tecnológico y genéticos entre otros.
    Según estudios, se calcula que los factores genéticos podrían explicar alrededor del 66% de la diferencia del estado atlético entre las personas.
    El organismo recurre a los hidratos y las grasas como fuente principal de energía, junto con la utilización de oxígeno. La genética influye en la eficacia con la que procesamos los nutrientes y obtenemos energía, y a esta capacidad se la denomina rendimiento energético. Nuestros genes pueden entonces influir en la forma en que obtenemos energía de los nutrientes, y afectar directamente nuestra capacidad atlética.
    `,
    results: `El gen PPARGC1A  está presente en tejidos con alta actividad metabólica, estos incluyen al músculo esquelético (durante el ejercicio). 
    Este gen controla transitoriamente el transporte de glucosa, la oxidación de lípidos y glucosa, orquestando la capacidad oxidativa del músculo.
    `,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que tu capacidad atlética sería mayor, lo que indica que podrías tener mayor aptitud para los deportes.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero el desarrollo de aptitudes deportivas depende fundamentalmente del entrenamiento y otros factores ambientales.</li>
      <li>Personas con este genotipo pueden beneficiarse al incluir tanto entrenamientos de fuerza/potencia (pocas repeticiones con mucha carga) y deportes que requieren esfuerzos máximos en corto tiempo como entrenamientos de resistencia aeróbica, de esfuerzo moderado por períodos largos.</li>
      <li>Una alimentación  balanceada contribuirá también al logro de tus objetivos.</li>
      <li>Diseñar tu entrenamiento apoyado por un profesional, de acuerdo a tus objetivos y focalizando en tus aptitudes, te ayudará a llegar más rápidamente a tus objetivo.</li>
      `,
      1: ``,
      2: `
        <li>Tus genes indican que tenés una capacidad atlética habitual.</li>
        <li>El desarrollo de aptitudes deportivas depende fundamentalmente del entrenamiento y otros factores ambientales, por lo cual tener capacidad atlética habitual no implica que no puedas alcanzar un rendimiento atlético elevado.</li>
        <li>Una alimentación balanceada e incluir tanto entrenamientos de fuerza/potencia (pocas repeticiones con mucha carga) y deportes que requieren esfuerzos máximos en corto tiempo como entrenamientos de resistencia aeróbica, de esfuerzo moderado por períodos largos contribuirá también al logro de tus objetivos.</li>
      `,
    },
    bibliography: `
    Nir Eynon 1, Yoav Meckel, Alberto Jorge Alves, Chen Yamin, Michael Sagiv, Ehud Goldhammer, Moran Sagiv.Is there an interaction between PPARD T294C and PPARGC1A Gly482Ser polymorphisms and human endurance performance
    <br/><br/>
    Ying Chen, Dongmei Wang, Pingping Yan, Shenglan Yan, Qing Chang, and Zhi Cheng
    Meta-analyses of the association between the PPARGC1A Gly482Ser polymorphism and athletic performance
    `
  },
  "Composición muscular": {
    label: "Composición muscular",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes acerca de la composición de tus músculos te permite adecuar tu entrenamiento para alcanzar tus objetivos deportivos.`,
      1: `Conocer lo que dicen tus genes acerca de la composición de tus músculos te permite adecuar tu entrenamiento para alcanzar tus objetivos deportivos.`,
      2: `Conocer lo que dicen tus genes acerca de la composición de tus músculos te permite adecuar tu entrenamiento para alcanzar tus objetivos deportivos.`,
    },
    generalInfo: `Nuestros músculos se componen de tres tipos principales de fibras, fibras de contracción lenta, intermedias y de contracción rápida.
    El tipo de fibra muscular viene determinado por nuestros genes, conocer el predominio de cada tipo fibra en cada persona nos permite obtener una ventaja relativa sobre los deportes que tal vez más nos convengan desarrollar o practicar. 
    De esta manera las personas que posean un alto porcentaje de fibras lentas podrían tener ventajas en actividades vinculadas con la resistencia, como por ejemplo, triatlón, running, carreras de fondo y medio fondo, ciclismo de ruta, natación de aguas abiertas, etc. 
    Quienes posean mayor predominio de fibras intermedias, que tienen capacidad aeróbica suficiente para resistir a la fatiga durante varios minutos, tendrán mayor aptitud para los deportes de esfuerzo intermitente o de conjunto, como puede ser el hockey, rugby, fútbol y básquet. Quienes posean en cambio un mayor predominio de fibras rápidas, se verán beneficiados en la realización de actividades breves y explosivas, vinculadas con la velocidad, como por ejemplo carreras de velocidad, pruebas de atletismo o lanzamientos, levantamiento de pesas, etc. 
    De todas maneras, la composición muscular es variable y se modifica en relación a la carga genética y el entrenamiento
    `,
    results: `El gen ACTN3 contiene instrucciones para producir alfa-actinina-3, una proteína que se encuentra en las fibras musculares e interviene en la contracción muscular.
    `,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      Fibras de contracción rápida.
        <li>Tus genes indican que en tus músculos tenés mayor porcentaje de fibras de contracción rápida, lo que indica que tenés mayor aptitud para deportes de potencia.</li>
        <li>Tu éxito deportivo puede verse más vinculado a actividades de alta intensidad y corta duración.</li>
        <li>Personas con tu composición muscular pueden beneficiarse al incluir entrenamiento de fuerza/potencia (pocas repeticiones con mucha carga), o deportes que requieren esfuerzos máximos en corto tiempo.</li>
        <li>Deportes como lanzamiento, levantamiento de pesas y carreras cortas pueden ser los adecuados para vos, aunque también podés beneficiarte de realizar entrenamiento funcional o crossfit que combinan entrenamiento de fuerza con resistencia.</li>
        <li>Es importante que incluyas algunas sesiones de entrenamiento de resistencia en tu plan semanal, para mejorar esta capacidad.</li>
        <li>Una alimentación adecuada, suficiente y balanceada contribuirá también al logro de tus objetivos.</li>
      `,
      1: `
      Fibras de contracción mixta.
      <li>Tus genes indican una composición muscular mixta entre fibras de contracción rápida y fibras de contracción lenta, lo que se asocia con habilidad para actividades de potencia como así también de resistencia.</li>
      <li>Personas con tu composición muscular pueden beneficiarse al incluir entrenamiento de fuerza/potencia (pocas repeticiones con mucha carga), como así también sesiones de entrenamiento de resistencia, de esfuerzo moderado por períodos prolongados (carga moderada con más repeticiones, o esfuerzos moderados sostenidos).</li>
      <li>Entrenamientos que combinen estos dos tipos de esfuerzo, como entrenamiento funcional o crossfit podrían ser los ideales para vos.</li>
      <li>También te recomendamos incluir algunas sesiones de entrenamiento de resistencia aeróbica en tu plan semanal, para mejorar esta capacidad.</li>
      `,
      2: `
      Fibras de contracción lenta.
      <li>Tus genes indican que en la composición de tus músculos hay predominio de fibras de contracción lenta y menor proporción de fibras de contracción rápida, lo que se asocia con mayor aptitud para deportes de resistencia.</li>
      <li>Personas con tu composición muscular pueden beneficiarse al incluir entrenamiento de resistencia aeróbica, de esfuerzo moderado por períodos largos (carga moderada con más repeticiones, o esfuerzos moderados sostenidos).</li>
      <li>Actividades como ciclismo, correr a ritmo moderado, natación pueden ser las adecuadas para vos. También te recomendamos incluir algunas sesiones de entrenamiento de potencia para mejorar esta capacidad. Entrenamientos que combinen estos dos tipos de esfuerzo, como entrenamiento funcional o crossfit también podrían ser beneficiosos para vos.</li>
      <li>Una alimentación adecuada, suficiente y balanceada contribuirá también al logro de tus objetivos.</li>
      `,
    },
    bibliography: `
    Alfred T et al. (2011). "ACTN3 genotype, athletic status, and life course physical capability: meta-analysis of the published literature and findings from nine studies." Hum Mutat. 32(9):1008-18.
    <br/>
    Berman Y and North KN. (2010). "A gene for speed: the emerging role of alpha-actinin-3 in muscle metabolism." Physiology (Bethesda). 25(4):250-9.
    <br/>
    Druzhevskaya AM et al. (2008). "Association of the ACTN3 R577X polymorphism with power athlete status in Russians." Eur J Appl Physiol. 103(6):631-4.
    <br/>
    Eynon N et al. (2013). "Genes for elite power and sprint performance: ACTN3 leads the way." Sports Med. 43(9):803-17.
    <br/>
    Guth LM and Roth SM. (2013). "Genetic influence on athletic performance." Curr Opin Pediatr. 25(6):653-8.
    <br/>
    Lee FX et al. (2016). "How does α-actinin-3 deficiency alter muscle function? Mechanistic insights into ACTN3, the 'gene for speed'." Biochim Biophys Acta. 1863(4):686-93.
    <br/>
    Ma F et al. (2013). "The association of sport performance with ACE and ACTN3 genetic polymorphisms: a systematic review and meta-analysis." PLoS One. 8(1):e54685.
    <br/>
    MacArthur DG et al. (2007). "Loss of ACTN3 gene function alters mouse muscle metabolism and shows evidence of positive selection in humans." Nat Genet. 39(10):1261-5.
    <br/>
    MacArthur DG et al. (2008). "An Actn3 knockout mouse provides mechanistic insights into the association between alpha-actinin-3 deficiency and human athletic performance." Hum Mol Genet. 17(8):1076-86.
    <br/>
    Moran CN et al. (2007). "Association analysis of the ACTN3 R577X polymorphism and complex quantitative body composition and performance phenotypes in adolescent Greeks." Eur J Hum Genet. 15(1):88-93.
    `
  },
  "Celiaquía": {
    label: "Celiaquía",
    zone: "diagnosticResults",
    desc: {
      0: `La enfermedad celíaca depende  de múltiples factores genéticos y ambientales.

      Tus genes indican que podrías presentar variantes <b>no asociadas</b> a la enfermedad celíaca, aunque esto no lo determina y las pruebas genéticas no son diagnósticas de la enfermedad. `,
      1: "",
      2: `La enfermedad celíaca depende  de múltiples factores genéticos y ambientales.

      Tus genes indican que podrías presentar variantes <b>asociadas</b> a la enfermedad celíaca, aunque esto no lo determina y las pruebas genéticas no son diagnósticas de la enfermedad. `,
    },
    generalInfo: `El gluten es una proteína que se encuentra presente en diversos tipos de cereales, como el trigo, la cebada, la avena y el centeno (TAAC). También se puede encontrar en productos alimenticios ya que es utilizado como espesante, tales como yogures, embutidos, salsas, alimentos congelados, chocolate, golosinas e incluso en pasta de dientes,  medicamentos y suplementos.

    Algunas personas tienen dificultad para digerir el gluten y este permanece en el intestino delgado produciendo daño o sensibilidad.
    Cuando esta intolerancia al gluten es permanente, con causa  genética y respuesta autoinmune se denomina celiaquía, pero hay personas con sensibilidad al gluten que no son estrictamente celíacas, sino que les es indigesto el gluten.
    
    Estas personas mejoran sus síntomas al limitar el gluten de la dieta. 
    
    Algunos Signos y síntomas: 
    <li>Enfermedad Celíaca: los síntomas más comunes son pérdida de peso, anemia, irritabilidad o dermatitis,  molestias digestivas que causan diarrea y malabsorción de nutrientes.</li>
    <li>Sensibilidad al gluten no celíaca: predominan las manifestaciones extradigestivas, como alteraciones del comportamiento, dolores óseos y articulares, calambres, adormecimiento de las extremidades, pérdida de peso o fatiga crónica.</li>
    <br/>
    <b>El estudio genético no hace diagnóstico de enfermedad celíaca, sino que deben realizarse más estudios para confirmar la enfermedad.</b>
    `,
    results: `Existe una  fuerte asociación entre la celiaquía con genes localizados en la región HLA (Human Leukocyte Antigen)
    La determinación de los alelos específicos constituyen la base de la prueba genética o test genético que puede emplearse en el diagnóstico de EC.
    `,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que es muy poco probable que desarrolles intolerancia al gluten o seas celíaco.</li>
      <li>Si aún asi presentas síntomas gastrointestinales, se asocien o no con la ingesta de gluten, te sugerimos que concurras a tu médico o nutricionista que descartará otras causas posibles para tus síntomas.</li>
      `,
      1: ``,
      2: `
      <li>Tus genes indican que tenés variantes asociadas al  desarrollo de  intolerancia al gluten y enfermedad celiaca, pero esto no significa que desarrolles en un futuro la enfermedad ya que depende de multiples factores y su diagnostico no es genetico.</li>
      <li>Si presentas síntomas gastrointestinales específicos, consulta con tu médico para que te solicite estudios específicos que permitan confirmar/descartar el diagnóstico.</li>
      <li>Es importante que no excluyas el gluten de tu dieta sin indicación médica, ya que los estudios diagnósticos deben realizarse mientras se consuman productos con gluten para obtener resultados confiables.</li>
      <li>Si tuvieras síntomas hasta tanto confirmes tu diagnóstico, podes sentirte aliviado comiendo volúmenes pequeños, prefiriendo verduras cocidas en lugar de crudas, fruta sin cáscara, leche deslactosada y moderando el consumo de azúcar, mate cebado, café y picantes.</li>
      <li>La detección temprana y el plan alimentario libre de gluten instaurado en el momento oportuno son fundamentales para evitar complicaciones secundarias de esta patología.</li>
      <li>Si tu diagnóstico fuera positivo deberás eliminar el gluten de tu dieta, consumiendo alimentos seguros que tengan la etiqueta “Sin TACC” (sin trigo, avena, cebada y centeno).</li>
      <li>Si se confirma el diagnóstico, deberas quitar de tu alimentación las harinas que presentan TACC y los productos preparados con ellas , como: galletas, panes, alfajores, tortas, masitas, pastas simples y rellenas, seitán (“simil carne” vegetariana), bebidas (cerveza, whisky).</li>
      <li>Existen alimentos que pueden tener gluten como aditivo alimentario. También deberás elegir la versión permitida de golosinas, enlatados, fiambres, quesos y pasta dental.</li>
      <li>Alimentos que naturalmente no tienen gluten: frutas, verduras, huevos, carnes, leche, yogur natural, fruta seca, especias, aceites.</li>
      <li>En caso que se confirmase tu diagnóstico podes consultar las marcas seguras sin TACC en la siguiente pagina web: acelu.org</li>
      <li>Para más información podés consultar a un médico clínico o gastroenterólogo.</li>
      `,
    },
    bibliography: `
    Victorien M. Wolters, M.D.and Cisca Wijmenga, Ph.D.Department of Pediatric Gastroenterology and Department of Biomedical Genetics, University Medical Centre Utrecht, Utrecht, The Netherlands; and Department of Genetics, University Medical Centre Groningen, Groningen, The Netherlands.Genetic Background of Celiac Disease and Its Clinical Implications
    <br/>
    Patrick CA Dubois,Gosia Trynka, Lude Franke, Karen A Hunt, Jihane Romanos, Alessandra Curtotti, Alexandra Zhernakova, Graham AR Heap, Róza Ádány, Arpo Aromaa, Maria Teresa Bardella.Multiple common variants for celiac disease influencing immune gene expression
    <br/>
    Alienke J. Monsuur, Paul I. W. de Bakker, Alexandra Zhernakova , Dalila Pinto, Willem Verduijn , Jihane Romanos , Renata Auricchio , Ana Lopez, David A. van Heel , J. Bart A. Crusius , Cisca Wijmeng.Effective Detection of Human Leukocyte Antigen Risk Alleles in Celiac Disease Using Tag Single Nucleotide Polymorphisms
    <br/>
    Concepción Núñez y José Antonio Garrote; en nombre del Grupo de Trabajo de “Inmunología y Genética” de la Sociedad Española de Enfermedad Celíaca (SEEC.Recomendaciones para la elaboración e interpretación de informes genéticos en enfermedad celíaca
    `
  },
  "Sensación de saciedad": {
    label: "Saciedad",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite adecuar el consumo de alimentos acorde a tu índice de saciedad  y objetivo terapéutico.

      Tus genes indican que tu sensación de saciedad podría estar <b>habitual</b>, tus genes no te determinan, tus hábitos si.`,
      1: "",
      2: `Conocer lo que dicen tus genes te permite adecuar el consumo de alimentos acorde a tu índice de saciedad  y objetivo terapéutico.

      Tus genes indican que tu sensación de saciedad podría estar <b>disminuida</b>, tus genes no te determinan, tus hábitos si.`,
    },
    generalInfo: `La saciedad es la sensación de estar “llenos” y determina el tiempo entre nuestras comidas: "Comer-Volver a comer". Esta sensación es la que nos indica cuándo “comimos lo suficiente” y debemos  limitar la ingesta. Se regula muy finamente por señales neuroendocrinas.
    Puede aparecer precozmente, la saciedad temprana es tener la sensación de estar lleno antes de lo normal o después de comer menos de lo usual, habitualmente está asociada a alguna patología del tracto gastrointestinal como úlceras y acidez.
    De modo contrario aquellas personas que poseen una variación genética en el gen FTO pueden  presentar una sensación de saciedad post comida disminuida o retardada, “no se sienten llenos cuando ingirieron un volúmen suficiente como para estarlo” por lo cual tienden a consumir mayor cantidad de alimentos/ calorías para poder saciarse.
    <br/><br/>
    Existe una escala de hambre-saciedad que va del 1 (hambre extrema)  al 10 (saciedad extrema) 
    ¿Vos ya conoces la tuya? ¿En qué punto de la escala “dejas de comer”?
    El punto ideal teórico es entre el 5-6
    <br/>
    <ol>
      <li>Hambre extrema, famélica, sensación de debilidad y mareos. Es urgente alimentarte.</li>
      <li>Mucha hambre, con irritación, ansiedad, bajos niveles de energía, el estómago ruge mucho. Comerías cualquier cosa aun si no es de tu total agrado.</li>
      <li>Bastante hambre, hay incomodidad pero aguantas con algún rugido en el estómago.</li>
      <li>Un poco de hambre, pero bajo control, puedes esperar la próxima comida sin problemas.</li>
      <li>Neutral, sensación de satisfacción, ni sientes hambre ni saciedad.</li>
      <li>Saciedad, el estómago está lleno pero de forma agradable, aunque si quisieras podrías comer un poco más.</li>
      <li>Lleno y satisfecho, no quieres comer más porque sabes que te haría sentirte incómodo.</li>
      <li>Muy lleno, te sientes incómodo y ya no puedes comer más.</li>
      <li>Muy lleno y con malestar, el estómago está hinchado y empiezas a notar dolor y empacho</li>
      <li>Extremadamente lleno, te sientes mal con náuseas y ganas de vomitar.</li>
    </ol>
    `,
    results: `El  gen FTO  está involucrado en la regulación de la ingesta dado que afecta la sensación de saciedad a nivel central, bloquea la señal de la leptina (“hormona de la saciedad”). En las personas con sensación de saciedad disminuida presentan mayor consumo y frecuencia de alimentos totales, saludables y no saludables, influyendo  sobre el peso corporal, perímetro abdominal, IMC y riesgo cardiovascular pudiendo asociarse con mayor riesgo de obesidad, diabetes y enfermedades cardiovasculares.`,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican  que tu sensación de saciedad es la habitual, pero esto no significa que no puedas tener tendencia  a un consumo aumentado de alimentos por otros motivos.</li>
      <li>Esto es una predisposición que te acompañará siempre, sin embargo los hábitos de consumo se basan además en factores nutricionales, ambientales y socioculturales.</li>
      <li>Elegí ambientes luminosos, frescos y tranquilos para comer. Así registrarás mejor tus emociones y disfrutarás más de lo que comes.</li>
      <li>Como estrategia para el desarrollo de buenos hábitos en torno a la comida te sugerimos comer en plato y no desde la fuente, no llevar la fuente de alimento a la mesa , elegir plato de postre que es más pequeño que el playo si necesitarás reducir tus porciones. Llenar el plato dejando los bordes libres, frezar las porciones extra que hayas preparado, evitar comer con  el celular, tablet  o  tele  para tener registro de la porción de alimento que consumís, apoyar los cubiertos en la mesa entre bocado y bocado.</li>
      <li>Planifica tus comidas distribuyendo los alimentos en 4 comidas principales y, de ser necesario, 2 colaciones para poder moderar el tamaño de las porciones.</li>
      <li>Si bien tu saciedad podría estar en los niveles habituales, los alimentos más saciantes son muy ricos en nutrientes, como las proteínas de carnes, huevos, legumbres y lácteos o  la fibra en las frutas y verduras.</li>
      <li>Preferí siempre alimentos nutritivos y saciantes antes que aquellos con elevados niveles de azúcar y grasa. Somos un cerebro ancestral en un mundo ultra procesado!</li>
      `,
      1: ``,
      2: `
      <li>Tus genes indican  que tu sensación de saciedad está disminuida, pero esto no significa que tengas tendencia a un consumo aumentado de alimentos.</li>
      <li>Esto es una predisposición que te acompañará siempre, sin embargo los hábitos de consumo se basan además en factores nutricionales, ambientales y socioculturales.</li>
      <li>Existen estrategias que te permitan controlar la porción de alimentos que consumís como: comer en plato y no desde la fuente, no llevar la fuente de alimento a la mesa ,elegir plato de postre que es más pequeño que el playo si necesitarás reducir tus porciones. Llenar el plato dejando los bordes libres, frezar las porciones extra que hayas preparado, evitar comer con  el celular, tablet  o  tele  para tener registro de la porción de alimento que consumís, apoyar los cubiertos en la mesa entre bocado y bocado.</li>
      <li>Evita lugares muy estimulantes, con música fuerte, muy oscuros o con  muchas personas. Elegí ambientes luminosos, frescos y tranquilos para comer.</li>
      <li>Planifica tus comidas distribuyendo los alimentos en 4 comidas principales y, de ser necesario, 2 colaciones para poder moderar el tamaño de las porciones. </li>
      <li>Para lograr mayor saciedad elegí alimentos con alto contenido proteico y fibra. Las proteínas las encontrarás en carnes, huevos, legumbres y lácteos mientras que la fibra principalmente en las frutas y verduras. Al consumirlas crudas o con cáscara añadís mayor dificultad masticatoria y eso favorece a que puedas sentir mayor saciedad. Recordá lavarlas muy bien antes.</li>
      <li>Evita consumir alimentos adictivos y de fácil consumo como caramelos, gomitas, snacks, galletitas. Somos un cerebro ancestral en un mundo ultra procesado!</li>
      `,
    },
    bibliography: `
    WARDLE, Jane, et al. Obesity associated genetic variation in FTO is associated with diminished satiety. The Journal of Clinical Endocrinology & Metabolism, 2008, vol. 93, no 9, p. 3640-3643.
    <br/>
    DEN HOED, Marcel, et al. Postprandial responses in hunger and satiety are associated with the rs9939609 single nucleotide polymorphism in FTO. The American journal of clinical nutrition, 2009, vol. 90, no 5, p. 1426-1432.
    <br/>
    MAGNO, Fernanda Cristina Carvalho Mattos, et al. Influence of FTO rs9939609 polymorphism on appetite, ghrelin, leptin, IL6, TNFα levels, and food intake of women with morbid obesity. Diabetes, metabolic syndrome and obesity: targets and therapy, 2018, vol. 11, p. 199.
    `
  },
  "Tolerancia a la lactosa": {
    label: "Lactosa",
    zone: "diagnosticResults",
    desc: {
      0: `Tus genes indican que podrías presentar una tolerancia <b>normal</b> a la lactosa, aunque esto no lo determina. 

      Esto te permite explicar una gran variedad de síntomas que se asocian a la falta de lactasa, para modificar tu dieta en consecuencia y mejorar tu calidad de vida.
      `,
      1: "",
      2: `Tus genes indican que podrías presentar una tolerancia <b>disminuida</b> a la lactosa, aunque esto no lo determina. 

      Esto te permite explicar una gran variedad de síntomas que se asocian a la falta de lactasa, para modificar tu dieta en consecuencia y mejorar tu calidad de vida.
      `,
    },
    generalInfo: `La lactosa es un tipo de azúcar que se encuentra en la leche (de vaca, de cabra, de oveja, etc) y también en sus derivados como quesos, cremas, mantecas, etc. 
    La lactosa es digerida en nuestro intestino por la enzima lactasa.  La lactasa descompone la molécula de lactosa, en sus dos componentes: glucosa y galactosa, para que luego estos azúcares simples puedan ser absorbidos por la pared intestinal y pasar al torrente sanguíneo. 
    Para poder digerir este azúcar el cuerpo humano necesita producir  lactasa.
    Cuando una persona es intolerante a la lactosa en realidad significa que su cuerpo no produce la lactasa suficiente.
    En más del 50% de los casos la causa es genética, al finalizar el periodo de lactancia (destete)  nuestro cuerpo pierde progresivamente la capacidad de producción de la lactasa. Somos la única especie animal que sigue consumiendo leche de otra especie luego de este periodo.
    <br/><br/>
    Un poco de historia, en la antigüedad, ante la dificultad de obtener alimentos,  la leche era un recurso preciado por sus propiedades nutricionales y aporte  proteico, razón por la cual era consumida a diario por los pueblos ganaderos. Aquellos que la digerían mejor  tenían una mutación genética localizada en el gen que hacía que la producción de la lactasa no se “desactivase” después de la infancia, en concreto se trata del SNP C/T 13910 (rs4988235) y, evidentemente, estos, mejor adaptados al medio, eran los que más sobrevivían y cuya mutación en el gen pasaba a sus hijos.
    <br/>
    Síntomas de intolerancia:
    <li>Al quedar parcialmente digerida la lactosa en tu intestino las personas con intolerancia  presentan síntomas intestinales derivados de la fermentación de la lactasa por las bacterias intestinales tales como: dolor abdominal, sensación de pesadez, gases, retortijones, distensión abdominal y diarrea.</li>
    <li>Existen diferentes grados de intolerancia, por lo que hay personas que tras consumir mínimas cantidades de leche presentan síntomas y otras sin embargo necesitan grandes cantidades para notar esos síntomas de intolerancia.</li>
    `,
    results: `El gen MCM6, ayuda a controlar la actividad del gen de la lactasa (gen LCT). La intolerancia a la lactosa en la adultez es causada por la expresión gradualmente decreciente del gen LCT luego de la infancia, que ocurre en la mayoría de los humanos.`,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que tenés baja posibilidad de presentar intolerancia a la lactosa, pero esto no significa que no puedas desarrollarla.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero la reacción de tu cuerpo a la lactosa también se modifica por el ambiente y otros factores.</li>
      <li>Nuestro cuerpo digiere la lactosa a través de una sustancia (enzima lactasa) que se produce a “demanda”, si habitualmente no consumís lacteos y los incorporaras repentinamente podrías tener alguna dificultad en su digestión, esto no significa que seas intolerante.</li>
      <li>Si por hábito no incorporaras leche a tu dieta, el calcio está presente también en tofu (queso vegetariano), semillas (sésamo, girasol), fruta seca (avellana, almendras), cereales (amaranto, quinoa y avena), y vegetales (coles, radicheta, garbanzos), aunque por absorberse distinto que las fuentes animales en algunos casos podrías requerir suplementación supervisada.</li>
      <li>Para más información podés consultar a un médico clínico o nutricionista.</li>
      `,
      1: ``,
      2: `
      <li>Tus genes indican que tenés posibilidad de presentar intolerancia a la lactosa, pero esto no significa que vayas a desarrollarla.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero la reacción de tu cuerpo a la lactosa también se modifica por el ambiente y otros factores.</li>
      <li>Si tenés síntomas gastrointestinales, aunque sean inespecíficos y no se relacionen con ingesta de lácteos, consultá con tu médico para confirmar/descartar el diagnóstico.</li>
      <li>Si fueras intolerante, para aliviar los síntomas descritos hasta tanto consultes a tú médico te recomendamos: alimentos a temperaturas templadas, preparaciones sólidas en lugar de líquidas, comer volúmenes pequeños y frecuentemente.</li>
      <li>Si necesitaras eliminar la lactosa de tu dieta, además de encontrarse en la leche y sus derivados (queso y yogur) está presente en: chocolate con leche, panes lacteados, helados a la crema, postres, preparaciones y salsas a base de leche.</li>
      <li>Si tenés dudas, hasta tanto confirmes tu intolerancia, los lácteos que podés consumir de forma segura son los deslactosados.</li>
      <li>La lactosa se encuentra como aditivo en la industria alimenticia, en las etiquetas la encontras como: monohidrato de lactosa, suero de leche y cuajo.</li>
      <li>La celiaquía y la enfermedad de Crohn producen una “símil intolerancia a la lactosa” (intolerancia secundaria). Es importante que tu médico lo diagnostique para instaurar el tratamiento correcto.</li>
      <li>Existen varios niveles de intolerancia. Los menos intolerantes pueden consumir quesos madurados y leches fermentadas, solo hay que hacer una correcta selección con tu médico o nutricionista.</li>
      <li>Si debieras eliminar la leche y derivados de tu dieta, el calcio está presente también en tofu (queso vegetariano), semillas (sésamo, girasol), fruta seca (avellana, almendras), cereales (amaranto, quinoa y avena), y vegetales (coles, radicheta, garbanzos), aunque por absorberse distinto que las fuentes animales en algunos casos podría requerir suplementación supervisada.</li>
      `,
    },
    bibliography: `Nana Yaa Baffour-Awuah* , Sarah Fleet* , Susan S. Baker, Johannah L. Butler, Catarina Campbell, Samuel Tischfield, Paul D. Mitchell, Jennifer E. Moon, Sophie Allende-Richter, Laurie Fishman, Athos Bousvaros, Victor Fox, Mikko Kuokkanen, Robert K. Montgomery, Richard J. Grand, and Joel N. Hirschhorn.Functional Significance of Single Nucleotide Polymorphisms in the Lactase Gene in Diverse United States Subjects and Evidence for a Novel Lactase Persistence Allele at -13909 in Those of European Ancestry`
  },
  "Sensibilidad a la cafeína": {
    label: "Cafeína",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite saber si metabolizás rápido o lento la cafeína, y adecuar tu consumo para evitar los efectos secundarios de su exceso.

      Tus genes indican que podrías metabolizar la cafeína de forma <b>rápida</b>, aunque esto no lo determina.`,
      1: "",
      2: `Conocer lo que dicen tus genes te permite saber si metabolizás rápido o lento la cafeína, y adecuar tu consumo para evitar los efectos secundarios de su exceso.

      Tus genes indican que podrías metabolizar la cafeína de forma <b>lenta</b>, aunque esto no lo determina.`,
    },
    generalInfo: `
    La cafeína es una sustancia de sabor amargo que está naturalmente presente en las semillas, frutos secos u hojas de más de 60 variedades de plantas. Las de la familia Coffea (café) o Theaceae (té), el cacao, guaraná, y la yerba mate.
    El principal mecanismo de acción de la cafeína es como agente estimulante del sistema nervioso central (SNC), aunque además se han observado efectos a nivel muscular y cardiovascular.
    <br/><br/>
    Su principal uso es como estimulante del sistema nervioso para disminuir la somnolencia. Se recomienda idealmente iniciar su consumo luego de 90 minutos de despertarnos ya que por la mañana naturalmente nuestro cuerpo experimenta un aumento de cortisol (CAR, respuesta al despertar del cortisol), hormona que nos permite estar alertas y despiertos. Si durante este pico consumimos cafeína disminuimos el efecto de ambos, el de la cafeína y el del cortisol. 
    <br/><br/>
    La cantidad de cafeína recomendada por día es de hasta 400 mg (4 tazas diarias), pero no todas las personas reaccionan igual frente a esa dosis de cafeína. El exceso de esta sustancia puede producir en algunos individuos, insomnio, nerviosismo, malestar estomacal, aumento de la frecuencia cardiaca  y/o temblores musculares.
    <br/><br/>
    En metabolizadores lentos se relaciona el consumo de cafeína con aumento del riesgo de infarto agudo de miocardio (IAM). 
    `,
    results: `El gen CYP1A2 codifica el citocromo P4501A2, una enzima responsable del 95% del metabolismo de la cafeína. La variante rs762551 en este gen afecta la velocidad de metabolización de la cafeína.`,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que tu metabolismo de la cafeína es más rápido, por lo que tu posibilidad de tener efectos adversos asociados a su consumo es menor.</li>
      <li>Aunque es poco probable que presentes síntomas, la cantidad de cafeína recomendada por día es de hasta 400 mg, que equivale a 4 tazas de café.</li>
      <li>Otras bebidas que contienen cafeína son el té (verde y negro), las que contienen cacao y las bebidas cola (una taza tiene la mitad de cafeína que una taza de café, aproximadamente 50 mg).</li>
      <li>Los tipos de café que más cafeína poseen son los de filtro y expreso, mientras que el instantáneo y descafeinado son los que menos cantidad poseen.</li>
      <li>En caso de presentar síntomas, evitá las bebidas cafeinadas luego del mediodía, y reducí el consumo total diario de cafeína a menos de 200 mg/día (2 tazas). Si continuas con estos síntomas luego de reducir la dosis, considerá eliminarla.</li>
      <li>El café es un poderoso antioxidante ayudando a frenar el envejecimiento celular, aunque metabolices la cafeína más rápido que otras personas igual gozas de estos beneficios.</li>
      <li>Si las bebidas cafeinadas no fueran de tu preferencia, las moras, frambuesas y ananá son las frutas con una capacidad antioxidante similar al café.</li>
      <li>Puede tener efecto diurético y/o laxante, como tu organismo la metaboliza rápidamente es poco probable que presentes estos signos al consumirla.</li>
      <li>La cafeína atraviesa la placenta. Si estás embarazada o planeas estarlo considerá que la dosis recomendada es de 200 mg /día, lo que equivale a 2 tazas diarias.</li>
      <li>Para más información consultá a tu médico o nutricionista.</li>
      `,
      1: ``,
      2: `
      <li>Tus genes indican que tu metabolismo de la cafeína es más lento, por lo que tu posibilidad de tener efectos adversos asociados a su consumo es mayor que la media de la población.</li>
      <li>Se recomienda consumir hasta 400mg de cafeína por día, que equivale a 4 tazas de café.</li>
      <li>Otras bebidas que contienen cafeína son el té (verde y negro), las que contienen cacao y las bebidas cola (una taza tiene la mitad de cafeína que una taza de café, aproximadamente 50 mg).</li>
      <li>Los tipos de café que más cafeína poseen son los de filtro y expreso, mientras que el instantáneo y descafeinado son los que menos cantidad poseen.</li>
      <li>En caso de presentar síntomas, evitá las bebidas cafeinadas luego del mediodía, y reducí el consumo total diario de cafeína a menos de 200 mg/día (2 tazas). Si continuas con estos síntomas luego de reducir la dosis, considerá eliminarla.</li>
      <li>El café tiene sustancias antioxidantes y antiinflamatorias, pero si tenés factores de riesgo cardiovasculares o hipertensión arterial limita su consumo a 2 tazas diarias porque puede producir un aumento transitorio de la presión arterial.</li>
      <li>Si tu colesterol LDL está elevado posiblemente el café filtrado sea el más apropiado. El papel de filtro retiene ciertas sustancias (diterpenos) presentes en el café que se asocian al aumento de colesterol.</li>
      <li>La cafeína aumenta la eliminación de calcio, pero si tu alimentación es equilibrada esto no representa un factor de riesgo importante para tu salud ósea.</li>
      <li>La cafeína atraviesa la placenta. Si estás embarazada o planeas estarlo considerá que la dosis recomendada es de 200 mg/día, lo que equivale a 2 tazas diarias.</li>

      `,
    },
    bibliography: `
    Djordjevic, N., Ghotbi, R., Jankovic, S., & Aklillu, E. (2010). Induction of CYP1A2 by heavy coffee consumption is associated with the CYP1A2 -163C>A polymorphism. European Journal of Clinical Pharmacology, 66(7), 697–703.
    <br/>
    Sachse, C., Brockmöller, J., Bauer, S., & Roots, I. (1999). Functional significance of a C→A polymorphism in intron 1 of the cytochrome P450 CYP1A2 gene tested with caffeine: Short report. British Journal of Clinical Pharmacology, 47(4), 445–449.
    `
  },
  "Equilibrio depuración hepática": {
    label: "Depuración hepática",
    zone: "diagnosticResults",
    desc: {
      0: `No presenta riego genético.`,
      1: "",
      2: `Conocer lo que dicen tus genes te permite modificar tu estilo de vida y hábitos alimentarios para mejorar la forma en la que tu cuerpo depura sustancias.

      Tus genes indican que algunos mecanismo de depuración hepática podrían estar <b>disminuido</b>, aunque esto no lo determina.`,
    },
    generalInfo: `Existen variantes en nuestros genes que pueden afectar, favoreciendo o protegiéndonos, a los riesgos específicos que un individuo tiene a desarrollar un problema de salud al exponerse a una sustancia química específica o xenobiótico. 
    La respuesta que ese individuo tenga a determinada sustancia, está en estrecha relación con el proceso de detoxificación que su cuerpo lleve a cabo.
    Las diferencias genéticas entre los individuos pueden hacer a una persona de hasta 100 veces más susceptible a los efectos de una sustancia dada que a otro.
    Nuestro organismo cuenta con varias rutas de destoxificación, pero una de las principales ocurre en el hígado.
    El objetivo de esta vía es transformar los tóxicos solubles en agua a fin de poder eliminarlos principalmente por orina.
    Este proceso se desarrolla en dos etapas, la primera intervienen enzimas del citocromo P450 y en la segunda etapa distintas reacciones químicas donde se conjuga la sustancia tóxica con grupos solubles en agua.
    Síntomas de sobrecarga hepática a xenobióticos: 
    <br/><br/>
    <li>Malestar general, migraña, fatiga.</li>
    <li>Dolor articular, fiebre crónica</li>
    <li>Reacciones no deseadas a medicamentos habituales</li>
    <li>Reacciones adversas a la cafeína y al alcohol</li>
    <li>Neuropatías y trastornos del sueño</li>
    `,
    results: `EL CYP 450 es una superfamilia de enzimas  que intervienen en el metabolismo de fármacos, carcinógenos, esteroides, ácidos grasos, pesticidas y alcaloides. Su función es la de metabolizar en primera instancia estas sustancias a través de reacciones de oxidorreducción, protegiéndonos del daño o estrés oxidativo que producirían en el resto del organismo si no fueran procesados.
    
    `,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `No hay recomendaciones.`,
      1: ``,
      2: `
      <li>Tus genes indican que podrías presentar un desequilibrio en la actividad enzimática de alguna de  las fases de destoxificacion, aunque esto no lo determina. </li>
      <li>Algunas sustancias derivadas de la cocción prolongada de la carne, como las acrilamidas y furanos (coloración marrón), aminas aromáticas (brasas), micotoxinas ( legumbres y fruta secas) y epóxidos de los plásticos,  son metabolizadas por el hígado en dos fases,  a su forma soluble para poder eliminarlas principalmente por orina.</li>
      <li>Si existiera un desequilibrio en estas fases dichas sustancias tenderían a acumularse. Para lograr un equilibrio y que tu cuerpo pueda deshacerse correctamente de estas sustancias:</li>
      <li>Evita recalentar la comida en envases plásticos o beber de botellas plásticas. Siempre preferí el vidrio  o cerámica.</li>
      <li>Almacena la fruta seca y semillas en  bolsa hermética en la heladera o frezzer, retirala tiempo antes de consumirla, así evitaras que se forme moho. Las micotoxinas están presentes naturalmente aunque no las veamos.</li>
      <li>Asegúrate de incluir diariamente vitaminas del complejo B ( cereales integrales, fruta seca y vegetales), minerales como magnesio, selenio y zinc, compuestos azufrados de brócoli, coliflor, col rizada, col de brusela, kale , ajo y cebolla. Germinados como brotes de soja o alfalfa.</li>
      <liPodés utilizar hierbas como boldo, diente de león, alcachofera. Podes infusionarla o mezclarla con la yerba mate.></li>
      <li>Es importante el consumo diario de antioxidantes como el resveratrol de uvas y frutos rojos, vitamina C  y glutatión (espinacas, palta, esparrago, papa, brócoli y cítricos)</li>
      <li>Hidratate con agua potable frecuentemente</li>
      <li>Minimiza el consumo de azúcar, refinados, procesados, carne roja, café en exceso y alcohol.</li>
      `,
    },
    bibliography: `Arzu Gunes, Marja-Liisa Dahl.Variation in CYP1A2 activity and its clinical implications: Influence of environmental factors and genetic polymorphism.`
  },
  "Niveles séricos de homocisteina": {
    label: "Homocisteína",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite saber si tenés predisposición a valores elevados de Homocisteína en sangre y modificar tus hábitos para evitar los efectos de su exceso.

      Tus genes indican que tus niveles de homocisteína en sangre podrían estar <b>habituales</b>, tus genes no te determinan, tus hábitos si.
      `,
      1: `Conocer lo que dicen tus genes te permite saber si tenés predisposición a valores elevados de Homocisteína en sangre y modificar tus hábitos para evitar los efectos de su exceso.

      Tus genes indican que tus niveles de homocisteína en sangre podrían estar <b>moderados</b>, tus genes no te determinan, tus hábitos si.
      `,
      2: `Conocer lo que dicen tus genes te permite saber si tenés predisposición a valores elevados de Homocisteína en sangre y modificar tus hábitos para evitar los efectos de su exceso.

      Tus genes indican que tus niveles de homocisteína en sangre podrían estar <b>aumentados</b>, tus genes no te determinan, tus hábitos si.
      `,
    },
    generalInfo: `La homocisteína es un aminoácido que en nuestro organismo participa en diferentes vías del metabolismo celular asociada a vitamina B12,  vitamina B6 y ácido fólico. 
    Normalmente, los niveles de este aminoácido libre en sangre son bajos, ya que es una molécula agresiva para la pared arterial y se considera un factor influyente en el desarrollo de enfermedades cardio y cerebrovasculares.
    La causa principal de exceso de homocisteína plasmática es el déficit de ácido fólico, aunque existen factores genéticos que contribuyen en un 60% a mayores niveles de este aminoácido en sangre.
    <br/><br/>
    Factores de riesgo:
    <li>Deficiencia de vitaminas como  folato, la vitamina B12 y, en menor medida, por la deficiencia de la vitamina B6 pueden aumentar los niveles de homocisteína en sangre.</li>
    <li>Además, también está influenciado por enfermedades crónicas, obesidad, hipotiroidismo y deterioro renal.</li>
    `,
    results: `La homocisteína se forma en nuestro organismo por varias vías,  como producto intermedio del metabolismo de otro aminoácido, la metionina y a partir de la homocisteína por remetilación llevado a cabo por  la enzima homocisteína metiltransferasa (MTR) la cual requiere de vitamina B12 y de 5, 10 -metilentetrahidrofolato para su correcto funcionamiento. 
    El gen MTHFR codifica para la enzima Metilentetrahidrofolato reductasa. Esta enzima ayuda a descomponer la homocisteína en sangre. Si su actividad se encuentra alterada, aumentando el riesgo de tener niveles elevados de homocisteína en sangre sobre todo en personas que no incorporen vitamina B en su dieta.
    `,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que  no tenés predisposición  a presentar un aumento de homocisteína en plasma, pero esto no significa que no puedas desarrollarlo.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero los niveles de homocisteína se modifican fundamentalmente por el ambiente y el estilo de vida.</li>
      <li>Los niveles de homocisteína pueden aumentar con la edad y por el déficit de vitamina B12, B6 o ácido fólico en la dieta, el consumo de alcohol, o el tabaquismo.</li>
      <li>Si sospechas que podés tener niveles de homocisteína fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Para mantener niveles saludables de homocisteína en plasma, llevá una dieta balanceada, con buen aporte de vitamina B12, B6 y ácido fólico.</li>
      <li>Más allá de tu predisposición genética siempre es saludable hacer ejercicio regularmente. Se recomiendan al menos 150 minutos de actividad física moderada o 75 minutos de actividad aeróbica vigorosa semanales.</li>
      <li>Si bebes alcohol, hacelo con moderación (una bebida al día para las mujeres y dos bebidas al día para los hombres. Una bebida equivale a 350 ml de cerveza, 147 ml de vino o 44 ml de licor con graduación de 80 grados).</li>
      `,
      1: `
      <li>Tus genes indican que tenés predisposición a presentar un leve aumento de homocisteína en plasma, pero esto no significa que vayas a desarrollarlo.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero los niveles de homocisteína se modifican fundamentalmente por el ambiente y el estilo de vida.</li>
      <li>Los niveles de homocisteína pueden aumentar con la edad y por el déficit de vitamina B12, B6 o ácido fólico en la dieta, el consumo de alcohol, o el tabaquismo.</li>
      <li>Si sospechas que podés tener niveles de homocisteína fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Para mantener niveles saludables de homocisteína en plasma, llevá una dieta balanceada, con buen aporte de vitamina B12, B6 y ácido fólico.</li>
      <li>Los alimentos con mayor contenido de B12 son: carnes rojas y blancas, vísceras, yema de huevo, leche y queso.</li>
      <li>Sin embargo, el consumo excesivo de carne, puede aumentar los niveles de Homocisteína. Se debe regular el consumo de carnes y tener una dieta balanceada, para ello, consultar con un nutricionista.</li>
      <li>En caso de no consumir productos de origen animal, se puede optar por la suplementación de ácido fólico y B12 para evitar deficiencias. Tu médico o nutricionista te indicará las dosis adecuadas basándose en tus requerimientos.</li>
      <li>Si bebes alcohol, hacelo con moderación (una bebida al día para las mujeres y dos bebidas al día para los hombres. Una bebida equivale a 350 ml de cerveza, 147 ml de vino o 44 ml de licor con graduación de 80 grados).</li>
      <li>Si estás embarazada, o planificando un embarazo, consultá con tu obstetra. Es posible que indique realizar estudios adicionales, seguimiento o tratamiento específico.</li>
      `,
      2: `
      <li>Tus genes indican que tenés  predisposición  a presentar un aumento de homocisteína en plasma, pero esto no significa que vayas a desarrollarlo.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero los niveles de homocisteína se modifican fundamentalmente por el ambiente y el estilo de vida.</li>
      <li>Los niveles de homocisteína pueden aumentar con la edad y por el déficit de vitamina B12, B6 o ácido fólico en la dieta, el consumo de alcohol, o el tabaquismo.</li>
      <li>Si sospechas que podés tener niveles de homocisteína fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Para mantener niveles saludables de homocisteína en plasma, llevá una dieta balanceada, con buen aporte de B12, B6 y ácido fólico.</li>
      <li>Los alimentos con mayor contenido de B12 son: carnes rojas y blancas, vísceras, yema de huevo, leche y queso.</li>
      <li>Sin embargo, el consumo excesivo de carne, puede aumentar los niveles de Homocisteína. Se debe regular el consumo de carnes y tener una dieta balanceada, para ello, consultar con un nutricionista.</li>
      <li>En caso de no consumir productos de origen animal, se puede optar por la suplementación de ácido fólico y B12 para evitar deficiencias. Tu médico o nutricionista te indicará las dosis adecuadas basándose en tus requerimientos.</li>
      <li>Si bebes alcohol, hacelo con moderación (una bebida al día para las mujeres y dos bebidas al día para los hombres. Una bebida equivale a 350 ml de cerveza, 147 ml de vino o 44 ml de licor con graduación de 80 grados).</li>
      <li>Si estás embarazada, o planificando un embarazo, consultá con tu obstetra. Es posible que indique realizar estudios adicionales, seguimiento o tratamiento específico.</li>
      `,
    },
    bibliography: `
    Meta-analysis study to evaluate the association of MTHFR C677T polymorphism with risk of ischemic stroke (nih.gov)	
    <br/>
    Sci-Hub | Effect of homocysteine on pregnancy: A systematic review. Chemico-Biological Interactions, 293, 70–76 | 10.1016/j.cbi.2018.07.021
    Common genetic loci influencing plasma homocysteine concentrations and their effect on risk of coronary artery disease (nih.gov)
    `
  },
  "Actividad antioxidante": {
    label: "Antioxidante",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite adecuar el consumo de alimentos y estilo de vida acorde a tu metabolismo para minimizar los efectos del estrés oxidativo.

      Tus genes indican que tu estado antioxidante podría ser el <b>habitual</b>, aunque tus genes no te determinan, tus hábitos si.`,
      1: "",
      2: `Conocer lo que dicen tus genes te permite adecuar el consumo de alimentos y estilo de vida acorde a tu metabolismo para minimizar los efectos del estrés oxidativo.

      Tus genes indican que tu estado antioxidante podría estar <b>disminuido</b>, aunque tus genes no te determinan, tus hábitos si.`,
    },
    generalInfo: `Los radicales libres se pueden formar a partir de muchas moléculas, pero los derivados de la molécula del oxígeno son los que tienen mayor importancia en nuestro organismo.
    Hemos  diseñado estrategias genéticas para defendernos de las agresiones de los radicales libres generados naturalmente del metabolismo celular aerobio.
    Se trata de las enzimas que fomentan su inactivación y eliminación, como la superóxido dismutasa (SOD), la catalasa, y la glutatión peroxidasa, entre otras. 
    Hay otras moléculas que existen en nuestro organismo protegiéndonos del estrés oxidativo, como la ceruloplasmina, las vitaminas antioxidantes, los betacarotenos, la cisteína, y las sustancias que actúan como agonistas del glutatión.
    <br/><br/>
    Signos y síntomas:
    <li>Cuando el equilibrio de oxidantes/antioxidantes se altera y no podemos deshacernos de forma efectiva de los radicales libres padecemos envejecimiento prematuro y aumenta el riesgo de numerosas enfermedades como cardiovasculares, neurológicas, degenerativas y ciertos tipos de cáncer.</li>
    `,
    results: "El gen SOD2 codifica para la enzima superóxido dismutasa, que cataliza la dismutación de superóxido en oxígeno y peróxido de hidrógeno.​ Debido a esto es una importante defensa antioxidante en la mayoría de las células expuestas al oxígeno.",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que tu estado antioxidante podría ser el habitual, aunque esto no lo determina.</li>
      <li>Esta es una predisposición que te acompañará siempre, pero tu estado antioxidante depende de varias moléculas y del estilo de vida que lleves.</li>
      <li>Podés beneficiarte del consumo habitual de antioxidantes. Los antioxidantes son sustancias presentes en algunos alimentos que pueden beneficiarte previniendo o retrasando el daño que los radicales libres de oxígeno le generan a nuestras células.</li>
      <li>Consumí diariamente alimentos ricos en vitamina A, C y E.</li>
      <li>La vitamina A la encontrás en frutas y verduras como : espinacas, zanahorias, brócoli, calabaza, batata. Las frutas que la contienen son por ejemplo el melón y el mango.</li>
      <li>En los productos de origen animal encontrás vitamina A en leche y quesos.</li>
      <li>La vitamina C está presente en las naranjas, arándanos,  moras, pomelo, pimientos, kiwi, brócoli, melón, tomate, frutillas.</li>
      <li>La vitamina E la encontrás en aceites (preferentemente oliva) y semillas y frutas secas tales como semillas de girasol, almendras, avellanas, maní así como también en algunas hortalizas verdes.</li>
      <li>El oligoelemento selenio también es un poderoso antioxidante ya que ayuda a la formación de enzimas antioxidantes de nuestro organismo. Lo encontrás en: girasol, sésamo, nueces de brasil, arroz integral, coco, avena y cereales integrales,  y pescados y mariscos.</li>
      <li>El licopeno y luteína son pigmentos (rojo y amarillo respectivamente) relacionados con los betacarotenos con poderosa función antioxidante. El licopeno está presente preferentemente en el tomate y la luteína en algunos vegetales como kale, palta,acelga, apio, espárrago, repollo brusela, nabos.</li>
      <li>También podés utilizar diariamente especias y condimentos con función antioxidante: canela, cúrcuma, tomillo, menta, romero, jengibre.</li>
      <li>Preferí lácteos fermentados, kéfir de leche, yogur y queso de leche de oveja, cabra y búfala.</li>
      <li>Las infusiones son una buena y fácil opción para el consumo de antioxidantes, preferí los té de arándanos, matcha o té verde, té de rooibos, yerba mate.</li>
      `,
      1: ``,
      2: `
      <li>Tus genes indican que tu estado antioxidante podría estar disminuido y tu cuerpo podría no estar deshaciéndose correctamente de los radicales libres de oxígeno que se producen como producto del metabolismo celular.</li>
      <li>Esta es una predisposición que te acompañará siempre, pero tu estado antioxidante depende de varias moléculas y del estilo de vida que lleves.</li>
      <li>Los antioxidantes son sustancias presentes en algunos alimentos que pueden beneficiarte previniendo o retrasando el daño que los radicales libres de oxígeno le generan a nuestras células.</li>
      <li>Consumí diariamente alimentos ricos en vitamina A, C y E.</li>
      <li>La vitamina A la encontrás en frutas y verduras como : espinacas, zanahorias, brócoli, calabaza, batata. Las frutas que la contienen son por ejemplo el melón y el mango.</li>
      <li>En los productos de origen animal encontrás vitamina A en leche y quesos.</li>
      <li>La vitamina C está presente en las naranjas, arándanos,  moras, pomelo, pimientos, kiwi, brócoli, melón, tomate, frutillas.</li>
      <li>Preferi el maiz morado al amarillo, es el mas rico en antocianinas.</li>
      <li>La vitamina E la encontrás en aceites ( preferentemente oliva) y semillas y frutas secas tales como semillas de girasol, almendras, avellanas, maní así como también en algunas hortalizas verdes.</li>
      <li>El oligoelemento selenio también es un poderoso antioxidante ya que ayuda a la formación de enzimas antioxidantes de nuestro organismo. Lo encontrás en: girasol, sésamo, nueces de brasil, arroz integral, coco, avena y cereales integrales,  y pescados y mariscos.</li>
      <li>El licopeno y luteína son pigmentos (rojo y amarillo respectivamente) relacionados con los betacarotenos con poderosa función antioxidante. El licopeno está presente preferentemente en el tomate y la luteína en algunos vegetales como kale, palta,acelga, apio, espárrago, repollo brusela, nabos.</li>
      <li>También podés utilizar diariamente especias y condimentos con función antioxidante: canela, cúrcuma, tomillo, menta, romero, jengibre. La curcuma se activa con la pimienta, úsalas juntas!</li>
      <li>Las infusiones son una buena y fácil opción para el consumo de antioxidantes, preferí los té de arándanos, matcha o té verde, té de rooibos, yerba mate.</li>
      <li>Si te gusta el chocolate, selecciona aquellos de calidad !</li>
      <li>Preferí lácteos fermentados, kéfir de leche, yogur y queso de leche de oveja, cabra y búfala.</li>
      <li>Preferí siempre actividades al aire libre, el entrenamiento incluye descansar,evita ultraprocesados, chequea los niveles de tus vitaminas. Lleva un estilo de vida anti inflamatorio.</li>  
      `,
    },
    bibliography: `Zhi Xu, MD, PhD ; Haixia Zhu, MD ; John M. Luk.Clinical Significance of SOD2 and GSTP1 Gene Polymorphisms in Chinese Patients With Gastric Cancer`
  },
  "Sensibilidad a la sal": {
    label: "Sal",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite saber tu grado de sensibilidad a la sal, y adecuar tu consumo para evitar los efectos secundarios de su exceso.
      Tus genes indican que podrías presentar una sensibilidad <b>habitual</b> a la sal, tus genes no te determinan, tus hábitos si. `,
      1: `Conocer lo que dicen tus genes te permite saber tu grado de sensibilidad a la sal, y adecuar tu consumo para evitar los efectos secundarios de su exceso.
      Tus genes indican que podrías presentar una sensibilidad <b>moderada</b> a la sal, tus genes no te determinan, tus hábitos si. `,
      2: `Conocer lo que dicen tus genes te permite saber tu grado de sensibilidad a la sal, y adecuar tu consumo para evitar los efectos secundarios de su exceso.
      Tus genes indican que podrías presentar una sensibilidad <b>aumentada</b> a la sal, tus genes no te determinan, tus hábitos si. `,
    },
    generalInfo: `La sal común es un mineral esencial para la vida. Su nombre científico es cloruro sódico porque está constituida por iones de sodio y cloruro cristalizados. El sodio es imprescindible para el correcto funcionamiento del sistema nervioso, el sistema muscular y la homeostasis, es decir, la regulación de los líquidos dentro de nuestras células. El sodio, además, es el responsable del sabor salado.
    Además de su uso culinario, desde la antigüedad se usa como conservante por su capacidad de absorber la humedad (agua) reduciendo el crecimiento microbiano.
    <br/><br/>
    En encontrar la justa medida y tener acceso a las versiones más saludables y ricas en minerales está el desafío. 
    `,
    results: `El gen ACE contiene la información para producir la enzima convertidora de angiotensina I. 
    Se produce en el sistema nervioso central,  riñón y pulmón. Convierte la angiotensina I en angiotensina II, que incrementa la vasoconstricción, interviniendo de esta forma en la regulación de la presión arterial
    El alelo I (inserción) está asociado con una menor producción de  la enzima otorgando mayor sensibilidad a  la hipertensión por consumo de sal.
    `,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que la actividad de la enzima ACE es la habitual, lo que significa que tu sensibilidad a la sal y sus efectos hipertensivos son similares a cualquier individuo, pero esto no quiere decir que sea así ya que interfieren otros factores.</li>
      <li>De todos modos, no te confíes, la presión arterial responde no solo a nuestros genes sino a nuestro estilo de vida y alimentación. Mantené un peso saludable. No fumes</li>
      <li>Hacé ejercicio regularmente. Se recomiendan al menos 150 minutos de actividad física moderada o 75 minutos de actividad aeróbica vigorosa semanales.</li>
      <li>Seguí una dieta saludable con alimentos ricos en calcio, potasio, magnesio y fibra, que al combinarse, ayudan a disminuir la presión arterial.</li>
      <li>Hacete controles de rutina que incluyan medición de la presión arterial. Si bebes alcohol, hacelo con moderación (una bebida al día para las mujeres y dos bebidas al día para los hombres. Una bebida equivale a 350 ml de cerveza, 147 ml de vino o 44 ml de licor con graduación de 80 grados).</li>
      <li>Evita el consumo diario de alimentos ricos en sodio. El sodio está presente en cantidades muy elevadas en salsas y productos untables, enlatados, conservas, fiambres y quesos duros</li>
      <li>El consumo de sal recomendado por la OMS es de 5gr de sal/día, es decir 1 cucharadita de café al ras distribuida a lo largo del día.</li>
      <li>Si ya tenés HTA, es importante prevenir que empeore o que cause complicaciones. Controlate con tu médico periódicamente y seguí tu plan de tratamiento incluyendo hábitos de vida saludables y, si está indicado, medicamentos.</li>
      <li>Para más información podés consultar a tu médico clínico.</li>
      `,
      1: `
      <li>Tus genes indican que  la actividad de la  enzima ACE  es moderada, lo que significa que tu sensibilidad a la sal es moderada, pero esto no quiere decir que sea así.</li>
      <li>Mantené un peso saludable. No fumes</li>
      <li>Hacé ejercicio regularmente. Se recomiendan al menos 150 minutos de actividad física moderada o 75 minutos de actividad aeróbica vigorosa semanales.</li>
      <li>Seguí una dieta saludable con alimentos ricos en calcio, potasio, magnesio y fibra, que al combinarse, ayudan a disminuir la presión arterial.</li>
      <li>Hacete controles de rutina que incluyan medición de la presión arterial. Tené un registro de tu presión arterial realizando una correcta toma (piernas descruzadas, con la suela del pie tocando el piso, espalda contra el respaldo de la silla y el brazo a la altura del corazón).</li>
      <li>Si bebes alcohol, hacelo con moderación (una bebida al día para las mujeres y dos bebidas al día para los hombres. Una bebida equivale a 350 ml de cerveza, 147 ml de vino o 44 ml de licor con graduación de 80 grados).</li>
      <li>Evita el consumo excesivo o diario de alimentos ricos en sodio. El sodio está presente en cantidades muy elevadas en salsas y productos untables, enlatados, conservas, fiambres y quesos duros</li>
      <li>Si ya tenés HTA, es importante prevenir que empeore o que cause complicaciones. Controlate con tu médico periódicamente y seguí tu plan de tratamiento incluyendo hábitos de vida saludables y, si está indicado, medicamentos.</li>
      <li>Para más información podés consultar a tu médico clínico.</li>
      `,
      2: `
      <li>Tus genes indican que la probabilidad de presentar menor ACE está aumentada, siendo más sensible a la sal y sus efectos hipertensivos, pero esto no quiere decir que sea así.</li>
      <li>Mantené un peso saludable. No fumes</li>
      <li>Hacé ejercicio regularmente. Se recomiendan al menos 150 minutos de actividad física moderada o 75 minutos de actividad aeróbica vigorosa semanales.</li>
      <li>Seguí una dieta saludable con alimentos ricos en calcio, potasio, magnesio y fibra, que al combinarse, ayudan a disminuir la presión arterial.</li>
      <li>Hacete controles de rutina que incluyan medición de la presión arterial.</li>
      <li>Si bebes alcohol, hacelo con moderación (una bebida al día para las mujeres y dos bebidas al día para los hombres. Una bebida equivale a 350 ml de cerveza, 147 ml de vino o 44 ml de licor con graduación de 80 grados).</li>
      <li>Evita el consumo excesivo o a diario de alimentos ricos en sodio. El sodio está presente en cantidades muy elevadas en salsas y productos untables, panes, sopas cremas,  enlatados, conservas, fiambres y quesos duros.</li>
      <li>Como estrategia podes lavar las legumbres o atún enlatado  a fin de quitarle el exceso de sodio. Ponelo en un colador bajo el chorro de agua y dejalo correr unos minutos.</li>
      <li>Elegí productos que digan sin sal/sodio o muy bajo en sodio.</li>
      <li>Nuestro paladar puede reeducarse, reemplaza la sal de mesa por otros condimentos que le den sazón, como pimienta y ajo en polvo, cebolla, albahaca, cilantro y comino.</li>
      <li>Si ya tenés HTA, es importante prevenir que empeore o que cause complicaciones. Controlate con tu médico periódicamente y seguí tu plan de tratamiento incluyendo hábitos de vida saludables y, si está indicado, medicamentos.</li>
      <li>Para más información podés consultar a tu médico clínico.</li>
      `,
    },
    bibliography: `Esteban Poch, Daniel González, Vicente Giner, Ernesto Bragulat, Antonio Coca, Alejandro de la Sierr.Molecular Basis of Salt Sensitivity in Human Hypertension Evaluation of Renin-Angiotensin-Aldosterone System Gene Polymorphism`
  },
  "Respuesta al alcohol": {
    label: "Alcohol",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite saber si metabolizás rápido o lento el alcohol, y adecuar tu consumo para evitar los efectos secundarios de su exceso.

      Tus genes indican que tu metabolismo del alcohol sería <b>habitual</b>, aunque esto no lo determina.`,
      1: `Conocer lo que dicen tus genes te permite saber si metabolizás rápido o lento el alcohol, y adecuar tu consumo para evitar los efectos secundarios de su exceso.

      Tus genes indican que tu metabolismo del alcohol sería <b>intermedio</b>, aunque esto no lo determina.`,
      2: `Conocer lo que dicen tus genes te permite saber si metabolizás rápido o lento el alcohol, y adecuar tu consumo para evitar los efectos secundarios de su exceso.

      Tus genes indican que tu metabolismo del alcohol sería <b>acelerado</b>, aunque esto no lo determina.`,
    },
    generalInfo: `El alcohol es una sustancia que, al ser incorporada, sufre distintas reacciones químicas en nuestro organismo, generando metabolitos intermedios, como el acetaldehído, responsable de  afectar a nuestro cuerpo. 
    En algunos individuos debido a su genética, esta oxidación del alcohol puede ser más rápida o más lenta. Si es más rápida tenderán a acumular más fácilmente acetaldehído en los tejidos.
    <br/><br/>
    El acetaldehído tiene efectos deletéreos en el cuerpo, por esta razón las personas con metabolismo rápido a diferencia de las de metabolismo lento tienen menos tolerancia al alcohol, sufren resacas más fuertes y frecuentes y tienen un menor riesgo de dependencia del alcohol.
    Con el tiempo, el consumo excesivo de alcohol puede causar enfermedades crónicas, entre ellas. hipertensión arterial, enfermedad cardiaca, accidentes cerebrovasculares, enfermedad del hígado y problemas digestivos. Cáncer de mama, boca, garganta, laringe, esófago, hígado, colon y recto.
    <br/><br/>
    Conocer tus genes permite conocer tu velocidad de metabolismo del alcohol, y adecuar tu consumo para evitar los efectos secundarios de su exceso.
    <br/><br/>
    <u>El alcohol produce en nuestro organismo:</u>
    <li>Enrojecimiento e incrementos de temperatura en la piel por vasodilatación</li>
    <li>Ojos enrojecidos, dolor de cabeza</li>
    <li>Sed y aumento de la micción</li>
    <li>Náuseas</li>
    <li>Aumento del ritmo cardíaco (taquicardia) y respiratorio</li>
    `,
    results: `El gen ADH1B contiene la información para producir la enzima Alcohol deshidrogenasa 1B (ADH1B) en nuestro hígado.
    Esta enzima cataliza la conversión de alcohol a acetaldehído. 
    Se ha observado una asociación entre la presencia del SNP rs1229984 (ADH2*2) en el gen que codifica para esta enzima y el desarrollo de dependencia al alcohol.`,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que tu metabolismo del alcohol está dentro de límites normales, por lo que tu posibilidad de tener efectos adversos asociados a su consumo es menor.</li>
      <li>Es probable que no sientas aversión por el alcohol y no presentes resacas luego de su consumo.</li>
      <li>Aunque tu metabolismo sea normal, el consumo excesivo de alcohol puede aumentar el riesgo de tener problemas de salud graves.</li>
      <li>Si bebes alcohol, hacelo con moderación (una bebida al día para las mujeres y dos bebidas al día para los hombres. Una bebida equivale a 350 ml de cerveza, 147 ml de vino o 44 ml de licor con graduación de 80 grados).</li>
      <li>Recordá que el alcohol aporta energía( calorías) pero son “ vacías”, es decir que no tiene ningún nutriente necesario para el organismo.</li>
      <li>En ciertas situaciones, los riesgos del alcohol pueden ser mayores. Evitá especialmente el alcohol si estás embarazada, si tuviste un accidente cerebrovascular ó  tenés una enfermedad hepática, pancreática o cardiovascular.</li>
      `,
      1: `
      <li>Tus genes indican que tu metabolismo del alcohol se encuentra ligeramente aumentado, por lo que tu posibilidad de tener efectos adversos asociados a su consumo es mayor, pero no significa que si o si vayas a presentarlos.</li>
      <li>Si elegís beber alcohol, hacelo con moderación (una bebida al día para las mujeres y dos bebidas al día para los hombres. Una bebida equivale a 350 ml de cerveza, 147 ml de vino o 44 ml de licor con graduación de 80 grados)</li>
      <li>Si tenés factores de riesgo cardiovascular o hipertensión arterial limitá el consumo de alcohol.</li>
      <li>Recordá que el alcohol aporta energía( calorías) pero son “ vacías”, es decir que no tiene ningún nutriente necesario para el organismo.</li>
      <li>En ciertas situaciones, los riesgos del alcohol pueden ser mayores. Evitá especialmente el alcohol si estás embarazada, si tuviste un accidente cerebrovascular ó  tenés una enfermedad hepática, pancreática o cardiovascular.</li>
      `,
      2: `
      <li>Tus genes indican que tu metabolismo del alcohol se encuentra aumentado, por lo que tu posibilidad de tener efectos adversos asociados a su consumo es mayor, pero no significa que si o si vayas a presentarlos.</li>
      <li>Es probable que presentes resacas fuertes y frecuentes luego de su consumo y  hasta sientas aversión por el alcohol.</li>
      <li>Si elegís beber alcohol, hacelo con moderación en situaciones especiales (una bebida al día para las mujeres y dos bebidas al día para los hombres. Una bebida equivale a 350 ml de cerveza, 147 ml de vino o 44 ml de licor con graduación de 80 grados)</li>
      <li>Si tenés factores de riesgo cardiovascular o hipertensión arterial limitá el consumo de alcohol.</li>
      <li>En ciertas situaciones, los riesgos del alcohol pueden ser mayores. Evitá especialmente el alcohol si estás embarazada, si tuviste un accidente cerebrovascular ó  tenés una enfermedad hepática, pancreática o cardiovascular.</li>
      `,
    },
    bibliography: `
    Raymond K. Walters, Renato Polimanti, Emma C. Johnson,  Jeanette N. McClintick, Mark J. Adams,  Amy E. Adkins,.Transancestral GWAS of alcohol dependence reveals common genetic underpinnings with psychiatric disorders. Published in final edited form as: Nat Neurosci. 2018 December ; 21(12): 1656–1669. doi:10.1038/s41593-018-0275-1
    `
  },
  "Requerimiento de magnesio": {
    label: "Magnesio",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permitirá adecuar el consumo de este mineral para mantener los valores séricos dentro de los rangos de normalidad.

      Tus genes indican que podrías presentar niveles de magnesio <b>habituales</b> en sangre, tus genes no te determinan, tus hábitos si.`,
      1: `Conocer lo que dicen tus genes te permitirá adecuar el consumo de este mineral para mantener los valores séricos dentro de los rangos de normalidad.

      Tus genes indican que podrías presentar niveles de magnesio <b>altos</b> en sangre, tus genes no te determinan, tus hábitos si.`,
      2: `Conocer lo que dicen tus genes te permitirá adecuar el consumo de este mineral para mantener los valores séricos dentro de los rangos de normalidad.

      Tus genes indican que podrías presentar niveles de magnesio <b>bajos</b> en sangre, tus genes no te determinan, tus hábitos si.`,
    },
    generalInfo: `El magnesio es un mineral esencial y el segundo electrolito más abundante de nuestro cuerpo. Interviene en más de 300 reacciones químicas en nuestro organismo.
    Es necesario en muchos procesos celulares, casi el 60% del magnesio del cuerpo está en los huesos, el 20% en músculo esquelético y otro 20% en tejido blando. Una pequeña fracción circula en nuestra sangre, cuya valoración se asocia con algunas enfermedades crónicas tales como diabetes, hipertensión y osteoporosis.
    <br/><br/>
    Debido a la desmineralización de los suelos cada vez es más complejo poder cubrir su requerimiento solo con alimentos. Aunque la mayoría de las deficiencias se adquieren por una alimentación insuficiente, se ha demostrado que las concentraciones de magnesio séricas tienen un componente hereditario cercano a solo el 30%.
    <br/><br/>
    <u>Los niveles bajos de magnesio pueden causar:</u>
    <li>Síntomas de deficiencia leve: Pérdida de apetito, náuseas, vómitos, fatiga, debilidad.</li>
    <li>Síntomas de deficiencia moderada: entumecimiento, hormigueo, contracciones musculares y calambres, convulsiones, cambios de personalidad, latidos cardíacos irregulares.</li>
    <li>Síntomas de deficiencia grave: bajo nivel de calcio en sangre (hipocalcemia), bajo nivel de potasio en la sangre (hipopotasemia).</li>
    <br/><br/>
    <u>Factores de riesgo:</u>
    <br/>
    La falta de magnesio se puede presentar más frecuentemente en personas que consumen alcohol en exceso o en aquellas que presentan una absorción alterada, por ejemplo en algunas enfermedades gastrointestinales o cirugías que causen malabsorción (cirugía bariátrica entre otras), adultos mayores y personas que tengan diabetes tipo 2.
    `,
    results: `El gen ATP2B1 contiene la información para producir una enzima que depende de magnesio para producir ATP (energía celular) aprovechando la energía suministrada por gradientes de calcio.`,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que tu concentración de magnesio en sangre podría encontrarse dentro del rango normal, pero esto no significa que sea así.</li>
      <li>Si sospechás que podés tener niveles de magnesio fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Es probable que llevar una dieta variada sea suficiente para obtener los niveles de magnesio necesarios para tu cuerpo.</li>
      <li>Podés cubrir tus necesidades consumiendo diariamente cereales, legumbres y vegetales: medio plato playo de cereales + una cucharada chica de legumbres al día (o en su remplazo medio plato playo semanal) y un plato de verduras crudas y/o cocidas al día.</li>
      <li>Los alimentos con mayor contenido de magnesio son los cereales, legumbres, frutas secas, semillas y vegetales.</li>
      <li>Los vegetales donde predomina son los de color verde oscuro, como espinaca y acelga. Para conservar el magnesio del alimento, el mejor método de cocción es vapor, no hervido.</li>
      <li>Preferí consumir cereales en su versión integral, ya que el magnesio se encuentra en el germen y corteza del grano. Por ejemplo: arroz integral, germen de trigo, salvado de avena y panes integrales.</li>
      <li>Las legumbres donde predomina son las lentejas, garbanzos, porotos y judías verdes. También son excelentes fuentes: Almendras, dátiles y semillas de girasol. Para mejorar la absorción actívalas, dejándolas en remojo 2-4 horas mínimo antes de consumirlas.</li>
      `,
      1: `
      <li>Tus genes indican que en algún momento podrías presentar una concentración aumentada de magnesio en sangre, aunque esto no quiere decir que lo presentes.</li>
      <li>Es probable que llevar una dieta equilibrada sea suficiente para obtener los niveles de magnesio necesarios para tus músculos.  Si deseas verificar tus  niveles en sangre,  se utilizan técnicas de laboratorio (hemograma), que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>A pesar de tener esta predisposición no dejes de consumir alimentos ricos en este mineral, el exceso de magnesio relacionado a la dieta es poco frecuente. El magnesio  es neuroprotector, protege frente a la diabetes y enfermedades cardiovasculares, mejora el estado de ánimo y nuestro descanso.</li>
      <li>Los alimentos con mayor contenido de magnesio son los cereales, legumbres, frutas secas, semillas y vegetales.</li>
      <li>Preferí consumir cereales en su versión integral, ya que el magnesio se encuentra en el germen y corteza del grano. Por ejemplo: arroz integral, germen de trigo, salvado de avena y panes integrales.</li>
      <li>Las legumbres donde predomina son las lentejas, garbanzos, porotos y judías verdes. También son excelentes fuentes: Almendras, dátiles y semillas de girasol. Para mejorar la absorción actívalas, dejándolas en remojo 2-4 horas mínimo antes de consumirlas.</li>
      <li>Los vegetales donde predomina son los de color verde oscuro, como espinaca y acelga. Para conservar el magnesio del alimento, el mejor método de cocción es el vapor, no hervido.</li>
      <li>Para más información podés consultar a un médico clínico o nutricionista.</li>
      `,
      2: `
      <li>Tus genes indican que tus niveles de magnesio en sangre podrían encontrarse disminuidos, pero esto no significa que sea así. Es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Los alimentos con mayor contenido de magnesio son los cereales, legumbres, frutas secas, semillas y vegetales.</li>
      <li>Los niveles de magnesio mejoran consumiendo niveles suficientes de proteínas animales/vegetales, y vitamina. Esto lo lográs combinando los alimentos ricos en magnesio con carnes (vaca, pollo, pescado) tofu, seitán y lácteos (leche, queso, yogur).</li>
      <li>Preferí consumir cereales en su versión integral, ya que el magnesio se encuentra en el germen y corteza del grano. Por ejemplo: arroz integral, germen de trigo, salvado de avena y panes integrales.</li>
      <li>Las legumbres donde predomina son las lentejas, garbanzos, porotos y judías verdes. También son excelentes fuentes: Almendras, dátiles y semillas de girasol.</li>
      <li>Los vegetales donde predomina son los de color verde oscuro, como espinaca y acelga. Para conservar el magnesio del alimento, el mejor método de cocción es el vapor, no hervido.</li>
      <li>Las semillas donde predomina son las de girasol, zapallo y lino. Para mejorar la absorción actívalas, dejándolas en remojo 2-4 horas antes de consumirlas.</li>
      <li>Si sos celíaco, tenés  deficiencia de B1 y B6 o  enfermedades que se asocian con malabsorción intestinal o nefropatías (enfermedades del riñón) podes tener mayor probabilidad de tener magnesio bajo.</li>
      <li>Existen distintos tipos de suplementos de magnesio, los más recomendable son los orgánicos y quelados como por ejemplo: Bisglicinato, citrato, treonato, taurinato, malato, gluconato, aspartato. Cada “forma” tiene una indicación y dosis, para más información podés consultar a un médico clínico o nutricionista.</li>
      <li>Evita los suplementos inorgánicos como óxido, cloruro, carbonato y sulfato.</li>
      `,
    },
    bibliography: `Meyer, Tamra E., Germaine C. Verwoert, Shih-Jen Hwang, Nicole L. Glazer, Albert V. Smith, Frank J. A. van Rooij, Georg B. Ehret, et al. 2010. “Genome-Wide Association Studies of Serum Magnesium, Potassium, and Sodium Concentrations Identify Six Loci Influencing Serum Magnesium Levels.” PLoS Genetics 6 (8). https://doi.org/10.1371/journal.pgen.1001045.`
  },
  "Requerimiento de vitamina B12": {
    label: "Vitamina B12",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite adecuar el consumo de vitamina B12 acorde a tu metabolismo, para evitar los efectos de su deficiencia o exceso.

      Tus genes indican que tus niveles vitamina B12 en sangre podrían ser <b>habituales</b>, tus genes no te determinan, tus hábitos si.`,
      1: `Conocer lo que dicen tus genes te permite adecuar el consumo de vitamina B12 acorde a tu metabolismo, para evitar los efectos de su deficiencia o exceso.

      Tus genes indican que tus niveles vitamina B12 en sangre podrían estar <b>aumentados</b>, tus genes no te determinan, tus hábitos si.`,
      2: `Conocer lo que dicen tus genes te permite adecuar el consumo de vitamina B12 acorde a tu metabolismo, para evitar los efectos de su deficiencia o exceso.

      Tus genes indican que tus niveles vitamina B12 en sangre podrían estar <b>disminuidos</b>, tus genes no te determinan, tus hábitos si.`,
    },
    generalInfo: `La vitamina B12  es una vitamina esencial, participa en la generación de nuevas moléculas de ADN y proteínas, la regulación de expresión de genes y las vías celulares para  obtener energía celular. Participa también en la maduración de los glóbulos rojos de la sangre, y de otras células sanguíneas.
    No podemos sintetizar nuestra propia B12, por lo que la única manera de obtenerla es a partir de alimentos y a partir de la microbiota intestinal.
    La deficiencia de vitamina B12 ocurre cuando el cuerpo no recibe o no puede absorber la cantidad de vitamina que el cuerpo necesita.
    <br/><br/>
    <b><u>Signos y síntomas:</u></b>
    Los niveles bajos de vitamina B12 pueden causar:
    <li>Anemia megaloblástica, debe ser diagnosticada por técnica de laboratorio.</li>
    <li>Pérdida del equilibrio, entumecimiento u hormigueo en brazos y piernas, alteración de la sensibilidad, disminución de la capacidad para sentir dolor, alteración en la forma de caminar o moverse, trastornos visuales, irritabilidad y depresión.</li>
    <li>Glositis (dolor y enrojecimiento de la lengua), úlceras en la boca.</li>
    <li>Malabsorción de algunos nutrientes.</li>

    `,
    results: `El gen CUBN codifica para el receptor encargado de la absorción de la vitamina B12 junto con el FI en el intestino. El gen TCN1 codifica para la proteína de unión a la vitamina B12, transcobalamina I o haptocorrina, facilita el ingreso de la vitamina a las células.`,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que tu concentración de vitamina B12 en sangre podría encontrarse dentro del rango normal, pero esto no significa que sea así.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero los niveles de vitamina B12 también se modifican por el ambiente y el estilo de vida.</li>
      <li>Si sospechas que podés tener niveles de B12 fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Es probable que el aporte de tu dieta de alimentos ricos en vitamina B12 sea suficiente para el correcto funcionamiento de tus procesos biológicos.</li>
      <li>Si estás embarazada o planeas estarlo te recomendamos mantener los niveles de esta vitamina en sus valores normales para el correcto desarrollo fetal. Consultá a tu médico si necesitas suplementación.</li>
      <li>Los alimentos con mayor contenido de B12 son: carnes rojas y blancas, vísceras, yema de huevo, leche y queso.</li>
      <li>Cubrimos nuestra necesidad diaria por ejemplo con 100 gr de carne + 1 taza de 250 ml de leche.</li>
      <li>Si sos vegetariano o vegano consultale a tu médico si necesitas suplementación, ya que la B12 está exclusivamente en alimentos de origen animal.</li>
      <li>Consumir antiácidos con regularidad (en caso de úlceras y gastritis) podría disminuir la absorción de esta vitamina. La B12 necesita un medio ácido para absorberse correctamente.</li>
      `,
      1: `
      <li>Tus genes indican que podrías presentar concentración aumentada de B12 en sangre, pero esto no quiere decir que las tengas.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero los niveles de vitamina B12 también se modifican por el ambiente y el estilo de vida.</li>
      <li>En condiciones normales y llevando una dieta equilibrada, es poco probable presentar altos niveles de esta vitamina en sangre a causa de la dieta, su aumento se asocia al consumo de suplementos.</li>
      <li>Si sospechas que podés tener niveles de B12 fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Los alimentos con mayor contenido de B12 son: carnes rojas y blancas, vísceras, yema de huevo, leche y queso. No los excluyas de tu alimentación sin supervisión hasta tanto no confirmes que los valores están aumentados</li>
      <li>Cubrimos nuestra necesidad diaria por ejemplo con 100 gr de carne + 1 taza de 250 ml de leche.</li>
      <li>La cantidad de cada vitamina que necesitas depende de la edad y el sexo. Otros factores, como el embarazo y la salud, también son importantes.</li>
      <li>Para más información podés consultar a un médico clínico o nutricionista.</li>
      `,
      2: `
      <li>Tus genes indican que tu concentración de vitamina B12 en sangre podría encontrarse disminuida, pero esto no significa que sea así.</li>
      <li>Si sospechas que podés tener niveles de B12 fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Es probable que si consumís diariamente alimentos ricos en vitamina B12 obtengas la cantidad suficiente para el correcto funcionamiento de tus procesos biológicos.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero los niveles de vitamina B12 también se modifican por el ambiente y el estilo de vida.</li>
      <li>Si estás embarazada o planeas estarlo te recomendamos mantener los niveles de esta vitamina en sus valores normales para el correcto desarrollo fetal. Consultá a tu médico si necesitas suplementación.</li>
      <li>Los alimentos con mayor contenido de B12 son: carnes rojas y blancas, vísceras, yema de huevo, leche y queso. Podés cubrir tu necesidad diaria por ejemplo con 100 gr de carne + 1 taza de 250 ml de leche.</li>
      <li>La B12 está exclusivamente en alimentos de origen animal, si sos vegetariano o vegano consultale a tu médico si necesitas suplementación.</li>
      <li>Consumir antiácidos con regularidad (en caso de úlceras y gastritis) podría disminuir la absorción de esta vitamina. La B12 necesita un medio ácido para absorberse correctamente.</li>
      <li>En algunas enfermedades que alteran la función intestinal (por ejemplo: celiaquía, enfermedad de Crohn) y deficiencias vitamínicas (por ejemplo: déficit de B6) los valores de esta vitamina pueden estar disminuidos.</li>
      <li>La B12 es muy sensible a la temperatura, las carnes y huevo se consideran la mejor opción para la adquisición de esta vitamina. Evita cocciones muy prolongadas y en piezas muy pequeñas, preferible mas tiempo de cocción a menor temperatura</li>
      `,
    },
    bibliography: `Surendran, S., A. Adaikalakoteswari, P. Saravanan, I. A. Shatwaan, J. A. Lovegrove, and K. S. Vimaleswaran. 2018. “An Update on Vitamin B12-Related Gene Polymorphisms and B12 Status.” Genes & Nutrition 13 (February): 2.`
  },
  "Requerimiento de vitamina B6": {
    label: "Vitamina B6",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite adecuar el consumo de vitamina B6 acorde a tu metabolismo, para evitar los efectos de su deficiencia o exceso.

      Tus genes indican que tus niveles vitamina B6 en sangre podrían estar <b>habitual</b>, tus genes no te determinan, tus hábitos si.`,
      1: `Conocer lo que dicen tus genes te permite adecuar el consumo de vitamina B6 acorde a tu metabolismo, para evitar los efectos de su deficiencia o exceso.

      Tus genes indican que tus niveles vitamina B 6 en sangre podrían estar <b>aumentada</b>, tus genes no te determinan, tus hábitos si.`,
      2: `Conocer lo que dicen tus genes te permite adecuar el consumo de vitamina B6 acorde a tu metabolismo, para evitar los efectos de su deficiencia o exceso.

      Tus genes indican que tus niveles vitamina B 6 en sangre podrían estar <b>disminuida</b>, tus genes no te determinan, tus hábitos si.`,
    },
    generalInfo: `La vitamina B6 o piridoxina participa en la elaboración de  neurotransmisores (sustancias que permiten a las neuronas comunicarse entre sí), la hemoglobina (transporta el oxígeno en la sangre) y componentes del sistema inmune como los anticuerpos. 
    Además, participa en la obtención de energía a partir de los hidratos de carbono, especialmente en los músculos, incrementando el rendimiento muscular durante el ejercicio físico. La vitamina B6 también puede favorecer la pérdida de peso, ya que ayuda a que nuestro cuerpo obtenga energía a partir de las grasas acumuladas en el organismo. Otra función importante de esta vitamina es favorecer la absorción de magnesio, hierro y B12.
    <br/><br/>
    Signos y síntomas:
    <li>El exceso de piridoxina, puede generar pérdida de control de los movimientos corporales.</li>
    <li>El déficit de piridoxina, puede provocar alteraciones del metabolismo de aminoácidos, convulsiones, debilidad, insomnio y alteraciones de la función inmune, entre otras.</li>
    `,
    results: `El gen NBPF3 codifica para una hormona asociada con la eliminación de la vitamina B6 del organismo.`,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que tu concentración de vitamina B6 en sangre podría encontrarse dentro del rango normal, pero esto no significa que sea así.</li>
      <li>Es probable que el aporte diario de alimentos ricos en B6 sea suficiente para tu correcta síntesis de proteínas y metabolismo de la energía.</li>
      <li>Si sospechas que podés tener niveles de B6 fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Los alimentos con mayor contenido son: carnes (ave, pescado, cerdo), vísceras (hígado), cereales integrales y germen de trigo, frutas secas y semillas (girasol y avellanas), legumbres (garbanzos, lentejas) y vegetales (banana, espárragos, brócoli).</li>
      <li>Consumiendo alimentos de origen animal cubrimos nuestras necesidades diarias. Por ejemplo con: 100 gr de carne blanca (pollo o pescado) + 1 fruta chica por día.</li>
      <li>Consumiendo alimentos de origen vegetal cubrimos nuestras necesidades diarias. Por ejemplo con: 150 gr de verduras + 2 cucharadas soperas de legumbres + 1 fruta mediana.</li>
      <li>Esto es una predisposición que te acompañará toda la vida, pero los niveles de vitamina B6 también se modifican por el ambiente y el estilo de vida.</li>
      <li>Para más información podés consultar a un médico clínico o nutricionista.</li>
      `,
      1: `
      <li>Tus genes indican que podrías presentar concentración aumentada de B6 en sangre, pero esto no quiere decir que los tengas.</li>
      <li>Es probable que llevar una dieta variada sea suficiente para tu correcta síntesis de proteínas y metabolismo de la energía.</li>
      <li>Si sospechas que podés tener niveles de B6 fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero los niveles de vitamina B6 también se modifican por el ambiente y el estilo de vida.</li>
      <li>Los niveles elevados de Vitamina B 6 por causa alimentaria son muy infrecuentes, no excluyas alimentos que sean fuente de esta vitamina. Es más común que se presenten al incluir suplementos o por la ingesta de algunos medicamentos.</li>
      <li>Los alimentos con mayor contenido son: carnes (ave, pescado, cerdo), vísceras (hígado), cereales integrales y germen de trigo, frutas secas y semillas (girasol y avellanas), legumbres (garbanzos, lentejas) y vegetales (banana, espárragos, brócoli).</li>
      <li>Consumiendo alimentos de origen animal cubrimos nuestras necesidades diarias. Por ejemplo con: 100 gr de carne blanca (pollo o pescado) + 1 fruta chica por día.</li>
      <li>Consumiendo alimentos de origen vegetal cubrimos nuestras necesidades diarias. Por ejemplo con: 150 gr de verduras + 2 cucharadas soperas de legumbres + 1 fruta mediana.</li>
      `,
      2: `
      <li>Tus genes indican que tu concentración de B6 en sangre podría encontrarse disminuida, pero esto no significa que sea así.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero los niveles de vitamina B6 también se modifican por el ambiente y el estilo de vida.</li>
      <li>Es probable que necesites agregar a tu dieta porciones extra de alimentos ricos en B6 o algún tipo de suplemento para asegurar la correcta síntesis de proteínas y metabolismo de la energía. Consultá a tu nutricionista para ajustar tu plan alimentario a esta predisposición genética.</li>
      <li>Si sospechas que podés tener niveles de vitamina B6 fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Los alimentos con mayor contenido son: carnes (ave, pescado, cerdo), vísceras (hígado), cereales integrales y germen de trigo, frutas secas y semillas (girasol y avellanas), legumbres (garbanzos, lentejas) y vegetales (banana, espárragos, brócoli).</li>
      <li>Consumiendo alimentos de origen animal cubrimos nuestras necesidades diarias. Por ejemplo con: 100 gr de carne blanca (pollo o pescado) + 1 fruta chica por día.</li>
      <li>Consumiendo alimentos de origen vegetal cubrimos nuestras necesidades diarias. Por ejemplo con: 150 gr de verduras + 2 cucharadas soperas de legumbres + 1 fruta mediana.</li>
      <li>Preferí la cocción por vapor u horno de tus alimentos y aleja el alcohol de tus comidas, así conseguirás que esta vitamina esté disponible para ser absorbida en tu organismo.</li>
      <li>Algunos anticonceptivos orales (estrógenos) pueden interferir con el metabolismo de esta vitamina disminuyendo su concentración. Para más información podés consultar a un médico clínico o nutricionista.</li>
      <li>La salud de tu intestino es crucial para la absorción de vitaminas, mantenerlo sano comiendo alimentos reales.</li>
      `,
    },
    bibliography: `Tanaka, Toshiko, Paul Scheet, Betti Giusti, Stefania Bandinelli, Maria Grazia Piras, Gianluca Usala, Sandra Lai, et al. 2009. “Genome-Wide Association Study of Vitamin B6, Vitamin B12, Folate, and Homocysteine Blood Concentrations.” American Journal of Human Genetics 84 (4): 477–82.`
  },
  "Requerimiento de vitamina A": {
    label: "Vitamina A",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite adecuar el consumo de alimentos y suplementos de vitamina A acorde a tu metabolismo, para evitar los efectos de su deficiencia o exceso.
      Tus genes indican que podrías presentar niveles séricos de vitamina A <b>habituales</b>, tus genes no te determinan, tus hábitos si.`,
      1: `Conocer lo que dicen tus genes te permite adecuar el consumo de alimentos y suplementos de vitamina A acorde a tu metabolismo, para evitar los efectos de su deficiencia o exceso.
      Tus genes indican que podrías presentar niveles séricos de vitamina A <b>aumentados</b>, tus genes no te determinan, tus hábitos si.`,
      2: `Conocer lo que dicen tus genes te permite adecuar el consumo de alimentos y suplementos de vitamina A acorde a tu metabolismo, para evitar los efectos de su deficiencia o exceso.
      Tus genes indican que podrías presentar niveles séricos de vitamina A <b>disminuidos</b>, tus genes no te determinan, tus hábitos si.`,
    },
    generalInfo: `La vitamina A comprende un  grupo de compuestos químicos como por ejemplo  el retinol, el retinal y el betacaroteno. Intervienen en el crecimiento y el desarrollo del sistema nervioso y la visión.
    <br/><br/>
    La forma química más común de vitamina A que consumimos en los alimentos y también en los suplementos dietéticos es el betacaroteno. Los niveles de vitamina A en el organismo se relacionan con la capacidad de conversión de estos precursores ( b caroteno) en retinol (vitamina A activa).
    Esta conversión puede verse alterada según nuestra constitución genética, la mayoría de las personas consumen suficiente vitamina A y sus alteraciones son poco comunes.
    <br/><br/>
    <b></b><u>Signos y síntomas de deficiencia y exceso de vitamina A:</u></b>
    <li>Deficiencia: El más habitual es la xeroftalmia, que es la incapacidad de ver con poca luz.</li>
    <li>Exceso: Consumir demasiada vitamina A preformada (en general, en suplementos o ciertos medicamentos) puede provocar mareos, náuseas, dolores de cabeza, y alteraciones de la conciencia graves.</li>
    <li>Exceso en embarazadas: Las dosis elevadas de vitamina A preformada en mujeres embarazadas también pueden causar defectos congénitos en sus hijos ya que es una vitamina teratogénica.</li>
    `,
    results: `Los marcadores genéticos utilizados están relacionados con los genes que codifican para el procesamiento de estos metabolitos, RET y BC. Su biodisponibilidad influye, junto con otros factores, en la conversión a vitamina A activa (retinol) y su disponibilidad para ser utilizada por los diferentes tejidos. 
    El gen RBP4 codifica para  una proteína que transporta el retinol desde el hígado a los tejidos periféricos. El gen TTR codifica para una proteína que transporta retinol en dimero con RBP4 y tiroxina.
    `,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que tu concentración de precursores de vitamina A en sangre podrían encontrarse dentro del rango normal, pero esto no significa que sea así.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero los niveles de vitamina A también se modifican por el ambiente y el estilo de vida.</li>
      <li>Los alimentos con mayor contenido de retinol son: lácteos (leche, queso y yogur), hígado, yema de huevo y pescados de aguas profundas (atún, arenque, sardina, salmón).</li>
      <li>Si sospechas que podés tener niveles de vitamina A fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>La cantidad de cada vitamina que necesitás depende de la edad y el sexo. Otros factores, como el embarazo y la salud, también son importantes.</li>
      <li>Con alimentos de origen animal cubrimos nuestra necesidad diaria consumiendo 2 tazas de 250 ml de leche fresca y 1 huevo.</li>
      <li>Con alimentos de origen vegetal cubrimos nuestra necesidad diaria consumiendo, 2 frutas chicas / día y 50 gr de vegetales verdes.</li>
      <li>Los alimentos con mayor contenido de betacarotenos son: maíz, vegetales de hoja verde (acelga, lechuga, espinaca), frutas y verduras de color anaranjado (zapallo, calabaza, zanahoria, melón, durazno, damasco).</li>
      <li>El método de cocción más adecuado para conservar esta vitamina en los alimentos es el vapor. Cocciones prolongadas o hervor disminuyen su biodisponibilidad.</li>
      `,
      1: `
      <li>Tus genes indican que podrías presentar concentración aumentada de precursores de la vitamina A en sangre, pero esto no quiere decir que asi sea.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero los niveles de vitamina A también se modifican por el ambiente y el estilo de vida.</li>
      <li>Es probable que teniendo una alimentación variada y equilibrada obtengas de los alimentos toda la vitamina A y betacarotenos que tus ojos y sistema nervioso necesitan.</li>
      <li>Si sospechas que podés tener niveles de vitamina A fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Es infrecuente presentar concentraciones elevadas de vitamina A a causa de la dieta, su aumento se asocia al consumo de suplementos, no restrinjas alimentos fuente de esta vitamina.</li>
      <li>Si estás embarazada o planeas estarlo consulta a tu médico ya que altos niveles de esta vitamina tienen efecto teratogénico (pueden producir malformaciones congénitas).</li>
      <li>Los alimentos con mayor contenido de retinol son: lácteos (leche, queso y yogur), hígado, yema de huevo y pescados de aguas profundas (atún, arenque, sardina, salmón).</li>
      <li>Los alimentos con mayor contenido de betacarotenos son: maíz, vegetales de hoja verde (acelga, lechuga, espinaca), frutas y verduras de color anaranjado (zapallo, calabaza, zanahoria, melón, durazno, damasco).</li>
      `,
      2: `
      <li>Tus genes indican que tu concentración de precursores de vitamina A en sangre podrían encontrarse disminuida, pero esto no significa que sea así.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero los niveles de vitamina A también se modifican por el ambiente y el estilo de vida.</li>
      <li>Si sospechas que podés tener niveles de vitamina A fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Es probable que teniendo una alimentación variada y equilibrada obtengas de los alimentos toda la vitamina A y betacarotenos que tus ojos y sistema nervioso necesitan.</li>
      <li>Los alimentos con mayor contenido de retinol son: lácteos (leche, queso y yogur) hígado, yema de huevo y pescados de aguas profundas (atún, arenque, sardina, salmón).</li>
      <li>Los alimentos con mayor contenido de betacarotenos son: maíz, vegetales de hoja verde (acelga, lechuga, espinaca), frutas y verduras de color anaranjado (zapallo, calabaza, zanahoria, melón, durazno, damasco).</li>
      <li>Para la correcta absorción de las vitaminas es importante tu salud intestinal, consumí alimentos reales.</li>
      <li>Preferí cocinar tus alimentos a vapor y condimentarlos con 1 o 2 cucharadas soperas de aceite de oliva. La vitamina A para absorberse necesita la presencia de grasas en la misma comida.</li>
      `,
    },
    bibliography: `PatrickBorelandCharlesDesmarchelie.GeneticVariationsAssociatedwithVitaminAStatus andVitaminABioavailability`
  },
  "Requerimiento de vitamina D": {
    label: "Vitamina D",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite adecuar el consumo y suplementos de vitamina D acorde a tu metabolismo, para evitar los efectos de su deficiencia.

      Tus genes indican que tus niveles vitamina D en sangre podrían estar <b>habitual</b>, tus genes no te determinan , tus hábitos si.`,
      1: `Conocer lo que dicen tus genes te permite adecuar el consumo y suplementos de vitamina D acorde a tu metabolismo, para evitar los efectos de su deficiencia.

      Tus genes indican que tus niveles vitamina D en sangre podrían estar <b>aumentada</b>, tus genes no te determinan , tus hábitos si.`,
      2: `Conocer lo que dicen tus genes te permite adecuar el consumo y suplementos de vitamina D acorde a tu metabolismo, para evitar los efectos de su deficiencia.

      Tus genes indican que tus niveles vitamina D en sangre podrían estar <b>disminuida</b>, tus genes no te determinan , tus hábitos si.`,
    },
    generalInfo: `La vitamina D tiene múltiples funciones. La más importante de ellas es la regulación de los niveles de calcio y fosfato a nivel renal, promoviendo la absorción intestinal de los mismos a partir de los alimentos y la reabsorción de calcio a nivel renal para disminuir las pérdidas por la orina. 
    Además favorece la remodelación del hueso y su mineralización y participa en las defensas de nuestro cuerpo (sistema inmune). 
    Las variantes genéticas comunes que influyen en los niveles de vitamina D en sangre permiten identificar a las personas con riesgo de deficiencia de vitamina D, y sugerir cambios de hábitos que ayuden a tener niveles adecuados.
    <br/><br/>
    <b><u>Signos y síntomas de déficit de vitamina D:</u></b>
    <li>Su déficit se asocia con: pérdida de la densidad ósea (favoreciendo a la osteoporosis y fracturas), mayor susceptibilidad a infecciones, dolor muscular difuso y menor rendimiento deportivo.</li>
    `,
    results: `El gen GC,  es el responsable de la codificación de una proteína que recubre y protege a la vitamina D, facilitando su transporte por la sangre. Algunas variantes en este gen pueden disminuir la capacidad de transportar esta vitamina, disminuyendo los niveles de vitamina D disponibles en el organismo.

    El gen VDR, por su parte, codifica al receptor de vitamina D.
    `,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que la concentración de vitamina D en sangre podría encontrarse dentro del rango normal, pero esto no significa que sea así.</li>
      <li>Si sospechas que podés tener niveles de vitamina D fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Es probable que llevar una dieta variada y exponerte al sol en horarios seguros sea suficiente para obtener los niveles de vitamina D necesarios para tus células.</li>
      <li>Los alimentos con mayor contenido de vitamina D son las grasas lácteas (leche, yogur y queso), yema de huevo, hígado y algunos pescados grasos (arenque, congrio, salmón, atún).</li>
      <li>Mantene saludable tu microbiota intestinal ( mas alimentos reales menos procesados) la disbiosis intestinal juega un rol fundamental en la absorción de esta vitamina.</li>
      <li>La correcta absorción de la vitamina D  alimentaria depende de la cantidad de grasas que consumas diariamente ya que las necesita para absorberse. Condimentá tus acompañamientos con 1 o 2 cucharadas soperas de aceite de oliva.</li>
      <li>Se recomienda exponerse al sol en horario seguro para la síntesis cutánea de vitamina D: en verano 10 minutos 3 veces por semana, y en invierno 30 minutos 3 veces por semana.</li>
      `,
      1: `
      <li>Tus genes indican que podrías presentar concentración aumentada de vitamina D en sangre, pero esto no quiere decir que los tengas.</li>
      <li>En condiciones normales es poco probable presentar niveles altos de esta vitamina en sangre a causa de una dieta equilibrada. El aumento se asocia al consumo de suplementos.</li>
      <li>Si sospechas que podés tener niveles de vitamina D fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero los niveles de vitamina D también se modifican por el ambiente y el estilo de vida.</li>
      <li>Los alimentos con mayor contenido de vitamina D son las grasas  lácteas (leche, yogur y queso), yema de huevo, hígado y algunos pescados grasos (arenque, congrio, salmón, atún).</li>
      <li>Mantene saludable tu microbiota intestinal ( mas alimentos reales menos procesados) la disbiosis intestinal juega un rol fundamental en la absorción de esta vitamina.</li>
      <li>La correcta absorción de la vitamina D  alimentaria depende de la cantidad de grasas que consumas diariamente ya que las necesita para absorberse. Condimentá tus acompañamientos con 1 o 2 cucharadas soperas de aceite de oliva.</li>
      <li>Se recomienda exponerse al sol en horario seguro para la síntesis cutánea de vitamina D: en verano 10 minutos 3 veces por semana, y en invierno 30 minutos 3 veces por semana.</li>
      `,
      2: `
      <li>Tus genes indican que la concentración de vitamina D en sangre podría encontrarse disminuida, pero esto no significa que sea así.</li>
      <li>Aunque en general llevar una dieta variada y exponerte al sol en horarios seguros sea suficiente para obtener los niveles de vitamina D necesarios para tus células, es posible que requieras suplementos en algunas situaciones (siempre bajo prescripción médica).</li>
      <li>Si sospechas que podés tener niveles de vitamina D fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>El sobrepeso u obesidad favorece a que la concentración de vitamina D esté disminuida en sangre, ya que el tejido adiposo la retiene impidiendo que quede disponible para llevar a cabo sus funciones. Mantene un peso saludable y hace ejercicio diariamente.</li>
      <li>Los alimentos con mayor contenido de vitamina D son las grasas lácteas (leche, yogur y queso),si consumís leche de vaca preferí las opciones enteras. Yema de huevo, hígado y algunos pescados grasos (arenque, congrio, salmón, atún).</li>
      <li>Mantene saludable tu microbiota intestinal (más alimentos reales menos procesados) la disbiosis intestinal juega un rol fundamental en la absorción de esta vitamina.</li>
      <li>La correcta absorción de la vitamina D  alimentaria depende de la cantidad de grasas que consumas diariamente ya que las necesita para absorberse. Condimentá tus acompañamientos con 1 o 2 cucharadas soperas de aceite de oliva.</li>
      <li>Se recomienda exponerse al sol en horario seguro para la síntesis cutánea de vitamina D: en verano 10 minutos 3 veces por semana, y en invierno 30 minutos 3 veces por semana.</li>
      <li>Esto es una predisposición que te acompañará toda la vida, pero los niveles de vitamina D también se modifican por el ambiente y el estilo de vida.</li>
      `,
    },
    bibliography: `Wang, Thomas J., Feng Zhang, J. Brent Richards, Bryan Kestenbaum, Joyce B. van Meurs, Diane Berry, Douglas P. Kiel, et al. 2010. “Common Genetic Determinants of Vitamin D Insufficiency: A Genome-Wide Association Study.” The Lancet 376 (9736): 180–88.`
  },
  "Requerimiento de calcio": {
    label: "Calcio",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite adecuar el consumo y suplementos de calcio acorde a tu metabolismo, para evitar los efectos de su deficiencia.

      Tus genes indican que tus niveles de calcio en sangre podrían estar <b>normales</b>, tus genes no te determinan, tus hábitos si.`,
      1: `Conocer lo que dicen tus genes te permite adecuar el consumo y suplementos de calcio acorde a tu metabolismo, para evitar los efectos de su deficiencia.

      Tus genes indican que tus niveles de calcio en sangre podrían estar <b>aumentados</b>, tus genes no te determinan, tus hábitos si.`,
      2: `Conocer lo que dicen tus genes te permite adecuar el consumo y suplementos de calcio acorde a tu metabolismo, para evitar los efectos de su deficiencia.

      Tus genes indican que tus niveles de calcio en sangre podrían estar <b>disminuidos</b>, tus genes no te determinan, tus hábitos si.`,
    },
    generalInfo: `El calcio es un ion que actúa como mensajero intracelular y participa de múltiples procesos celulares, como la contracción muscular, el ritmo cardíaco, la coagulación de la sangre y la secreción de diferentes hormonas. 
    El 99% de nuestro calcio corporal está almacenado en los huesos y dientes, mientras que el 1% circula en sangre y se encuentra involucrado en la señalización intracelular.
    Los niveles de calcio en sangre están bajo un fuerte control genético. 
    La hormona paratiroidea, producida por la glándula paratiroides, es la responsable de mantener los niveles séricos de calcio en el rango adecuado.
    <br/><br/>
    Concentraciones de calcio más altas (hipercalcemia) o más bajas (hipocalcemia) de lo esperado pueden generar síntomas:
    <li>La hipercalcemia leve puede no tener síntomas o manifestarse con náuseas, poco apetito, vómitos y constipación. Niveles de hipercalcemia mayores, pueden presentar espasmos musculares, cambios de personalidad y confusión, somnolencia excesiva, coma e incluso la muerte.</li>
    <li>El signo más común de hipocalcemia es "irritabilidad neuromuscular, calambres musculares en las piernas o en los brazos. Niveles de calcio en sangre muy bajos pueden ocasionar desorientación, palpitaciones o alteraciones del ritmo cardíaco.</li>
    `,
    results: `El gen CASR codifica para el receptor sensor de calcio, encargado de medir los niveles del mineral en las células del organismo. Variantes que causan fallas en su funcionamiento pueden derivar tanto en un aumento como en una disminución de los niveles circulantes.`,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que tu concentración de calcio en sangre podría encontrarse dentro del rango normal, pero esto no significa que sea así.</li>
      <li>Esto es una predisposición, pero los niveles de calcio se modifican, según tipo de alimentación, edad, y momento de la vida.</li>
      <li>Es conveniente controlar los niveles en sangre directamente por técnicas de laboratorio que reflejan lo que sucede en el momento que se realiza el estudio o a través de una densitometría (gold standard)</li>
      <li>Es probable que llevar una dieta variada sea suficiente para que obtengas los niveles de calcio necesarios para tus células.</li>
      <li>Los alimentos de origen animal donde encontramos calcio son los lácteos (leche, queso, yogur), kéfir de leche, sardinas.</li>
      <li>Las fuentes veganas son:Almendras, tofu, tahini, Garbanzos, porotos, vegetales verdes de hoja oscura (aunque una parte no es absorbida por los oxalatos) brócolis,   semillas (sésamo), frutas secas (avellanas, almendras) y vegetales (achicoria, brócoli, radicheta). Tené en cuenta que en este caso, por tener fibra, el calcio absorbido es menor que las fuentes animales.</li>
      <li>El café (cafeína) y el té (teofilina) favorecen la eliminación de calcio por orina. Aleja las infusiones de los alimentos ricos en calcio (al menos 30-40 minutos).</li>
      <li>Nuestro cuerpo absorbe el calcio que necesita cuando los niveles de proteínas en la dieta son moderadas. Dietas hiperproteicas (altas en proteínas) pueden aumentar su eliminación por orina.</li>
      <li>Si sos vegetariano o vegano, intolerante a la lactosa o tenés enfermedad celíaca podrías no estar recibiendo todo el calcio que necesitas.</li>
      <li>Para más información podés consultar a un médico clínico o nutricionista.</li>
      `,
      1: `
      <li>Tus genes indican  que podrías presentar valores altos de calcio en sangre, pero esto no quiere decir que los tengas.</li>
      <li>Esto es una predisposición, pero los niveles de calcio se modifican, según tipo de alimentación, edad, y momento de la vida.</li>
      <li>Si sospechas que podés tener niveles de calcio fuera de lo normal es conveniente verificar los niveles en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Es probable que llevar una dieta variada sea suficiente para que obtengas los niveles de calcio necesarios para tus células. A pesar de esta predisposición, no dejes de consumir alimentos ricos en calcio.</li>
      <li>Los alimentos de origen animal donde encontramos calcio son los lácteos (leche, queso, yogur), kéfir de leche, sardinas.</li>
      <li>Las fuentes veganas son:Almendras, tofu, tahini, Garbanzos, porotos, vegetales verdes de hoja oscura (aunque una parte no es absorbida por los oxalatos) brócolis,   semillas (sésamo), frutas secas (avellanas, almendras) y vegetales (achicoria, brócoli, radicheta). Tené en cuenta que en este caso, por tener fibra, el calcio absorbido es menor que las fuentes animales.</li>
      <li>Un exceso de calcio podría estar involucrado en enfermedad coronaria, cálculos en la orina y cáncer de próstata.</li>
      <li>Para más información podés consultar a un médico clínico o nutricionista.</li>
      `,
      2: `
      <li>Tus genes indican que tus niveles de calcio en sangre podrían encontrarse disminuidos, pero esto no significa que sea así.</li>
      <li>Esto es una predisposición, pero los niveles de calcio se modifican, según tipo de alimentación, edad, y momento de la vida.</li>
      <li>Es conveniente controlar los niveles en sangre directamente por técnicas de laboratorio que reflejan lo que sucede en el momento que se realiza el estudio o a través de una densitometría (prueba diagnostica mas fiable)</li>
      <li>Los alimentos de origen animal donde encontramos calcio son los lácteos (leche, queso, yogur), kéfir de leche, sardinas.</li>
      <li>Las fuentes veganas son:Almendras, tofu, tahini, Garbanzos, porotos, vegetales verdes de hoja oscura (aunque una parte no es absorbida por los oxalatos) brócolis,   semillas (sésamo), frutas secas (avellanas, almendras) y vegetales (achicoria, brócoli, radicheta). Tené en cuenta que en este caso, por tener fibra, el calcio absorbido es menor que las fuentes animales.</li>
      <li>Si tus niveles estuviesen disminuidos, aleja alimentos ricos en fibra de las fuentes de calcio, como por ejemplo panes integrales o con semillas, cereales integrales, vegetales de hoja, frutas con cáscara.</li>
      <li>Nuestro cuerpo absorbe el calcio que necesita cuando los niveles de proteínas en la dieta son moderadas. Dietas hiperproteicas (altas en proteínas) pueden aumentar su eliminación por orina.</li>
      <li>El café (cafeína) y el te (teofilina), gaseosas, sedentarismo  favorecen la eliminación de calcio por orina. Aleja las infusiones de los alimentos ricos en calcio (al menos 30-40 minutos).</li>
      <li>La vitamina D es necesaria para la absorción del calcio, la obtenemos de la dieta ( pescados grasos) y a través de la exposición segura al sol.</li>
      <li>La actividad física ( ejercicios de fuerza) tiene un impacto positivo sobre la densidad ósea.</li>
      <li>Si sos vegetariano o vegano, intolerante a la lactosa o tenés enfermedad celíaca podrías no estar recibiendo todo el calcio que necesitas.</li>
      <li>La indemnidad de nuestro intestino juega un rol crucial en la absorción de vitaminas y minerales, incluidos el calcio. Mantener una microbiota sana consumiendo alimentos reales, prebióticos y probióticos.</li>
      <li>Para más información podés consultar a un nutricionista.</li>
      `,
    },
    bibliography: `Susanna C. Larsson, PhD; Stephen Burgess, PhD; Karl Michaëlsson, MD, PhD.Association of Genetic Variants Related to Serum Calcium Levels With Coronary Artery Disease and Myocardial Infarction`
  },
  "Sensibilidad a la histamina": {
    label: "Histamina",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite adecuar el consumo de alimentos a tus sensibilidades e intolerancias.
      Tus genes indican que tus niveles de histamina en sangre estaría en <b>concentraciones habituales</b>, lo que <b>disminuiría</b> el riesgo asociado al desarrollo de una intolerancia. Tus genes no te determinan, tus hábitos si.`,
      1: `Conocer lo que dicen tus genes te permite adecuar el consumo de alimentos a tus sensibilidades e intolerancias.
      Tus genes indican que tus niveles de histamina en sangre estarían <b>elevados</b>, lo que <b>aumentaría</b> el riesgo asociado al desarrollo de una intolerancia. Tus genes no te determinan, tus hábitos si.`,
      2: `Conocer lo que dicen tus genes te permite adecuar el consumo de alimentos a tus sensibilidades e intolerancias.
      Tus genes indican que tus niveles de histamina en sangre estarían <b>elevados</b>, lo que <b>aumentaría</b> el riesgo asociado al desarrollo de una intolerancia. Tus genes no te determinan, tus hábitos si.`,
    },
    generalInfo: `La histamina es una sustancia química perteneciente a la familia de las aminas biógenas que constituye una parte importante del sistema inmunológico y nervioso.
    También la ingerimos a través de los alimentos fermentados por ejemplo vino y cerveza (por acción de las enzimas descarboxilasas), pescados y mariscos, ahumados, quesos maduros.
    La histamina alimentaria normalmente se excreta por orina, se conocen dos rutas de excreción, la HNMT y la DAO, cuyos genes nos cuentan cómo metaboliza nuestro cuerpo ésta . 
    La intolerancia a la histamina (IH) se produce cuando el cuerpo presenta valores demasiado elevados de esta proteína, y puede causar síntomas conocidos como intolerancia a la histamina. Esto puede deberse tanto a una producción excesiva de histamina por parte del cuerpo (histamina endógena) como a una deficiencia en la descomposición de la histamina ingerida en tu dieta (histamina alimentaria). 
    <br/><br/>
    <b><u>Síntomas más comunes:</u></b>
    <li>Diarrea / constipación, dolor abdominal, colon irritable, reflujo, distensión abdominal, meteorismo.</li>
    <li>Sarpullido o urticaria, piel seca, edema.</li>
    <li>Alteraciones menstruales, síndrome premenstrual</li>
    `,
    results: `Existen dos genes con roles cruciales en el metabolismo de la histamina.
    <br/><br/>
    Histamina exógena: La DAO (diaminooxidasa) es la enzima producida por los intestinos que descompone la histamina de los alimentos. El gen AOC1 codifica la producción de la enzima DAO. Las variantes genéticas en AOC1 pueden aumentar o disminuir la producción de la enzima, lo que repercute directamente en los niveles de histamina presentes.
    <br/><br/>
    Histamina endógena: Otra enzima es la histamina n-metiltransferasa (HNMT), codificada por el gen HNMT. Su función es la de degradar la histamina intracelular. Las variantes genéticas en HMNT pueden aumentar o disminuir la producción de la enzima, lo que repercute directamente en los niveles de histamina presentes.
    `,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que tenés baja posibilidad de presentar intolerancia a la histamina, pero esto no significa que no puedas desarrollarla.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero la reacción de tu cuerpo a la histamina también se modifica por el ambiente y otros factores.</li>
      <li>Si tenés síntomas de los mencionados previamente, aunque sean inespecíficos y no se relacionen con la ingesta de alimentos ricos en histamina, consultá con tu médico para confirmar/descartar el diagnóstico.</li>
      `,
      1: `
      <li>Tus genes indican que tenés un riesgo levemente aumentado de presentar intolerancia a la histamina, pero esto no significa que no puedas desarrollarla.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero la reacción de tu cuerpo a la histamina también se modifica por el ambiente y otros factores.</li>
      <li>Si tenés síntomas de los mencionados previamente, aunque sean inespecíficos y no se relacionen con la ingesta de alimentos ricos en histamina, consultá con tu médico para confirmar/descartar el diagnóstico.</li>
      <li>Si padecieras IH, existen distintos criterios médicos de la concentración de histamina máxima tolerada y qué alimentos deberías evitar, puesto que la cantidad de histamina es muy variable de un alimento a otro por su grado de fermentación.</li>
      <li>A continuación te detallamos los alimentos que mayor cantidad poseen, son aquellos que se deterioran con facilidad como: carnes curadas (fiambres y achuras), pescados, fermentados como  vino, cerveza, champagne, kéfir, chucrut, pescados y mariscos en general,  ahumados, quesos maduros, enlatados, quesos añejos como: cheddar, gouda, suizo y parmesano.</li>
      <li>También está presente en alimentos de origen vegetal sobre todo en palta, espinaca, soja y berenjena.</li>
      <li>Otros alimentos desencadenan, en individuos susceptibles, aumento de la histamina endógena, ellos son: clara de huevo y proteína láctea, aditivos como glutamato, nitritos, sulfito y colorante amarillo E 102, E110, rojo E 124.</li>
      <li>Asegúrate de estar consumiendo diariamente vitamina C, uno de los múltiples beneficios que tiene esta vitamina es un potente  efecto antihistamínico.</li>
      <li>Las bebidas energizantes contienen serotonina que es metabolizada por la DAO, por lo que si presentas IH evita consumirlos para no sobresaturar tu sistema natural de excreción de histamina.</li>
      `,
      2: `
      <li>Tus genes indican que tenés un riesgo aumentado de presentar intolerancia a la histamina, pero esto no significa que no puedas desarrollarla.</li>
      <li>Esto es una predisposición que te acompañará toda la vida, pero la reacción de tu cuerpo a la histamina también se modifica por el ambiente y otros factores.</li>
      <li>Si tenés síntomas de los mencionados previamente, aunque sean inespecíficos y no se relacionen con la ingesta de alimentos ricos en histamina, consultá con tu médico para confirmar/descartar el diagnóstico.</li>
      <li>Si padecieras IH, existen distintos criterios médicos de la concentración de histamina máxima tolerada y que alimentos debieras evitar, puesto que la cantidad de histamina es muy variable de un alimento a otro por su grado de fermentación.</li>
      <li>A continuación te detallamos los alimentos que mayor cantidad poseen, son aquellos que se deterioran con facilidad como: carnes curadas (fiambres y achuras), pescados, fermentados como  vino, cerveza, champagne, kéfir, chucrut, pescados y mariscos en general, ahumados, quesos maduros, enlatados, quesos añejos como: cheddar, gouda, suizo y parmesano.</li>
      <li>También está presente en alimentos de origen vegetal sobre todo en palta, espinaca, soja y berenjena.</li>
      <li>Otros alimentos desencadenan, en individuos susceptibles, aumento de la histamina endógena, ellos son: clara de huevo y proteína láctea, aditivos como glutamato, nitritos, sulfito y colorante amarillo E 102, E110, rojo E 124.</li>
      <li>Asegúrate de estar consumiendo diariamente vitamina C, uno de los múltiples beneficios que tiene esta vitamina es un potente  efecto antihistamínico.</li>
      <li>Las bebidas energizantes contienen serotonina que es metabolizada por la DAO, por lo que si presentas IH evita consumirlos para no sobresaturar tu sistema natural de excreción de histamina</li>
      `,
    },
    bibliography: `L. Maintz1 *, C.-F. Yu1 *, E. Rodrı´guez2,3, H. Baurecht2,4, T. Bieber1 , T. Illig5 , S. Weidinger2,3,6 & N. Nova.Association of single nucleotide polymorphisms in the diamine oxidase gene with diamine oxidase serum activities 
    <br/>
    Jim Stevenson, Ph.D. Edmund Sonuga-Barke, Ph.D. Donna McCann, Ph.D. Kate Grimshaw, M.Sc. Karen M. Parker, M.S. Matthew J. Rose-Zerilli, B.Sc. John W. Holloway, Ph.D. John O. Warner, M.D.The Role of Histamine Degradation Gene Polymorphisms in Moderating the Effects of Food Additives on Children’s ADHD Symptoms
    <br/>
    Yuen Yi Hon1, William J. Jusko2, Hong-Hao Zhou3, Guo-Lin Chen3, Dong Guo3, Gan Zhou3, Vicky E. Spratlin1, and Michael W. Jann.Endogenous Histamine and Cortisol Levels in Subjects with Different Histamine N-Methyltransferase C314T Genotypes
    `
  },
  "Sensibilidad a la glucosa": {
    label: "Glucosa",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite adecuar la calidad de carbohidratos que consumís diariamente acorde a tu metabolismo para prevenir enfermedades asociadas.

      Tus genes indican que tu sensibilidad a la glucosa podría ser <b>habitual</b>, tus genes no te determinan, tus hábitos si.`,
      1: ``,
      2: `Conocer lo que dicen tus genes te permite adecuar la calidad de carbohidratos que consumís diariamente acorde a tu metabolismo para prevenir enfermedades asociadas.

      Tus genes indican que tu sensibilidad a la glucosa  podría estar <b>aumentada</b>, tus genes no te determinan, tus hábitos si.`,
    },
    generalInfo: `Los alimentos están constituidos por múltiples nutrientes, necesarios para el correcto funcionamiento de nuestro organismo y sus funciones vitales. Grasas, proteínas, carbohidratos, vitaminas, minerales y oligoelementos son algunas de las sustancias químicas que los forman.
    Los carbohidratos los incorporamos evolutivamente hace tan solo 10 mil años con el advenimiento de la agricultura, razón por la cual junto con las legumbres presentan tanta variabilidad en cuanto a su digestión de persona a persona.
    Los glúcidos son biomoléculas compuestas principalmente de carbono, hidrógeno y oxígeno, aunque algunos de ellos también contienen otros bioelementos tales como: nitrógeno, azufre y fósforo. Su principal fuente son los cereales.
    Según su estructura química podemos dividirlos en simples o complejos. 
    Los primeros son fácilmente digeridos y absorbidos mientras que los complejos tienen una digestión y absorción retardada en el tiempo debido a su mayor complejidad molecular.
    A diferencia de los complejos  (almidones, glucógeno y fibra)  los azúcares simples (glucosa, fructosa, galactosa, sacarosa y lactosa) al absorberse más fácilmente producen un aumento de la glucemia más rápido, esto determina que el páncreas secrete insulina a nuestro torrente sanguíneo para que las células de nuestros tejidos puedan usar o almacenar esa glucosa disponible.
    <br/><br/>
    En aquellas personas con predisposición genética  a la DBT2,  la calidad de carbohidratos de la dieta así como el estilo de vida sinergiza y predispone al desarrollo de esta patología. La mayor parte del consumo de hidratos en las personas proviene de las harinas refinadas y no de granos enteros. 
    En las poblaciones previas a la revolución industrial los granos y legumbres  o sus harinas se remojaban, fermentaban y germinaban previos a su consumo. Hoy estas prácticas no son nuestro cotidiano (por tiempo y costumbre) lo que impacta en una digestión a veces problemática-

    `,
    results: `El gen TCF7L2 codifica para el  factor de transcripción 7-like 2, también conocido como TCF7L2 o TCF4, proteína que actúa como factor de transcripción. En el páncreas, este factor activa la expresión génica de GLP1 estimulando la secreción de insulina por las células B. Hasta la fecha es uno de los  identificados como jugadores principales en la síntesis y secreción de la insulina. 
    La calidad de carbohidratos consumidos en la dieta modifican el riesgo de diabetes tipo 2 asociado a este gen.
    `,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican  que  tu sensibilidad a la glucosa es la habitual.</li>
      <li>Aunque tus genes no te predisponen, el estilo de vida que lleves, los alimentos que consumas, el mantenimiento de un peso saludable y la regularidad de tu actividad física juega un papel fundamental en el desarrollo de ciertas patologías como la  diabetes tipo 2.</li>
      <li>Mantené un peso saludable y acorde a tu contextura, talla y etapa de la vida. El sobrepeso y la obesidad son factores de riesgo muy importantes en el desarrollo de DBT tipo 2.</li>
      <li>Hacete controles médicos de rutina que incluyan niveles de glucosa en sangre.</li>
      <li>No elimines los cereales de tu dieta ya que nuestro cuerpo obtiene energía y nutrientes de ellos, selecciónalos de forma inteligente.</li>
      <li>Preferí a las harinas refinadas los derivados de cereales integrales (grano entero), legumbres, frutas y verduras (carbohidratos complejos).</li>
      <li>Sumá fibra en tus comidas  para absorber los azúcares más lentamente. La fibra está en las frutas con cáscaras, cereales integrales, semillas y verduras, sobre todo las de hoja (lechuga, escarola, radicheta, repollo).</li>
      <li>Suma proteínas en tus comidas. A mayor cantidad de proteínas menor digestión de glúcidos y por ende menor absorción.</li>
      <li>Deshabitúa tu consumo de azúcar de mesa, golosinas, productos de panadería y pastelería que contengan harinas refinadas (pizza, panes, empanadas, sándwiches, tartas, wraps. Si te es inevitable endulzar preferí edulcorantes naturales antes que el azúcar (hasta 8 hojas de stevia por día).</li>
      <li>Podes remplazar en algunas preparaciones las tradicionales harinas de cereales por harinas de legumbres para sumar nuevos nutrientes (lupino, soja, garbanzo, arveja) de frutos secos (almendra, nueces, castañas avellanas) de semillas (lino, chia, girasol, zapallo).</li>
      <li>Mantenete físicamente activo. Es suficiente con 30-40 min por día de actividad física.</li>
      <li>Consumí fruta fresca preferentemente agroecológica, entera y con cáscara (minimiza fruta deshidratada, desecada y jugos).</li>
      <li>Mantenete hidratado bebiendo de 2 a 3 litros de agua por día. Moderá el consumo de alcohol.</li>
      `,
      1: ``,
      2: `
      <li>Tus genes indican  que  tu sensibilidad a la glucosa es menor, por lo que una dieta rica en carbohidratos simples exacerba  el riesgo de desarrollo de DBT tipo 2.</li>
      <li>Esto es una predisposición que te acompañará siempre, pero el estilo de vida que lleves, el mantenimiento de un peso saludable y la regularidad de tu actividad física juega un papel fundamental en el desarrollo de ciertas patologías como diabetes tipo 2.</li>
      <li>Mantené un peso saludable y acorde a tu contextura, talla y etapa de la vida. El sobrepeso y la obesidad son factores de riesgo muy importantes en el desarrollo de DBT tipo 2.</li>
      <li>Hacete controles médicos de rutina que incluyan niveles de glucosa en sangre.</li>
      <li>Mantenete físicamente activo. Es suficiente con 30-40 min por día de actividad física.</li>
      <li>Los carbohidratos simples están relacionados con la diabetes (entre otras patologías), por producir un aumento rápido de la glucemia y consecuente liberación de insulina para luego caer abruptamente generando cansancio, hambre y especialmente mayor apetencia por alimentos dulces.</li>
      <li>No elimines los cereales de tu dieta ya que nuestro cuerpo obtiene energía y nutrientes de ellos, selecciónalos de forma inteligente.</li>
      <li>Preferí a las harinas refinadas los derivados de cereales integrales (grano entero), legumbres, frutas y verduras (carbohidratos complejos).</li>
      <li>Sumá fibra en tus comidas  para absorber los azúcares más lentamente. La fibra está en las frutas con cáscaras, cereales integrales, semillas y verduras, sobre todo las de hoja (lechuga, escarola, radicheta, repollo).</li>
      <li>Suma proteínas y grasas saludables en tus comidas. A mayor cantidad de proteínas y grasas menor digestión de glúcidos y por ende menor absorción. Ejemplo: Legumbres y palta.</li>
      <li>Deshabitúa tu consumo de azúcar de mesa, golosinas, productos de panadería y pastelería que contengan harinas refinadas (pizza, panes, empanadas, sándwiches, tartas, wraps). Si te es inevitable endulzar preferí edulcorantes naturales antes que el azúcar/ artificiales (hasta 8 hojas de stevia por día).</li>
      <li>Reemplazá las tradicionales harinas de cereales por harinas de legumbres (lupino, soja, garbanzo, arveja) de frutos secos (almendra, nueces, castañas avellanas) de semillas (lino, chía, girasol, zapallo).</li>
      <li>Consumí fruta fresca preferentemente agroecológica, entera y con cáscara (minimiza fruta deshidratada, desecada y jugos).</li>
      <li>El mejor método de cocción es el vapor o al “dente”, a mayor calor e hidratación del almidón mayor índice glucémico.</li>
      <li>Mantenete hidratado bebiendo de 2 a 3 litros de agua por día. Moderá el consumo de alcohol.</li>
      `,
    },
    bibliography: `Marilyn  C Cornelis ,Lu Qi,Peter Kraft,and Frank B Hu.TCF7L2,dietary carbohydrate ,and risk of type 2 diabetes in US Women.`
  },
  "Requerimiento de grasas Omega 3": {
    label: "Omega 3",
    zone: "diagnosticResults",
    desc: {
      0: `Conocer lo que dicen tus genes te permite adecuar el consumo de ácidos grasos omega 3 acorde a tu metabolismo, para evitar los efectos de su deficiencia 

      Tus genes indican que tendrías riesgo <b>habitual</b> de presentar altos niveles de triglicéridos en sangre por consumo deficiente de Omega 3, tus genes no te determinan, tus hábitos si.`,
      1: ``,
      2: `Conocer lo que dicen tus genes te permite adecuar el consumo de ácidos grasos omega 3 acorde a tu metabolismo, para evitar los efectos de su deficiencia 

      Tus genes indican que tendrías riesgo <b>aumentado</b> de presentar altos niveles de triglicéridos en sangre por consumo deficiente de Omega 3, tus genes no te determinan, tus hábitos si.`,
    },
    generalInfo: `Los ácidos grasos omega 3 comprende el ácido eicosapentaenoico (EPA) y el ácido docosahexaenoico (DHA) como fuentes de origen animal y linolénico (ALA) de fuentes vegetales. Estos ácidos no pueden ser sintetizados por nuestro organismo siendo la alimentación su principal fuente.
    Los ácidos grasos omega 3 mejoran nuestros lípidos en sangre (disminuyen el colesterol y aumentan las HDl o colesterol bueno), son conocidos por su efecto benéfico en la disminución del riesgo de enfermedades cardiovasculares a través de la disminución de los triglicéridos sanguíneos y disminución de la formación de placas de ateroma.
    Existen variantes genéticas asociadas al mayor riesgo de enfermedades cardiovasculares por un consumo insuficiente de este nutriente,  ya que en nuestro organismo el omega-3 produciría un aumento de la formación de óxido nítrico, un potente vasodilatador y antiagregante plaquetario.
    La American Heart Association (Asociación Americana del Corazón) recomienda comer al menos dos raciones semanales de pescado rico en omega-3 (lo que equivale a un mínimo de 200 gr de pescado)
    <br/><br/>
    Beneficios del omega 3:
    <li>Mejora los factores de riesgo asociados a enfermedad cardiovascular como: disminución de triglicéridos, reducción de presión arterial, aumento de colesterol HDL “bueno”, anticoagulante y antiplaquetario.</li>
    <li>Mejora la resistencia a la insulina.</li>
    <li>Reduce la inflamación de bajo grado.</li>
    `,
    results: `El gen NOS3 codifica la enzima óxido nítrico sintasa 3, que metaboliza la L-arginina en óxido nítrico. La función del óxido nítrico es aumentar el diámetro de los vasos sanguíneos (vasodilatación) e inhibir la agregación plaquetaria (formación de trombos). El consumo insuficiente de ácidos omega-3 promueve menor formación de óxido nítrico y aumento de los triglicéridos séricos incrementando el riesgo de enfermedades cardiovasculares.`,
    understandingResults: "",
    snps: [],
    recommendations: {
      0: `
      <li>Tus genes indican que tu riesgo a presentar altos niveles de triglicéridos séricos con una dieta baja en omega 3 es el habitual.</li>
      <li>Esta es una predisposición que te acompañará siempre, conocer esta información te permite elegir diariamente este nutriente para obtener sus beneficios protectores frente a las enfermedades cardiovasculares a pesar que tus genes no te predisponen a un riesgo aumentado.</li>
      <li>Los ácidos grasos omega 3 los encontramos en fuentes animales y vegetales.</li>
      <li>Los pescados de aguas profundas como salmón, caballa, atún blanco, trucha y sardina son los que más Omega (DHA y ARA) poseen. La carne de animales de pastoreo que declaren ser altas en omega 3 también pueden ser una fuente.</li>
      <li>Semillas de lino, aceite de linaza, semillas de chía , aceite de canola y aceite de soja, poroto de  soja y tofu, kale,  son las fuentes de omega 3 vegetales (ALA).</li>
      <li>La ingesta mínima recomendada es de 500-1 gr /día, pudiendo ser mayor en alguna patología inflamatoria. En vegetarianos y veganos se recomienda un mínimo de 2 gr/día. La mejor forma de absorción es con una comida alta en grasa.</li>
      <li>En caso de no cubrir con la recomendación con fuentes alimentarias contamos en el mercado con suplementos: aceite de hígado de bacalao, suplemento de aceite de pescado, suplemento de aceite de krill, suplemento de aceite de algas.</li>
      <li>Aceite de hígado de bacalao: es fuente de EPA y DHA. Contienen además vitamina A y D, por lo que debe suministrarse por un profesional para no exceder el consumo de estas vitaminas.</li>
      <li>Suplemento a base de aceite de pescado: Seleccionar marcas que tengan entre DHA y EPA alrededor de 500-1 gr.</li>
      <li>Suplementos de Krill: Es el suplemento de mayor biodisponibilidad , sobre todo de EPA. El krill es un crustáceo que contiene un aceite excepcionalmente rico en omega 3. Como se encuentra al inicio de la cadena trófica alimentándose de plancton el riesgo de ingesta de metales pesados y pesticidas es menor.</li>
      <li>Consulta a tu médico qué suplemento de omega 3 te sugiere, siempre prestando atención  a la cantidad de omega que aporta, trazabilidad y toxinas que puede contener. Chequea la fecha de vencimiento, siempre tiene que estar alejada del momento de consumo para que la grasa no se oxide.</li>
      `,
      1: ``,
      2: `
      <li>Tus genes indican que tu riesgo a presentar altos niveles de triglicéridos séricos con una dieta baja en omega 3 es mayor que al habitual.</li>
      <li>Esta es una predisposición que te acompañará siempre, conocer esta información te permite elegir diariamente este nutriente para obtener sus beneficios protectores frente a las enfermedades cardiovasculares.</li>
      <li>Los ácidos grasos omega 3 los encontramos en fuentes animales y vegetales.</li>
      <li>Los pescados de aguas profundas como salmón, caballa, atún blanco, trucha y sardina son los que más Omega (DHA y ARA) poseen. La carne de animales de pastoreo que declaren ser altas en omega 3 también pueden ser una muy buena fuente.</li>
      <li>Semillas de lino, aceite de linaza, semillas de chía , aceite de canola y aceite de soja, poroto de  soja y tofu, kale,  son las fuentes de omega 3 vegetales (ALA).</li>
      <li>La ingesta mínima recomendada es de 500-1 gr /día, pudiendo ser mayor en alguna patología inflamatoria. En vegetarianos y veganos se recomienda un mínimo de 2 gr/día. La mejor forma de absorción es con una comida alta en grasa.</li>
      <li>En caso de no cubrir con la recomendación con fuentes alimentarias contamos en el mercado con suplementos: aceite de hígado de bacalao, suplemento de aceite de pescado, suplemento de aceite de krill, suplemento de aceite de algas.</li>
      <li>Aceite de hígado de bacalao: es fuente de EPA y DHA. Contienen además vitamina A y D, por lo que debe suministrarse por un profesional para no exceder el consumo de estas vitaminas.</li>
      <li>Suplemento a base de aceite de pescado: Seleccionar marcas que tengan entre DHA y EPA alrededor de 500-1gr.</li>
      <li>Suplementos de Krill: Es el suplemento de mayor biodisponibilidad, sobre todo de EPA. El krill es un crustáceo que contiene un aceite excepcionalmente rico en omega 3. Como se encuentra al inicio de la cadena trófica alimentándose de plancton el riesgo de ingesta de metales pesados y pesticidas es menor.</li>
      <li>Consulta a tu médico qué suplemento de omega 3 te sugiere, siempre prestando atención  a la cantidad de omega que aporta, trazabilidad y toxinas que puede contener. Chequea la fecha de vencimiento, siempre tiene que estar alejada del momento de consumo para que la grasa no se oxide.</li>
      `,
    },
    bibliography: `Ju-ShengZheng.ReplicationofaGene-DietInteractionatCD36,NOS3 and PPARGinResponsetoOmega-3FattyAcidSupplementsonBloodLipids: ADouble-BlindRandomizedControlledTrial`
  },
  "Niveles séricos de colesterol LDL": {
    label: "LDL",
    zone: "diagnosticResults",
    desc: {
      0: `Los niveles de colesterol LDL en sangre dependen  de múltiples factores genéticos y ambientales.
      Conocer lo que dicen tus genes te puede orientar en el cambio de hábitos alimentarios, y en el tratamiento farmacológico si fuera necesario, para disminuir eficazmente el riesgo de padecer alguna de estas enfermedades.
      
      Tus genes indican que podrías presentar valores en sangre de colesterol LDL <b>habituales o bajos</b>, tus genes no te determinan, tus hábitos si.
      `,
      1: "",
      2: `Los niveles de colesterol LDL en sangre dependen de múltiples factores genéticos y ambientales.
      Conocer lo que dicen tus genes te puede orientar en el cambio de hábitos alimentarios, y en el tratamiento farmacológico si fuera necesario, para disminuir eficazmente el riesgo de padecer alguna de estas enfermedades.
      
      Tus genes indican que podrías presentar valores en sangre de colesterol LDL <b>altos</b>, tus genes no te determinan, tus hábitos si.
      `,
    },
    generalInfo: `Además de las recomendaciones de hábitos alimentarios saludables y actividad física diaria es importante conocer lo que dicen tus genes de  tu colesterol, ya que su alteración se asocia a la formación de placas de ateroma que bloquean la luz de los vasos sanguíneos aumentando el riesgo de infarto de miocardio y enfermedades cardiovasculares.
    Varios factores aumentan el riesgo de presentar colesterol alto, las más importantes son:
    La herencia, la edad y el sexo (el colesterol empieza a elevarse hacia los 20 años y continúa subiendo hasta los 60 o 65 años, es mayor en los hombres antes de los 50, y en las mujeres a partir de la menopausia), el sobrepeso tiende a aumentar el colesterol LDL (“malo”), aumenta los triglicéridos y baja el colesterol HDL (“bueno”), aumentando el riesgo cardiovascular.
    <br/><br/>
    Respecto a la dieta desde hace ya tiempo conocemos que las grasas saturadas si bien elevan el colesterol LDL no se consideran actores esenciales ya que las partículas de LDL que elevan son pequeñas y densas (un subtipo de LDL) en lugar de las partículas grandes.
    Signos y síntomas:
    <li>La mayoría de las personas con alteraciones en los valores de colesterol LDL no tienen signos ni síntomas, por eso es importante controlarlo en los análisis de sangre  de los controles médicos periódicos.</li>
    `,
    results: `El gen FADS1 codifica para la enzima hepática “desaturasa de ácidos grasos”. Esta enzima está asociada con la conversión de ácidos grasos de cadena corta en formas activas de mayor longitud, y con la conversion de los ácidos grasos  omega 3 y 6 en formas activas como EPA , DHA y AA. Estos ultimos ayudan a controlar los niveles séricos de LDL entre otras funciones.
    `,
    understandingResults: ``,
    snps: [],
    recommendations: {
      0: `  

      <li>Tus genes indican que tu riesgo de presentar altos niveles de colesterol LDL con una dieta rica en grasas saturadas es bajo, pero esto no quiere decir que no puedas desarrollarlos si tu estilo de vida no es saludable y basado en alimentos reales.</li>
      <li>Es recomendable que verifiques tus niveles de colesterol en sangre directamente por técnicas de laboratorio en tus chequeos médicos</li>
      <li>Mantené un peso saludable acorde a tu talla, contextura y momento de la vida.</li>
      <li>Ejercítate a diario al menos 30-40 minutos, mantené una alimentación variada, equilibrada, come de forma ordenada y en porciones suficientes.</li>
      <li>Para prevenir el aumento de peso y disminuir el riesgo de enfermedad cardiovascular, modera el consumo de grasas totales, azúcares y harinas refinadas de tu dieta, dale prioridad a las grasas poliinsaturadas (“grasas buenas”), especialmente omega-3 de aceite de oliva, aceitunas, semillas de chía y lino, nueces pecanas, aceite de lino, y nuez y pescados.</li>
      <li>Los alimentos que más colesterol tienen son las  vísceras, fiambres, manteca, quesos duros.</li>
      <li>Incorporá semanalmente a tu mesa más veces  el  pescado (merluza, abadejo, atún)  y el   pollo. Elegí cortes magros de carne vacuna (lomo, peceto, nalga).</li>
      <li>En lo cotidiano preferí lácteos descremados (leche, queso y yogur), reservando los quesos duros (Mar del plata, fontina) para situaciones especiales.</li>
      <li>Consumí diariamente variedad de frutas y verduras , cereales integrales, legumbres y reservá fiambres, achuras y grasas para consumo ocasional.</li>
      <li>Si tenés colesterol alto a pesar de este resultado, es importante prevenir que empeore o que cause complicaciones. Controlate con tu médico periódicamente y seguí tu plan de tratamiento incluyendo hábitos de vida saludables y, si está indicado, medicamentos.</li>
      `,
      1: ``,
      2: `

      <li>Tus genes indican que tu riesgo a presentar mayores niveles de colesterol LDL con una dieta rica en grasas saturadas es alto, pero  esto no lo determina.</li>
      <li>Recordá que la mayoría de las veces el colesterol alto no da síntomas, por lo que es conveniente verificar los niveles de colesterol en sangre directamente por técnicas de laboratorio, que reflejan lo que sucede en el momento que se realiza el estudio.</li>
      <li>Mantené un peso saludable acorde a tu talla, contextura y momento de la vida. Ejercítate a diario al menos 30-40 minutos, mantené una alimentación variada, equilibrada, come de forma ordenada y en porciones suficientes.</li>
      <li>Para prevenir el aumento de peso y disminuir el riesgo de enfermedad cardiovascular, modera el consumo de grasas totales , azúcares y harinas refinadas de tu dieta, dale prioridad a las grasas poliinsaturadas (“grasas buenas”), especialmente omega-3 de aceite de oliva, aceitunas, semillas de chía y lino, nueces pecanas, aceite de lino, y nuez y pescados.</li>
      <li>Los alimentos que más colesterol tienen son las  vísceras, fiambres, manteca, quesos duros. La yema de huevo no eleva el colesterol!</li>
      <li>Consumí diariamente frutas y verduras (preferentemente rojizas: uva, fresa, arándano, berenjena, remolacha), legumbres (soja, garbanzos) y té verde. Estos alimentos contienen polifenoles que limitan el daño de las LDL a los vasos sanguíneos.</li>
      <li>Utiliza quesos reducidos en grasa (cottage, blanco y blandos descremados). Evita quesos de rallar, tipo mar de plata o fontina. Las vísceras (achuras), fiambres y manteca reservalas para un consumo esporádico o en fechas especiales.</li>
      <li>Incorporá semanalmente a tu mesa más veces  el  pescado (merluza, abadejo, atún)  y el   pollo  . Elegí cortes magros de carne vacuna (lomo, peceto, nalga).</li>
      `,
    },
    bibliography: `S. Hellstrand , E. Sonestedt ,U. Ericson , B. Gullberg ,  E. Wirfält , B. Hedblad ,  and M. Orho-Melander .Intake levels of dietary long-chain PUFAs modify the association between genetic variation in FADS and LDL-C
    `
  },
  Calcio: {
    label: "Calcio",
    zone: "diagnosticResults",
    desc: {
      0: "Bajo riesgo de presentar niveles altos de calcio.",
      1: "Riesgo moderado de presentar niveles altos de calcio. Modere el consumo de alimentos con alto contenido en calcio",
      2: "Riesgo elevado de presentar niveles altos de calcio. Evite el consumo excesivo de alimentos con alto contenido en calcio.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
  Hierro: {
    label: "Hierro",
    zone: "diagnosticResults",
    desc: {
      0: "Bajo riesgo de presentar niveles bajos de hierro. Buen aprovechamiento de alimentos ricos en hierro.",
      1: "Riesgo moderado de presentar niveles bajos de hierro. Favorecer el consumo de alimentos ricos en hierro.",
      2: "Alto riesgo de presentar niveles bajos de hierro. Favorecer el consumo de alimentos ricos en hierro o complementar con un plan de suplementación.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
  Sodio: {
    label: "Sodio",
    zone: "diagnosticResults",
    desc: {
      0: "Bajo riesgo de presentar niveles altos de sodio.",
      1: "Riesgo moderado de presentar niveles niveles altos de sodio. Evite el consumo de alimentos altos en sodio.",
      2: "Riesgo elevado de presentar niveles altos de sodio. Evite el consumo de alimentos altos en sodio y prefiera alimentos bajos en sodio.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
  VitA: {
    label: "Vitamina A",
    zone: "diagnosticResults",
    desc: {
      0: "Menor riesgo de presentar niveles bajos de vitamina A. Adecuado metabolismo de la vitamina A proveniente de la alimentación.",
      1: "Riesgo moderado de presentar niveles bajos de vitamina A. Favorecer el consumo de alimentos ricos en vitamina A o complementar con un plan de suplementación.",
      2: "Riesgo elevado de presentar niveles bajos de vitamina A. Favorecer el consumo de alimentos ricos en vitamina A o complementar con un plan de suplementación.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
  VitB: {
    label: "Vitamina B9 (Ácido Fólico)",
    zone: "diagnosticResults",
    desc: {
      0: "Bajo riesgo de presentar niveles bajos de vitamina B.",
      1: "Riesgo moderado de presentar niveles bajos de vitamina B. Prefiera los alimentos considerados buena fuente de vitamina B o complementar con un plan de suplementación.",
      2: "Riesgo elevado de presentar niveles bajos de vitamina B. Prefiera los alimentos considerados buena fuente de vitamina B o complementar con un plan de suplementación.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
  VitC: {
    label: "Vitamina C",
    zone: "diagnosticResults",
    desc: {
      0: "Bajo riesgo de presentar niveles bajos de vitamina C.",
      1: "Riesgo moderado de presentar niveles bajos de vitamina C. Favorecer el consumo de alimentos ricos en vitamina C o complementar con un plan de suplementación.",
      2: "Riesgo elevado de presentar niveles bajos de vitamina C. Prefiera los alimentos considerados buena fuente de vitamina C o complementar con un plan de suplementación.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
  VitD: {
    label: "Vitamina D",
    zone: "diagnosticResults",
    desc: {
      0: "Bajo riesgo de presentar bajos niveles de vitamina D.",
      1: "Riesgo moderado de presentar bajos niveles de vitamina D. Prefiera el consumo de alimentos considerados buena fuente de vitamina D o complementar con un plan de suplementación.",
      2: "Riesgo elevado de presentar bajos niveles de vitamina D. Prefiera el consumo de alimentos considerados buena fuente de vitamina D o complementar con un plan de suplementación.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
  Lactosa: {
    label: "Intolerancia a la Lactosa",
    zone: "intolerancesMain",
    desc: {
      0: "Bajo riesgo de presentar intolerancia a la lactosa.",
      1: "Riesgo moderado de intolerancia a la lactosa. Consuma con moderación alimentos que contengan lactosa.",
      2: "Riesgo elevado de intolerancia a la lactosa. Evite el consumo de alimentos que contengan lactosa. La mala absorción de la lactosa produce inflamación intestinal y diversos síntomas digestivos.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
  Gluten: {
    label: "Intolerancia al Gluten (Celiaquía)",
    zone: "intolerancesMain",
    desc: {
      0: "Bajo riesgo de presentar intolerancia al gluten. Libre consumo de alimentos ricos en gluten.",
      1: "Riesgo moderado de intolerancia al gluten. Consuma con moderación los alimentos que contengan gluten.",
      2: "Riesgo elevado de intolerancia al gluten. Evite el consumo de alimentos que contengan gluten.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
  Lipidos: {
    label: "Metabolismo de Grasas",
    zone: "intolerancesMain",
    desc: {
      0: "Bajo riesgo de ganancia de peso por el consumo de grasas. Sin embargo, recuerde que una dieta equilibrada debe contener no más del 30% de grasa total de la cual menos del 10% debe ser grasa saturada, entre 15-20% de grasas monoinsaturadas y entre 6-11% de grasas poliinsaturadas. Bajo riesgo de presentar niveles bajos de ácidos grasos Omega-3 y 6.",
      1: "Riego moderado de ganancia de peso por el consumo de grasas. Modere el consumo de alimentos con alto o moderado aporte de grasas y prefiera el consumo de alimentos con muy bajo, bajo o moderado aporte de grasa. Recuerde que una dieta equilibrada debe contener no más del 30% de grasa total de la cual menos del 10% debe ser grasa saturada, entre 15-20% de grasas monoinsaturadas y entre 6-11% de grasas poliinsaturadas. Riesgo moderado de presentar niveles bajos de ácidos grasos Omega-3 y 6. Niveles bajos de los ácidos grasos esenciales Omega-3 y 6 está asociado a sobrepeso, obesidad y enfermedades inflamatorias, se recomienda el consumo de alimentos ricos en ácidos grasos Omega-3 y 6",
      2: "Riesgo elevado de ganancia de peso por el consumo de grasas. Evite el consumo de alimentos con alto o moderado aporte en grasas y prefiera aquellos bajos o muy bajos en grasa. Recuerde que una dieta equilibrada debe contener no más del 30% de grasa total de la cual menos del 10% debe ser grasa saturada, entre 15-20% de grasas monoinsaturadas y entre 6-11% de grasas poliinsaturadas. Riesgo elevado de presentar niveles bajos de ácidos grasos Omega-3 y 6. Niveles bajos de los ácidos grasos esenciales Omega-3 y 6 está asociado a sobrepeso obesidad y enfermedades inflamatorias, se recomienda el consumo de alimentos ricos en ácidos grasos Omega-3 y 6.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    bibliography: ""
  },
  HC: {
    label: "Metabolismo de Carbohidratos",
    zone: "intolerancesMain",
    desc: {
      0: "Bajo riesgo de ganancia de peso por el consumo de carbohidratos. Sin embargo, recuerde que una alimentación correcta se recomienda no exceder los 25g de azúcar y consumir al menos de 25 a 30g de fibra.",
      1: "Riesgo moderado de ganancia de peso por el consumo de carbohidratos. Modere su consumo de alimentos con alto aporte de carbohidratos y el consumo de alimentos con muy bajo, bajo o moderado aporte de carbohidratos. Recuerde que una alimentación correcta se recomienda no exceder los 25g de azúcar y consumir al menos de 25 a 30g de fibra.",
      2: "Riesgo elevado de ganancia de peso por el consumo de carbohidratos. Evite el consumo de alimentos con alto aporte de carbohidratos y el consumo de alimentos con muy bajo, bajo o moderado aporte de carbohidratos. Recuerde que una alimentación correcta se recomienda no exceder los 25g de azúcar y consumir al menos de 25 a 30g de fibra.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
  Etanol: {
    label: "Asimilación de Alcohol",
    zone: "intolerancesOther",
    desc: {
      0: "Bajo riesgo de experimentar los efectos negativos del alcohol (náuseas, mareos, enrojecimiento facial, etc), lo que implica un mayor riesgo de dependencia al mismo. Las bebidas alcohólicas son fuente de calorías vacías, es decir, carecen de vitaminas y minerales. Aportan energía que se verá contrarrestada por el efecto represor que tienen sobre el sistema nervioso central, reduciendo la capacidad de hablar, concentrarse y caminar. Factores psicosociales, ambientales, biológicos y genéticos desempeñan un importante papel en la dependencia al alcohol, el abuso en el consumo del alcohol puede generar diversas lesiones en el organismo como son: gastritis, daño hepático e hipertensión arterial entre otras.",
      1: "Riesgo moderado de experimentar los efectos negativos del alcohol (náuseas, mareos, enrojecimiento facial, etc). Las bebidas alcohólicas son fuente de calorías vacías, es decir, carecen de vitaminas y minerales. Aportan energía que se verá contrarrestada por el efecto represor que tienen sobre el sistema nervioso central, reduciendo la capacidad de hablar, concentrarse y caminar.",
      2: "Mayor riesgo de experimentar los efectos negativos del alcohol (náuseas, mareos, enrojecimiento facial, etc). Las bebidas alcohólicas son fuente de calorías vacías, es decir, carecen de vitaminas y minerales. Aportan energía que se verá contrarrestada por el efecto represor que tienen sobre el sistema nervioso central, reduciendo la capacidad de hablar, concentrarse y caminar.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
  Cafeina: {
    label: "Asimilación de Cafeína",
    zone: "intolerancesOther",
    desc: {
      0: "Bajo riesgo de que los efectos estimulantes resulten más duraderos. Metabolizador rápido de cafeína. Debe tenerse en cuenta que, además de la genética, la capacidad de metabolización de la cafeína depende también de factores como la cantidad de café ingerida, el tabaquismo, los tratamientos anticonceptivos hormonales, entre otros.",
      1: "Riesgo moderado de que los efectos estimulantes resulten más duraderos, incrementando el riesgo de hipertensión con su consumo y, por tanto, el riesgo cardiovascular asociado. Metabolización lenta de la cafeína. Debe tenerse en cuenta que, además de la genética, la capacidad de metabolización de la cafeína depende también de factores como la cantidad de café ingerida, el tabaquismo, los tratamientos anticonceptivos hormonales, entre otros.",
      2: "Mayor riesgo de que los efectos estimulantes resulten más duraderos, incrementando el riesgo de hipertensión con su consumo y, por tanto, el riesgo cardiovascular asociado. Metabolización lenta de la cafeína. Debe tenerse en cuenta que, además de la genética, la capacidad de metabolización de la cafeína depende también de factores como la cantidad de café ingerida, el tabaquismo, los tratamientos anticonceptivos hormonales, entre otros.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    bibliography: ""
  },
  Apetito: {
    label: "Apetito y Saciedad",
    zone: "intolerancesOther",
    desc: {
      0: "Bajo riesgo de presentar un mayor apetito. Bajo riesgo de presentar una incorrecta inhibición/control de la sensación de saciedad.",
      1: "Riesgo moderado de presentar un mayor apetito. Riesgo moderado de presentar una incorrecta inhibición/control de la sensación de saciedad.",
      2: "Alto riesgo de presentar una mayor sensación de apetito. Riesgo alto de presentar una incorrecta inhibición/control de la sensación de saciedad.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
  Ejercicio: {
    label: "Respuesta a Ejercicio Físico",
    zone: "intolerancesOther",
    desc: {
      0: "Mayor beneficio en la reducción de grasa corporal en respuesta a la práctica de ejercicio físico regular.",
      1: "Reducción adecuada de la grasa corporal como respuesta a la práctica de ejercicio físico regular.",
      2: "Riesgo elevado de resistencia a la pérdida de peso mediante la práctica de ejercicio físico, debido a una menor capacidad para usar los depósitos de grasa. El resultado no indica de manera directa que el ejercicio no resultará efectivo para la pérdida de peso, sino que aquellos pacientes con el genotipo de baja probabilidad requerirán rutinas de ejercicio especializadas.",
    },
    generalInfo: "",
    results: "",
    understandingResults: "",
    snps: [],
    recommendations: {
      0: ``,
      1: ``,
      2: ``,
    },
    bibliography: ""
  },
};

export function mapDiagnostics(analysisResult) {
  //console.log(analysisResult);
  const res = {
    diagnosticResults: [],
    intolerancesMain: [],
    intolerancesOther: [],
  };

  analysisResult
    .map((r) => ({
      label: KeyToLabelDict[r.nutrient].label,
      item: r.nutrient,
      value: r.score,
      zone: KeyToLabelDict[r.nutrient].zone,
      desc: KeyToLabelDict[r.nutrient].desc,
      generalInfo: KeyToLabelDict[r.nutrient].generalInfo,
      results: KeyToLabelDict[r.nutrient].results,
      understandingResults: KeyToLabelDict[r.nutrient].understandingResults,
      snps: r.snps,
      recommendations: KeyToLabelDict[r.nutrient].recommendations,
      bibliography: KeyToLabelDict[r.nutrient].bibliography,
    }))
    .forEach((r) => res[r.zone].push(r));
  //console.log(res);
  return res;
}

function Diagnostics({ patientId, isNutritionist }: DiagnosticsProps) {
  const [mappedAnalysis, setmappedAnalysis] = useState({
    diagnosticResults: [],
  });
  const dispatch = useDispatch();
  const { analysisResult, analysisResultError, analysisResultSuccess } =
    useSelector((x: RootState) => x.analyses);
  useEffect(() => {
    if (!analysisResult.length) {
      dispatch(fetchAnalysisResults(patientId));
    }
  }, []);

  useEffect(() => {
    if (isNutritionist) {
      const mapped = mapDiagnostics(analysisResult);
      setmappedAnalysis({
        diagnosticResults: [
          ...mapped.diagnosticResults,
          ...mapped.intolerancesMain,
          ...mapped.intolerancesOther,
        ],
      });
    } else {
      setmappedAnalysis(mapDiagnostics(analysisResult));
    }
  }, [analysisResult, isNutritionist]);
  
  return (
    
    <>
      <div className="diagnostic-container">
        {analysisResultSuccess ? (
          mappedAnalysis?.diagnosticResults.map((a,idx) => {
            return <DiagnosticCard result={a} key={idx} />;
          })
        ) : (
          <div className="loading">
            <LoadingAnimation />
          </div>
        )}
      </div>
      <FullWidthSnackbar
        open={analysisResultError}
        setOpen={() => false}
        text="Hubo un error al mostrar los diagnosticos"
        success={false}
      />
    </>
  );
}

const DiagnosticScore = {
  0: "Bajo",
  1: "Moderado",
  2: "Alto",
};

const DiagnosticScoresByNutrient = (r) => {
  if(r.item === 'Niveles séricos de colesterol LDL') {
    return r.value === 0 ? 'RIESGO BAJO' : r.value === 1 ? 'RIESGO MODERADO' : 'RIESGO ALTO'
  } else if(r.item === 'Requerimiento de grasas Omega 3') {
    return r.value === 0 ? 'REQUERIMIENTO NORMAL' : r.value === 1 ? 'REQUERIMIENTO MODERADO' : 'REQUERIMIENTO ALTO'
  } else if(r.item === 'Sensibilidad a la glucosa') {
    return r.value === 0 ? 'RIESGO BAJO' : r.value === 1 ? 'RIESGO MODERADO' : 'RIESGO ALTO'
  } else if(r.item === 'Sensibilidad a la histamina') {
    return r.value === 0 ? 'RIESGO BAJO' : r.value === 1 ? 'RIESGO MODERADO' : 'RIESGO ALTO'
   } else if(r.item === 'Requerimiento de calcio') {
    return r.value === 0 ? 'RIESGO BAJO' : r.value === 1 ? 'RIESGO MODERADO' : 'RIESGO ALTO'
   } else if(r.item === 'Requerimiento de vitamina D') {
    return r.value === 0 ? 'RIESGO BAJO' : r.value === 1 ? 'RIESGO MODERADO' : 'RIESGO ALTO'
   } else if(r.item === 'Requerimiento de vitamina A') {
    return r.value === 0 ? 'NIVELES NORMALES' : r.value === 1 ? 'NIVELES ALTOS' : 'NIVELES BAJOS'
   } else if(r.item === 'Requerimiento de vitamina B6') {
    return r.value === 0 ? 'RIESGO BAJO' : r.value === 1 ? 'RIESGO MODERADO' : 'RIESGO ALTO'
   } else if(r.item === 'Requerimiento de vitamina B12') {
    return r.value === 0 ? 'RIESGO BAJO' : r.value === 1 ? 'RIESGO MODERADO' : 'RIESGO ALTO'
   } else if(r.item === 'Requerimiento de magnesio') {
    return r.value === 0 ? 'RIESGO BAJO' : r.value === 1 ? 'RIESGO MODERADO' : 'RIESGO ALTO'
   } else if(r.item === 'Respuesta al alcohol') {
    return r.value === 0 ? 'RESPUESTA NORMAL' : r.value === 1 ? 'RESPUESTA MODERADA' : 'RESPUESTA ACELERADA'
   } else if(r.item === 'Sensibilidad a la sal') {
    return r.value === 0 ? 'SENSIBILIDAD NORMAL' : r.value === 1 ? 'SENSIBILIDAD MODERADA' : 'SENSIBILIDAD ALTA'
    } else if(r.item === 'Actividad antioxidante') {
    return r.value === 0 ? 'ACTIVIDAD NORMAL' : r.value === 1 ? 'ACTIVIDAD MODERADA' : 'ACTIVIDAD DISMINUIDA'
    } else if(r.item === 'Niveles séricos de homocisteina') {
    return r.value === 0 ? 'NIVELES NORMALES' : r.value === 1 ? 'NIVELES MDOERADOS' : 'NIVELES AUMENTADOS'
    } else if(r.item === 'Equilibrio depuración hepática') {
    return r.value === 0 ? 'RIESGO BAJO' : r.value === 1 ? 'RIESGO MODERADO' : 'RIESGO ALTO'
    } else if(r.item === 'Sensibilidad a la cafeína') {
    return r.value === 0 ? 'METABOLIZACIÓN RÁPIDA' : r.value === 1 ? 'METABOLIZACIÓN MODERADA' : 'METABOLIZACIÓN LENTA'
    } else if(r.item === 'Tolerancia a la lactosa') {
    return r.value === 0 ? 'TOLERANCIA NORMAL' : r.value === 1 ? 'TOLERANCIA MODERADA' : 'TOLERANCIA DISMINUIDA'
    } else if(r.item === 'Sensación de saciedad') {
    return r.value === 0 ? 'SACIEDAD HABITUAL' : r.value === 1 ? 'SACIEDAD INTERMEDIA' : 'SACIEDAD DISMINUIDA'
    } else if(r.item === 'Celiaquía') {
    return r.value === 0 ? 'VARIANTES NO ASOCIADAS' : r.value === 1 ? '' : 'VARIANTES ASOCIADAS'
    } else if(r.item === 'Composición muscular') {
      let res = r.snps[0].result
      if (res !== undefined) {
        return res === 'CC' ? 'FIBRAS RÁPIDAS' : res === 'CT' || res === 'TC' ? 'FIBRAS MIXTAS' : 'FIBRAS LENTAS'
      }else {
        return 'SIN INFORMACIÓN'
      }
    } else if(r.item === 'Capacidad aeróbica o atlética') {
      let res = r.snps[0].result
      if (res !== undefined) {
        return res === 'CC' ? 'CAPACIDAD SUPRANORMAL' : 'CAPACIDAD NORMAL'
      }else {
        return 'SIN INFORMACIÓN'
      }
    } else if(r.item === 'Cafeína en deporte') {
      return r.value === 0 ? 'METABOLIZACIÓN RÁPIDA' : r.value === 1 ? 'METABOLIZACIÓN MODERADA' : 'METABOLIZACIÓN LENTA'
    } else if(r.item === 'Grasas saturadas y peso corporal') {
    return r.value === 0 ? 'RIESGO BAJO' : r.value === 1 ? 'RIESGO MODERADO' : 'RIESGO ALTO'
    }else {
      return r.value === 0 ? 'RIESGO BAJO' : r.value === 1 ? 'RIESGO MODERADO' : 'RIESGO ALTO'
    }
}

const DiagnosticValVerification = (r) => {
  let val = r.value;
  let res = r.snps[0].result
  if (r.item === 'Capacidad aeróbica o atlética') {
      if (res !== undefined) {
         res === 'CC' ? val = 0 : val = 2
      }
      return val
  } else if (r.item === 'Composición muscular') {
    if (res !== undefined) {
      res === 'CC' ? val= 0 : res === 'CT' || res === 'TC' ? val = 1 : val = 2
    }
    return val
  }else {
    return val
  }

}

function DiagnosticCard({ result }) {
  const [isOpen, setIsOpen] = useState(false); 
  const toggleOpen = () => setIsOpen(!isOpen);
  //console.log(result);
  return (

    <div className={`diagnostic ${isOpen ? 'open' : ''}`}>
      <div className="diagnostic-header" onClick={toggleOpen}>
        <h2>{result.item}</h2>
        <div className="circles-container">
          <div className={`circle ${result.value === 0 ? 'low' : result.value === 1 ? 'medium' : 'high'}`}></div>
          <div className="result-text">{DiagnosticScoresByNutrient(result)}</div>
        </div>
        <div className={`arrow ${isOpen ? 'open' : ''}`}>⌄</div>
      </div>
      {isOpen && (
        <div className={`description ${isOpen ? 'show' : ''}`}>

          <div className="description-container">
            <div className="general-info">
            <h3>De qué se trata?</h3>
            <div dangerouslySetInnerHTML={{__html: result.generalInfo}}></div>
            </div>
            <div className="result">
              <h3>Resultado</h3>
              <div dangerouslySetInnerHTML={{__html: result.results}}></div>
              
            </div>
            <div className="result-explain">
            <h3>Entendiendo su resultado</h3>

            <div className="res-exp-text-circles">
              <div className="title-circles">
                <div className="title">
                  <div dangerouslySetInnerHTML={{__html: result.desc[DiagnosticValVerification(result)]}}></div>
                </div>
                <div className="circles-text-container">
                  <div className="circles-container">
                    <div>
                      <div className={`circle low ${result.value === 0 ? "active" : null}`}></div>
                    </div>
                    <div>
                      <div className={`circle medium ${result.value === 1 ? "active" : null}`}></div>
                    </div>
                    <div>
                      <div className={`circle high ${result.value === 2 ? "active" : null}`}></div>
                    </div> 
                    
                  </div>
                  <div className="circles-text">
                    {DiagnosticScoresByNutrient(result)}
                  </div>
                </div>
                  
              </div>
            </div>


            
            </div>
            <div className="recommendations">
            <h3>Recomendaciones</h3>
            <div dangerouslySetInnerHTML={{__html: result?.recommendations[DiagnosticValVerification(result)]}}></div>
            </div>
            <div className="bibliography">
            <h3>Bibliografía</h3>
            <div dangerouslySetInnerHTML={{__html: result.bibliography }}></div>
            </div>
            <div style={{clear: 'both'}}></div>
          </div>

          
          
        </div>
      )}
    </div>


   
  )
}

export default Diagnostics;


/*
 /*<div className={`diagnostic ${isOpen ? 'open' : ''}`}>
    <div className="diagtitle" onClick={toggleOpen}>
      <h4 >{result.label} </h4>
      <div> resultado </div>
    </div>
    
      {isOpen && ( // Esto asegura que el contenido solo se muestre si isOpen es true
        <>
          <p>{DiagnosticScore[result.value]}</p>
          <div className={`circles ${isOpen ? 'show' : ''}`}>
            <div>
              <div className={`low ${result.value === 0 ? "active" : null}`}></div>
            </div>
            <div>
              <div className={`medium ${result.value === 1 ? "active" : null}`}></div>
            </div>
            <div>
              <div className={`high ${result.value === 2 ? "active" : null}`}></div>
            </div>
          </div>
          <p className={`description ${isOpen ? 'show' : ''}`}>{result.desc[result.value]}</p>
        </>
    )}

       <h4>{result.label}</h4>
      <p>{DiagnosticScore[result.value]}</p>
      <div className="circles">
        <div>
          <div className={`low ${result.value === 0 ? "active" : null}`}></div>
        </div>
        <div>
          <div
            className={`medium ${result.value === 1 ? "active" : null}`}
          ></div>
        </div>
        <div>
          <div className={`high ${result.value === 2 ? "active" : null}`}></div>
        </div>
      </div>
  <p className="description">{result.desc[result.value]}</p> 
    </div>
    */
   