import React, { useState, useEffect, ChangeEventHandler } from 'react';
import { format, parseISO, addDays } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faTimes } from '@fortawesome/free-solid-svg-icons'; // Importar el ícono de cruz
import { Lottie } from "@crello/react-lottie";
import loadAnimation from "../../../assets/loading.json";
import logo from "../../../assets/logo.png";
import { useSelector, useDispatch } from "react-redux";
import backArrow from "../../../assets/backArrow.png";
import roles from "../../../utils/roles";
import { userRole, userAccount } from "../../../store/auth/selector";
import { RootState } from "../../../store/config/types";
import { Menu, MenuItem, Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";
import { logout } from "../../../store/auth/actionCreators";
import { useHistory, useLocation } from "react-router-dom";
import { BASE_URL_FOODS } from '../../../services/config';
import { zonasEnvio } from '../../../helpers/zonas-delivery';
import request from 'request';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';

interface Meal {
  id: number;
  title: string;
  subtitle: string;
  photo: string;
  category: string;
  calories: number;
  carbos: number;
  fats: number;
  proteins: number;
  maxQuantity: number;
  type: null | string;
  chefRecommendation: boolean;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  weekId: number;
  mealId: number;
  initDate: string;
  endDate: string;
  weekYear: string;
  selectedDay: string;
  kitchenId: number;
  availableDay: number;
}

interface Week {
  weekId: number;
  weekstart: string;
  weekend: string;
  mealsList: Meal[];
}

interface User {
  name: string;
  email: string;
  phone: string;
  department: string;
  neighborhood: string;
  address: string;
  from: string;
  until: string;
  saltOption: string;
  carboOption: string;
  comments: string;
}

interface OrderData {
  order: Week[];
  user: User;
  totalShipping: number;
  totalMeals: number;
}

interface MealGroup {
  meal: Meal;
  quantity: number;
}

const MenuSemanal: React.FC = () => {
  const [openModal, setOpenModal] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [selectedDays, setSelectedDays] = useState({});
  const [cartItems, setCartItems] = useState<any[]>([]);
  const [deliveryPrice, setDeliveryPrice] = useState(70);
  const [weeksData, setWeeksData] = useState<any[]>([]);
  const [cartOpen, setCartOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    department: 'Montevideo',
    neighborhood: 'Aguada',
    address: '',
    from: '',
    until: '',
    saltOption: 'con-sal',
    carboOption: 'con-carbo',
    comments: ''
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [mealPrice, setMealPrice] = useState(0);
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => userRole(state));
  const actualUser = useSelector((state: RootState) => userAccount(state));
  const isFinalUser = role === roles.user;
  const isNutritionist = role === roles.nutricionist;
  const firstName = actualUser && actualUser.profile.firstName;
  const hasAnalysis = actualUser && actualUser.profile.analysis;

  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [from, setFrom] = useState("10:00");
  const [until, setUntil] = useState("13:30");

  const [preference, setPreference] = useState('');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentStatus = queryParams.get('status');

  const [statusMP, setStatusMP] = useState('');
  const [paymentId, setPaymentId] = useState('');

  useEffect(() => {
    request.get(`${BASE_URL_FOODS}/v1/meal/MealsByWeek`, (error, response, body) => {
      if (!error && response.statusCode === 200) {
        const data = JSON.parse(body);
        const availableWeeksData = data.filter((week) => week.mealsList && week.mealsList.length > 0);
        console.log(availableWeeksData);
        setWeeksData(availableWeeksData);
      } else {
        console.error('Error al obtener los datos', error);
      }
    });
    request.get(`${BASE_URL_FOODS}/v1/meal/prices`, (error, response, body) => {
      if (!error && response.statusCode === 200) {
        const data = JSON.parse(body);
        setMealPrice(data['4']);
      } else {
        console.error('Error al obtener los datos', error);
      }
    });
    if (actualUser) {
      setFormData({
        name: actualUser?.profile?.firstName || '',
        email: actualUser?.email || '',
        phone: actualUser?.profile?.mobile || '',
        department: '',
        neighborhood: '',
        address: '',
        from: '10:00',
        until: '13:30',
        saltOption: 'con-sal',
        carboOption: 'con-carbo',
        comments: '',
      });
    }
    console.log(paymentStatus);
    if (paymentStatus !== "" && paymentStatus !== null) {
      setStatusMP(paymentStatus);
      setPaymentId(queryParams.get('payment_id'));
      setFormSubmitted(true);
      console.log("El pago fue aprobado");
    } else {
      setOpenModal(true);
      initMercadoPago('APP_USR-097c1049-0ad3-4548-b85e-ba982fe5f695');

    }

  }, []);

  useEffect(() => {
    if (formData.department === "Canelones") {
      setFormData({
        ...formData,
        neighborhood: getFirstLocalidad("Canelones")
      });
      setDeliveryPrice(parseInt(zonasEnvio.Canelones.localidades[formData.neighborhood]));
    } else {
      setFormData({
        ...formData,
        neighborhood: getFirstLocalidad("Montevideo")
      });
      setDeliveryPrice(parseInt(zonasEnvio.Montevideo.localidades[formData.neighborhood]));
    }
  }, [formData.department]);

  useEffect(() => {
    setDeliveryPrice(parseInt(zonasEnvio[formData.department].localidades[formData.neighborhood]));
  }, [formData.neighborhood])

  useEffect(() => {
    setFormData({
      ...formData,
      from: from,
      until: until
    })
  }, [from, until])

  const formatDate = (dateString: string) => {
    const date = parseISO(dateString);
    const formattedDate = format(date, 'd MMM');
    return formattedDate;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    console.log(formData.department, formData.neighborhood)
  };

  const getLocalidades = (departamento) => {
    return Object.keys(zonasEnvio[departamento].localidades);
  };
  const getFirstLocalidad = (departamento) => {
    return Object.keys(zonasEnvio[departamento].localidades)[0];
  };

  const generateTimeOptions = (startHour, endHour, interval) => {
    const options = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minute = 0; minute < 60; minute += interval) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        options.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return options;
  };

  const fromOptions = generateTimeOptions(7, 10, 30);
  const untilOptions = generateTimeOptions(10, 13, 30);

  const handleWeekChange = (weekIndex: number) => {
    setSelectedWeek(weekIndex);
  };

  const handleDayChange = (event, mealId) => {
    const newSelectedDays = {};
    newSelectedDays[mealId] = event.target.value;
    setSelectedDays(newSelectedDays);
  };

  const handleAddToCart = (meal: any) => {
    if (selectedDays[meal.mealId] === "" || !selectedDays[meal.mealId]) return;

    const selectedWeekData = weeksData[selectedWeek];
    if (!selectedWeekData || !selectedWeekData.mealsList) {
      return;
    }

    const newSelectedDay = selectedDays[meal.mealId];
    setSelectedDay(newSelectedDay);

    setCartItems((prevCartItems) => {
      const existingWeek = prevCartItems.find(
        (item) =>
          item.weekstart === selectedWeekData.weekstart &&
          item.weekend === selectedWeekData.weekend
      );

      if (existingWeek) {
        const updatedWeek = {
          ...existingWeek,
          mealsList: [...existingWeek.mealsList, { ...meal, selectedDay: newSelectedDay }],
        };
        return prevCartItems.map((item) =>
          item.weekstart === selectedWeekData.weekstart &&
            item.weekend === selectedWeekData.weekend
            ? updatedWeek
            : item
        );
      } else {
        return [
          ...prevCartItems,
          {
            weekId: selectedWeekData.weekId,
            weekstart: selectedWeekData.weekstart,
            weekend: selectedWeekData.weekend,
            mealsList: [{ ...meal, selectedDay: newSelectedDay }],
          },
        ];
      }
    });
    setSelectedDay('');
    const updatedSelectedDays = {};
    updatedSelectedDays[meal.mealId] = "";
    setSelectedDays(updatedSelectedDays);
    console.log(cartItems);
  };

  const handleRemoveFromCart = (event, mealId: number, weekstart: string, weekend: string, selectedDay: string, index: number) => {
    event.stopPropagation();
    setCartItems((prevCartItems) => {
      return prevCartItems.map((weekItem) => {
        if (weekItem.weekstart === weekstart && weekItem.weekend === weekend) {
          const updatedMealsList = weekItem.mealsList.filter(
            (meal, mealIndex) => !(meal.mealId === mealId && meal.selectedDay === selectedDay && mealIndex == index)
          );
          if (updatedMealsList.length === 0) {
            return null;
          }
          return {
            ...weekItem,
            mealsList: updatedMealsList,
          };
        }
        return weekItem;
      }).filter(Boolean);
    });
  };

  const renderWeekTabs = () => {
    return weeksData.map((week, index) => (
      <div
        key={week.weekId}
        className={`week-tab ${selectedWeek === index ? 'active' : ''}`}
        onClick={() => handleWeekChange(index)}
      >
        {formatDate(week.weekstart)} - {formatDate(week.weekend)}
      </div>
    ));
  };

  const getDayNameFromAvailableDay = (availableDay) => {
    const daysMapping = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    return daysMapping[availableDay];
  };

  const renderMeals = () => {
    const colorsByDay = ['lightgray', 'lightsteelblue', 'lightgreen', 'lightyellow', 'skyblue', 'lightgoldenrodyellow', 'lightgray', 'lightsalmon']

    const selectedWeekData = weeksData[selectedWeek];

    if (!selectedWeekData || !selectedWeekData.mealsList) {
      return <><div></div><div style={{ fontSize: '1.2rem', fontWeight: 'bold', paddingTop: '20px' }}>No hay platos disponibles para esta semana.</div><div></div></>;
    }
    const mealsOrderedByAvailableDay = selectedWeekData.mealsList.sort((item1, item2) => {
      return item1.availableDay - item2.availableDay;
    });

    return mealsOrderedByAvailableDay.map((meal: any) => (
      <div key={meal.mealId} className="meal">
        {meal.photo === null || meal.photo === "null" || meal.photo === "" ? '' : (<img src={meal.photo} alt={meal.title} width="70%" />)}
        <h3>
          {meal.title}
          <br />
          {meal.subtitle}
        </h3>
        {meal.kitchenId === 1 ? (<p>Calorías: {meal.calories}</p>) : ''}
        <div className="day-selector" style={{ backgroundColor: colorsByDay[meal.availableDay] }}>
          {
            meal.kitchenId === 1 ? (
              <>
                <label htmlFor={`day-selector-${meal.mealId}-lunes`}>
                  <input
                    type="radio"
                    name={`day-selector-${meal.mealId}`}
                    id={`day-selector-${meal.mealId}-lunes`}
                    value="Lunes"
                    checked={selectedDays[meal.mealId] === 'Lunes'}
                    onChange={(event) => handleDayChange(event, meal.mealId)}
                  />
                  Lunes
                </label>
                <label htmlFor={`day-selector-${meal.mealId}-miercoles`}>
                  <input
                    type="radio"
                    name={`day-selector-${meal.mealId}`}
                    id={`day-selector-${meal.mealId}-miercoles`}
                    value="Miércoles"
                    checked={selectedDays[meal.mealId] === 'Miércoles'}
                    onChange={(event) => handleDayChange(event, meal.mealId)}
                  />
                  Miércoles
                </label>
              </>
            ) : meal.availableDay !== 7 ? (
              <>
                <label htmlFor={`day-selector-${meal.mealId}-custom`}>
                  <input
                    type="radio"
                    name={`day-selector-${meal.mealId}`}
                    id={`day-selector-${meal.mealId}-custom`}
                    value={getDayNameFromAvailableDay(meal.availableDay)}
                    checked={selectedDays[meal.mealId] === getDayNameFromAvailableDay(meal.availableDay)?.toString()}
                    onChange={(event) => handleDayChange(event, meal.mealId)}
                  />
                  {getDayNameFromAvailableDay(meal.availableDay)}
                </label>
              </>
            ) : (
              <>
                <label htmlFor={`day-selector-${meal.mealId}-lunes`}>
                  <input
                    type="radio"
                    name={`day-selector-${meal.mealId}`}
                    id={`day-selector-${meal.mealId}-lunes`}
                    value="Lunes"
                    checked={selectedDays[meal.mealId] === 'Lunes'}
                    onChange={(event) => handleDayChange(event, meal.mealId)}
                  />
                  Lunes
                </label>
                <label htmlFor={`day-selector-${meal.mealId}-martes`}>
                  <input
                    type="radio"
                    name={`day-selector-${meal.mealId}`}
                    id={`day-selector-${meal.mealId}-martes`}
                    value="Martes"
                    checked={selectedDays[meal.mealId] === 'Martes'}
                    onChange={(event) => handleDayChange(event, meal.mealId)}
                  />
                  Martes
                </label>
                <label htmlFor={`day-selector-${meal.mealId}-jueves`}>
                  <input
                    type="radio"
                    name={`day-selector-${meal.mealId}`}
                    id={`day-selector-${meal.mealId}-jueves`}
                    value="Jueves"
                    checked={selectedDays[meal.mealId] === 'Jueves'}
                    onChange={(event) => handleDayChange(event, meal.mealId)}
                  />
                  Jueves
                </label>
                <label htmlFor={`day-selector-${meal.mealId}-viernes`}>
                  <input
                    type="radio"
                    name={`day-selector-${meal.mealId}`}
                    id={`day-selector-${meal.mealId}-viernes`}
                    value="Viernes"
                    checked={selectedDays[meal.mealId] === 'Viernes'}
                    onChange={(event) => handleDayChange(event, meal.mealId)}
                  />
                  Viernes
                </label>
              </>
            )
          }

        </div>
        <button onClick={() => handleAddToCart(meal)}>Agregar al carrito</button>
      </div>
    ));
  };

  const renderCart = () => {
    return (
      <>
        <div className={`cart ${cartOpen ? 'open' : 'closed'}`}>
          <h2>Pedido por semana</h2><br />
          <div className="cart-content" onClick={() => setCartOpen(!cartOpen)}>
            <ul>
              {cartItems.map((weekItem) => (
                <li key={`${weekItem.weekstart}-${weekItem.weekend}`}>
                  Semana {formatDate(weekItem.weekstart)} - {formatDate(weekItem.weekend)}:
                  <ul>
                    {weekItem.mealsList.map((meal: any, index: number) => (
                      <li key={`${meal.mealId}-${meal.weekId}-${meal.selectedDay}-${index}`}>
                        {meal.title} - {meal.selectedDay}
                        <FontAwesomeIcon 
                          icon={faTimes} 
                          onClick={(event) => handleRemoveFromCart(event, meal.mealId, weekItem.weekstart, weekItem.weekend, meal.selectedDay, index)} 
                          style={{ cursor: 'pointer', marginLeft: '10px' }} 
                        />
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
              {cartItems.length === 0 ? 'Aún no hay platos seleccionados' : ''}
            </ul>
          </div>
          <div className='cart-order-amount'>
            <div className='total-meals' style={{ paddingTop: '10px' }}><b>Platos (x{totalMealsInCart}):</b> <span>$U {totalMealsInCart * mealPrice}</span></div>
            <div className='total-shipping'><b>Envíos (x{totalShipping} - {formData.neighborhood} - ${deliveryPrice} - <a onClick={() => setOpenModal(true)} style={{ fontSize: '18px', color: '#7c67e2', fontWeight: 'bold' }}>Editar</a>):</b> <span>$U {totalShipping * deliveryPrice}</span></div>
            <div className='final-total'><b>Total a pagar:</b> <span>$U {(totalMealsInCart * mealPrice) + (totalShipping * deliveryPrice)}</span></div>
          </div>
          <button onClick={() => {
            setShowForm(true);
            setCartOpen(!cartOpen);
            setFormData({
              ...formData,
              name: actualUser?.profile?.firstName,
              email: actualUser?.email,
              phone: actualUser?.profile?.mobile,
            })
          }}>Confirmar pedido</button>
        </div>
      </>
    );
  };

  const handleFormChangeSelect: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const formatDateToDayMonth = (date) => {
    return `${date.split("-")[2]}/${date.split("-")[1]}`
  }

  const addDatesToWeeksData = (weeksData) => {
    const dayOffsets = {
      "Lunes": 0,
      "Martes": 1,
      "Miércoles": 2,
      "Jueves": 3,
      "Viernes": 4,
      "Sábado": 5,
      "Domingo": 6
    };

    weeksData.forEach(week => {
      const startDate = parseISO(week.weekstart);
      week.days.forEach(day => {
        const date = addDays(startDate, dayOffsets[day.day]);
        day.date = format(date, 'yyyy-MM-dd');
      });
    });

    return weeksData;
  };

  const generateOrderSummaryAndObject = (orderData) => {
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      return `${day}/${month}`;
    };

    let summary = `Pedido de Platos - Información del Usuario:\n`;
    summary += `------------------------------------------\n`;
    summary += `Nombre: ${orderData.user.name}\n`;
    summary += `Email: ${orderData.user.email}\n`;
    summary += `Teléfono: ${orderData.user.phone}\n`;
    summary += `Departamento: ${orderData.user.department}\n`;
    summary += `Barrio/Localidad: ${orderData.user.neighborhood}\n`;
    summary += `Dirección: ${orderData.user.address}\n`;
    summary += `Opción SAL: ${orderData.user.saltOption}\n`;
    summary += `Opción CARBO: ${orderData.user.carboOption}\n`;
    summary += `Rango entrega: ${orderData.user.from} - ${orderData.user.until}\n`;
    summary += `Comentarios: ${orderData.user.comments}\n\n`;
    summary += `Precio platos: ${orderData.totalMeals * mealPrice}\n`;
    summary += `Precio envíos: ${orderData.totalShipping * deliveryPrice}\n`;
    summary += `Total a pagar: ${(orderData.totalMeals * mealPrice) + (orderData.totalShipping * deliveryPrice)}\n\n`;

    summary += `Platos por Semana:\n`;
    summary += `-------------------\n`;

    const orderObject = orderData.order.map((week) => ({
      weekId: week.weekId,
      weekstart: week.weekstart,
      weekend: week.weekend,
      days: week.mealsList.reduce((daysAccumulator, meal) => {
        const { selectedDay } = meal;
        const { mealId, title } = meal;

        const existingDay = daysAccumulator.find((day) => day.day === selectedDay);

        if (existingDay) {
          const existingMeal = existingDay.meals.find((m) => m.mealId === mealId);

          if (existingMeal) {
            existingMeal.quantity += 1;
          } else {
            existingDay.meals.push({
              mealId: mealId,
              title: title,
              quantity: 1,
            });
          }
        } else {
          daysAccumulator.push({
            day: selectedDay,
            meals: [
              {
                mealId: mealId,
                title: title,
                quantity: 1,
              },
            ],
          });
        }

        return daysAccumulator;
      }, []),
    }));

    orderData.order.forEach((week) => {
      summary += `Semana ${formatDateToDayMonth(week.weekstart)} a ${formatDateToDayMonth(week.weekend)}\n`;

      const mealsByDay = {};

      week.mealsList.forEach((meal) => {
        if (!mealsByDay[meal.selectedDay]) {
          mealsByDay[meal.selectedDay] = {};
        }
        if (!mealsByDay[meal.selectedDay][meal.mealId]) {
          mealsByDay[meal.selectedDay][meal.mealId] = {
            meal: meal,
            quantity: 1,
          };
        } else {
          mealsByDay[meal.selectedDay][meal.mealId].quantity += 1;
        }
      });

      Object.keys(mealsByDay).forEach((day) => {
        summary += `- ${day}:\n`;

        Object.values(mealsByDay[day]).forEach((mealGroup: MealGroup) => {
          summary += `  - ${mealGroup.quantity}x ${mealGroup.meal.title}\n`;
        });
      });

      summary += `\n`;
    });

    return { summary, orderObject };
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!formData.name || !formData.email || !formData.phone || !formData.address) {
      alert('Por favor completa todos los campos del formulario.');
      return;
    }

    try {
      const orderData = {
        order: cartItems,
        user: {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          department: formData.department,
          neighborhood: formData.neighborhood,
          address: formData.address,
          from: formData.from,
          until: formData.until,
          saltOption: formData.saltOption,
          carboOption: formData.carboOption,
          comments: formData.comments
        },
        totalShipping: totalShipping,
        totalMeals: totalMealsInCart,
      };
      const userObject = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        department: formData.department,
        neighborhood: formData.neighborhood,
        address: formData.address,
        from: formData.from,
        until: formData.until,
        saltOption: formData.saltOption,
        carboOption: formData.carboOption,
        comments: formData.comments
      }
      const mrgenToken = {
        token: localStorage.getItem('token')
      }
      localStorage.setItem('orderData', JSON.stringify(orderData));

      const orderFormatted = generateOrderSummaryAndObject(orderData);
      const orderSummary = orderFormatted.summary;
      const orderObject = addDatesToWeeksData(orderFormatted.orderObject);
      console.log(orderSummary);
      console.log(orderObject);

      request.post(
        `${BASE_URL_FOODS}/v1/order/orders`,
        {
          json: { orderObject, orderSummary, userObject },
        },
        (error, response, body) => {
          if (!error && response.statusCode === 200) {
            console.log('Pedido enviado exitosamente:', body);
          } else {
            console.error('Error al enviar el pedido:', error || body);
            alert('Ocurrió un error al enviar el pedido. Por favor intenta nuevamente.');
          }
        }
      );

      request.post(
        `${BASE_URL_FOODS}/v1/payment/create_preference`,
        {
          json: { orderObject, orderData, mrgenToken },
        },
        (error, response, body) => {
          if (!error && response.statusCode === 200) {
            console.log('Pedido enviado exitosamente:', body);
            setPreference(body.id);
          } else {
            console.error('Error al enviar el pedido:', error || body);
            alert('Ocurrió un error al enviar el pedido. Por favor intenta nuevamente.');
          }
        }
      );

    } catch (error) {
      console.error('Error al enviar la orden o el correo:', error);
      alert('Ocurrió un error al enviar la orden. Por favor intenta nuevamente.');
    }
  };

  const selectOption = (option: number) => {
    if (option === 0) history.push("/profile");
    if (option === 1) {
      dispatch(logout());
      history.push("/");
    }
    if (option === 2) {
      history.push("/platos-semanales");
    }
    if (option === 3) {
      history.push("/consulta-nutricional");
    }
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const totalMealsInCart = cartItems.reduce((total, weekItem) => {
    return total + weekItem.mealsList.length;
  }, 0);
  const totalShipping = cartItems.map((weekItem) => {
    return new Set(weekItem.mealsList.map((meal) => meal.selectedDay)).size;
  }
  ).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <>
      {
        !formSubmitted && (
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogContent>
              <div>
                <h1>Selecciona tu departamento y localidad</h1>
                <label>Departamento:</label>
                <select
                  value={formData.department}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      department: e.target.value,
                    })
                  }}
                >
                  {Object.keys(zonasEnvio).map((departamento) => (
                    <option key={departamento} value={departamento}>
                      {departamento}
                    </option>
                  ))}
                </select>

                <br />

                <label>Localidad:</label>
                <select
                  value={formData.neighborhood}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      neighborhood: e.target.value,
                    })
                  }}
                >
                  {getLocalidades(formData.department).map((localidad) => (
                    <option key={localidad} value={localidad}>
                      {localidad}
                    </option>
                  ))}
                </select>
              </div>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
      <div className="menu-semanal">
        <div className="logo-week-cart-header">
          <div className="meals-logo-header">
            <img style={{ width: '160px' }}
              src={logo}
              alt="logo"
              className="logo"
              onClick={() =>
                window.localStorage.token === "" || !window.localStorage.token
                  ? history.push('/')
                  : window.localStorage.token !== "" && !hasAnalysis ? history.push('/loadingPayment')
                    : history.push('/dashboard')
              }
            />
            <div className="cart-icon-container" >
              <FontAwesomeIcon icon={faCartShopping} onClick={() => setCartOpen(!cartOpen)} />
              <div className="rel-div"><span onClick={() => setCartOpen(!cartOpen)}>{totalMealsInCart}</span></div>
              {
                actualUser && (<div className="profile-div" onClick={handleClick}>
                  <div className="image">{firstName[0]}</div>
                  <span>{firstName}</span>
                </div>)
              }
            </div>
            {
              actualUser && (
                <Menu
                  keepMounted
                  anchorEl={anchorEl}
                  anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                  transformOrigin={{
                    horizontal: "left",
                    vertical: "top",
                  }}
                  getContentAnchorEl={null}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => selectOption(0)}>Editar perfil</MenuItem>
                  <MenuItem onClick={() => selectOption(2)}>Platos semanales</MenuItem>
                  <MenuItem onClick={() => selectOption(3)}>Consulta nutricionista</MenuItem>
                  <MenuItem onClick={() => selectOption(1)}>Cerrar sesión</MenuItem>
                </Menu>
              )
            }
          </div>
          <div className="week-cart">
            <div className="tabs">{renderWeekTabs()}</div>
            {renderCart()}
          </div>
        </div>
        {!showForm && !formSubmitted && (
          <>
            <div className="platos-semana-header">
              <h1>Selecciona tus platos favoritos y recíbelos cada día</h1>
              <h2>Hasta 8 opciones diarias incluyendo snacks saludables</h2>
              <h4>Envíos lunes, martes, jueves y viernes desde $70 cada día<br />Platos aptos para congelar<br />Plato: $260</h4>
            </div>
            <div className="platos-semana">{renderMeals()}</div>
          </>
        )}
        {showForm && !formSubmitted && (
          <form className="order-form" onSubmit={handleFormSubmit}>
            <h2>Confirma tu orden</h2>
            <div className="form-group">
              <label htmlFor="name">Nombre:</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleFormChange} />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleFormChange} />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Celular:</label>
              <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleFormChange} />
            </div>
            <div className="form-group">
              <label htmlFor="department">Departamento:</label>
              <input type="text" id="department" name="department" value={formData.department} onChange={handleFormChange} />
            </div>
            <div className="form-group">
              <label htmlFor="neighborhood">Barrio:</label>
              <input type="text" id="neighborhood" name="neighborhood" value={formData.neighborhood} onChange={handleFormChange} />
            </div>
            <div className="form-group">
              <label htmlFor="address">Dirección:</label>
              <input type="text" id="address" name="address" value={formData.address} onChange={handleFormChange} />
            </div>
            <div className="form-group" style={{ width: '300px' }}>
              <label htmlFor="saltOption">Opción de sal:</label>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <label htmlFor="con-sal">
                  <input
                    type="radio"
                    id="con-sal"
                    name="saltOption"
                    value="con-sal"
                    checked={formData.saltOption === 'con-sal'}
                    onChange={handleFormChange}
                  />
                  Con Sal
                </label>
                <label htmlFor="sin-sal">
                  <input
                    type="radio"
                    id="sin-sal"
                    name="saltOption"
                    value="sin-sal"
                    checked={formData.saltOption === 'sin-sal'}
                    onChange={handleFormChange}
                  />
                  Sin Sal
                </label>
              </div>
            </div>
            <div className="form-group" style={{ width: '300px' }}>
              <label htmlFor="saltOption">Opción de Carbohidratos:</label>
              <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <label htmlFor="con-carbo">
                  <input
                    type="radio"
                    id="con-carbo"
                    name="carboOption"
                    value="con-carbo"
                    checked={formData.carboOption === 'con-carbo'}
                    onChange={handleFormChange}
                  />
                  Con Carbohidratos
                </label>
                <label htmlFor="sin-carbo">
                  <input
                    type="radio"
                    id="sin-carbo"
                    name="carboOption"
                    value="sin-carbo"
                    checked={formData.carboOption === 'sin-carbo'}
                    onChange={handleFormChange}
                  />
                  Sin Carbohidratos
                </label>
              </div>
            </div>
            <div className="form-group" style={{ width: '300px' }}>
              <label htmlFor="fromHour">Entregar entre:</label>
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                <select
                  name='fromHour'
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                >
                  <option value="">Selecciona una hora</option>
                  {fromOptions.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>

                <select
                  value={until}
                  onChange={(e) => setUntil(e.target.value)}
                >
                  <option value="">Selecciona una hora</option>
                  {untilOptions.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="comments">Comentarios:</label>
              <input type="text" id="comments" name="comments" value={formData.comments} onChange={handleFormChange} />
            </div>
            <div id="wallet_container"></div>
            {
              preference !== '' && (
                <Wallet initialization={{ preferenceId: preference }} customization={{ texts: { valueProp: 'smart_option' } }} />
              )
            }
            {preference === '' && (
              <button className="submit" type="submit">Confirmar</button>
            )
            }
            <button className="cancel-order" type="button" onClick={() => {
              setShowForm(false);
              setPreference('');
            }}>Cancelar</button>
          </form>
        )}
        {formSubmitted && statusMP === 'approved' && (
          <div className="confirmation-message">
            <p>Tu pedido ha sido confirmado. ¡Gracias por elegir nuestros platos!</p>
            <p>Pago Nro. {paymentId}</p>
          </div>
        )}
        {formSubmitted && statusMP !== 'approved' &&
          (
            <div className="confirmation-message">
              <p>Pedido pendiente de confirmación, a la brevedad nos comunicaremos. ¡Gracias por elegir nuestros platos!</p>
            </div>
          )
        }
      </div>
    </>
  );
};

export default MenuSemanal;
